import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FolderPlus } from "react-bootstrap-icons";
import { VictoryPie } from "victory";
import { useNavigate } from "react-router-dom";

import Loading from "../../../../Components/Loading";
import styles from "../../DashboardAlternado.module.css";
import { DashboardAlternadoContext } from "../../../../Context/DashboardAlternadoContext";

export const ContratosCancelados30Perc = () => {
  const [dadosGrafico, setDadosGrafico] = useState(null)
  const { buscaPorPeriodo, contratos30, setContratos30 } = React.useContext(DashboardAlternadoContext);

  const navigate = useNavigate();
  const dataAtual = new Date();
  
  useEffect(() => {
    async function fetchData() {
      try {
        let contratos = await contratos30;

        if (contratos === null) {
          contratos = await buscaPorPeriodo(30, dataAtual);
          setContratos30(contratos);
        }

        let total = contratos.quantidade_cancelados + contratos.quantidade_novos;
        let percent_cancelados = Number((contratos.quantidade_cancelados/total) * 100);
        let percent_novos = Number((contratos.quantidade_novos/total) * 100);

        setDadosGrafico([{x: "Contratos Cancelados", y: percent_cancelados}, {x: "Contratos Novos", y: percent_novos} ])
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, [contratos30])

  if (dadosGrafico === null) return <Loading />;

  return (
    <>
      <h1 className={styles.chartHeader}>
        Contratos: 30 dias 

        <Button onClick={() => navigate(`/dashboard2details/${30}`)} className={styles.btn}>
          <FolderPlus size={24} />
        </Button>
      </h1>

      <VictoryPie
        colorScale={["#cd4439", "#72b896"]}
        labels={({ datum }) => `${datum.y.toFixed(2)}%`}
        labelRadius={({ innerRadius }) => innerRadius + (window.innerHeight > 740 ? 170 : 100) }
        style={{ labels: { fill: "white",  fontSize: 24, fontWeight: "bold" }}}
        data={dadosGrafico}
        width={window.innerHeight > 740 ? 700 : 500}
        height={window.innerHeight > 740 ? 700 : 500}
      />

      <div className={styles.captionContainer} style={{fontWeight: 'bold'}}>
        <label className={styles.captionLabel}>
          <span className={styles.captionColorSquare} style={{backgroundColor: '#cd4439'}} />
          Contratos Cancelados
        </label>

        <label className={styles.captionLabel}>
          <span className={styles.captionColorSquare} style={{backgroundColor: '#72b896'}} />
          Contratos Novos
        </label>
      </div>

      <div className={styles.captionContainer}>
        <label className={styles.captionLabel}>
          Percentual de contratos
        </label>
      </div>
    </>
  )
}