import React from "react";
import api from "../../Api/apiAxios";
import { Button, Table } from "react-bootstrap";
import { CardText } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import HeaderContratos from "../../Components/HeaderContratos";
import Loading from "../../Components/Loading";
import { MyContext } from "../../Context/MyContext";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip'
import { Paginacao } from "../../Components/Paginacao";
import styles from "./ContratosIndex.module.css";
import { TableItemName } from "../../Components/TableItemName";
import { PedidosContext } from "../../Context/PedidosContext";

const ContratosIndex = () => {

    const navigate = useNavigate();

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');  
    const idDistribuidor = window.localStorage.getItem('distribuidorId');   

    const { listaGlobalContratos, setListaGlobalContratos, itensPorPagina } = React.useContext(MyContext);
    const { verificarECancelarContratos } = React.useContext(PedidosContext);

    const [pesquisa, setPesquisa] = React.useState('');
    const [contratos, setContratos] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [tipoPesquisa, setTipoPesquisa] = React.useState('*');

    const [registroInicial, setRegistroInicial] = React.useState(1);
    const [registroFinal, setRegistroFinal] = React.useState(20);
    const [quantidadeRegistros, setQuantidadeRegistros] = React.useState(0);

    React.useEffect(() => {     
        async function fetchInitialData() {
            if (!listaGlobalContratos) {
                setLoading(true);
                try {
                    const response = await api.get(`/Contrato/consulta-paginada?id=${idDistribuidor}&nivel=${nivelUsuario}&regInicial=${registroInicial}&itensPorPagina=999999`);
                    setQuantidadeRegistros(response.data[0]?.valorFixo ?? 0);
    
                    const response2 = await api.get(`/Contrato/parametros-consulta?nivel=${nivelUsuario}&tipo=ativos&args=status`);
                    setContratos(response2.data);
                    setListaGlobalContratos(response2.data);
                    verificarECancelarContratos(response2.data);
                } catch (e) {
                    alert(e);
                } finally {
                    setLoading(false);
                }
            } else {
                setContratos(listaGlobalContratos);
            }
        }
    
        fetchInitialData();            
    }, []);
    
    async function pesquisarClick(event) { 
        event.preventDefault();
    
        setLoading(true);
        try {
            let response;
            if (pesquisa.length === 0) {

                const pesqType = tipoPesquisa === "cancelados" ? "consulta-paginada" : "parametros-consulta";
                const args = tipoPesquisa === "ativos" || tipoPesquisa === "totais" ? "status" : pesquisa;
                response = await api.get(`/Contrato/${pesqType}?nivel=${nivelUsuario}&tipo=${tipoPesquisa}&id=${idDistribuidor}&regInicial=${registroInicial}&itensPorPagina=${itensPorPagina}&args=${args}`);
            } else {
                response = await api.get(`/Contrato/parametros-consulta?nivel=${nivelUsuario}&tipo=${tipoPesquisa}&args=${pesquisa}`);
                setListaGlobalContratos(response.data);
            }
    
            setContratos(response.data);
            setPesquisa('');
        } catch (error) {
            alert(error);
        } finally {
            setLoading(false);
        }
    }  

    function viewContrato(contrato) {
        navigate(`/viewcontrato/${contrato.id}`);
    }

    if (loading) return <Loading />

    return (
       <section>                                
             <HeaderContratos titulo={"Contratos"} 
                pesquisa={pesquisa} setPesquisa={setPesquisa}
                tipoPesquisa={tipoPesquisa} setTipoPesquisa={setTipoPesquisa}
                pesquisarClick={pesquisarClick}
            />

            <Table striped hover responsive>
                <thead className={styles.thead}>
                    <tr className={styles.trHead}>
                            <th className={styles.th}>Distribuidor</th>
                            <th className={styles.th}>Cliente</th>                          
                            <th className={styles.th}>Sistema</th>                        
                            <th className={styles.th}>Nr.Serie</th>
                            <th className={styles.th}>Início</th>
                            <th className={styles.th}>Final</th>
                            <th className={styles.th}>Valor (R$)</th>
                            <th className={styles.th}>Tipo</th>
                            <th className={styles.th}>Status</th>
                    </tr>
                </thead>
                {tipoPesquisa !== "totais" &&
                    <tbody className={styles.tbody}>
                    {contratos.map(contrato => 
                        <tr key={contrato.id} className={styles.trBody}>                                     
                            <TableItemName title={contrato.distribuidor.nomeFantasia} />
                            <TableItemName title={contrato.cliente.razaoSocial} />
                            
                            <td className={styles.td}>{contrato.sistema}</td>
                            <td className={styles.td}>{contrato.numeroSerie}</td>  
                            <td className={styles.td}> 
                                {contrato.dataAtivacao.substring(8,10)}/   
                                {contrato.dataAtivacao.substring(5,7)}/   
                                {contrato.dataAtivacao.substring(0,4)}                               
                                
                            </td>
                            {contrato.dataCancelamento ?
                                <td className={styles.td}>
                                    {contrato.dataCancelamento.substring(8,10)}/ 
                                    {contrato.dataCancelamento.substring(5,7)}/   
                                    {contrato.dataCancelamento.substring(0,4)}    
                                </td>
                            : <td> * * * * * * </td>  
                            }
                            <td>{contrato.valor.toFixed(2)}</td>  

                            {contrato.tipoContrato === 0 && <td className={styles.td}>Manut.</td>}
                            {contrato.tipoContrato === 1 && <td className={styles.td} style={{color: "darkcyan"}}>Locação</td>}
                            {contrato.tipoContrato === 2 && <td className={styles.td} style={{color: "blueviolet"}} >Saas</td>}

                            {contrato.statusContrato === 1 ?
                                <td className={styles.td} style={{backgroundColor: "palegreen"}}>Ativo</td>
                            : ( contrato.statusContrato === 0 ? 
                              <td className={styles.td} style={{backgroundColor: "tomato"}}>Cancelado</td>
                            : <td className={styles.td} style={{backgroundColor: "lavender"}}>Baixado</td>
                            )
                            }

                            <td style={{display: 'flex'}}>
                                                                                       

                                <span> </span>                     
                                <OverlayTrigger   overlay={
                                <Tooltip id="btn-view-pedido">Visualizar Contrato</Tooltip> }>                              
                                    <Button onClick={() => viewContrato(contrato)}                                       
                                            variant="outline-success">
                                            <CardText size={18}/>
                                    </Button>                                       
                                </OverlayTrigger>                                          

                                <span> </span>                                        
                            </td>                        

                        </tr>
                    )}
                </tbody>
            }

            <tfoot>
                <Paginacao
                    setRegistroFinal={setRegistroFinal}
                    setRegistroInicial={setRegistroInicial}
                    setRegistrosData={setContratos} 
                    registroInicial={registroInicial}     
                    registroFinal={registroFinal}                                                                
                    quantidadeRegistros={quantidadeRegistros}
                    itensPorPagina={itensPorPagina} 
                    urlPesquisa={`/Contrato/consulta-paginada?id=${idDistribuidor}&nivel=${nivelUsuario}`}
                />
            </tfoot>                    
            </Table>

       </section>
    )
}

export default ContratosIndex;