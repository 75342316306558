import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  IconButton, 
  Button, 
  Typography,
  Box,
  Tooltip
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Edit, Delete, Check } from '@mui/icons-material';
import { ScrollableContent, ScrollWrapper } from '../Pedidos';
import { StyledTableCell, StyledTableRow } from '../Pedidos/components/acesso/acessoIndex';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from '../../Api/apiAxios';

const validationSchema = Yup.object({
  tituloTipo: Yup.string().required('Título do tipo é obrigatório')
});

export function TipoServico() {
  const [tiposServico, setTiposServico] = useState([{ id: null, tituloTipo: '' }]);
  const [editIndex, setEditIndex] = useState(null);

  async function loadData() {
    const response = await api.get('TipoServico/');
    setTiposServico(response.data);
  }

  useEffect(() => {
    loadData();
  }, []);

  const formik = useFormik({
    initialValues: { tituloTipo: '' },
    validationSchema,
    onSubmit: async (values) => {
      if (editIndex !== null) {
        const id = tiposServico[editIndex].id;

        if (id !== 0) {
          await api.put(`/TipoServico/`, { id: id, tituloTipo: values.tituloTipo });
        } else {
          await api.post('/TipoServico/', { id: 0, tituloTipo: values.tituloTipo });
        }

        loadData();
        setEditIndex(null);
        formik.resetForm();
      }
    }
  });

  const handleEdit = (index) => {
    setEditIndex(index);
    formik.setFieldValue('tituloTipo', tiposServico[index].tituloTipo);
  };

  const handleDelete = async (id) => {
    await api.delete(`/TipoServico/${id}`);
    setTiposServico(tiposServico.filter(t => t.id !== id));
  };

  const handleAddTipo = () => {
    setTiposServico([...tiposServico, { id: 0, tituloTipo: '' }]);
    setEditIndex(tiposServico.length);
    formik.setFieldValue('tituloTipo', '');
  };

  return (
    <Container sx={{ mt: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pt: 1 }}>
        <Typography variant="h5" gutterBottom>
          Tipos de Serviço
        </Typography>

        <Button
          variant="text"
          sx={{ height: '2.5rem', fontSize: '0.78rem', color: 'rgb(19, 35, 80)', display: 'flex', alignItems: 'end' }}
          onClick={handleAddTipo}
        >
          <AddCircleIcon sx={{ marginRight: '0.5rem' }} />
          Novo Tipo
        </Button>
      </Box>

      <ScrollWrapper component={Paper} elevation={2} sx={{ marginY: 1 }}>
        <ScrollableContent sx={{ width: '101.7%' }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>ID</StyledTableCell>
                  <StyledTableCell>Título do Tipo</StyledTableCell>
                  <StyledTableCell align="right">Ações</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tiposServico.map((tipo, index) => (
                  <StyledTableRow key={tipo.id}>
                    <TableCell>{tipo.id || '-'}</TableCell>
                    <TableCell>
                      {editIndex === index ? (
                        <form onSubmit={formik.handleSubmit}>
                          <input
                            type="text"
                            name="tituloTipo"
                            value={formik.values.tituloTipo}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="form-control"
                          />
                          {formik.touched.tituloTipo && formik.errors.tituloTipo ? (
                            <div>{formik.errors.tituloTipo}</div>
                          ) : null}
                        </form>
                      ) : (
                        tipo.tituloTipo
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {editIndex === index ? (
                        <Tooltip title='Salvar'>
                          <IconButton onClick={formik.handleSubmit}>
                            <Check color='success' />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <>
                          <Tooltip title='Editar'>
                            <IconButton onClick={() => handleEdit(index)}>
                              <Edit style={{ color: '#ffc107' }}/>
                            </IconButton>
                          </Tooltip>
                          <Tooltip title='Deletar'>
                            <IconButton onClick={() => handleDelete(tipo.id)}>
                              <Delete color='error' />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ScrollableContent>
      </ScrollWrapper>
    </Container>
  );
}
