import React from "react";
import api from "../../Api/apiAxios";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loading from "../../Components/Loading";
import styles from "./GeracaoFaturamento.module.css";
import Mensagem from "../../Components/Mensagem";

const GeracaoFaturamento = () => {
    const navigate = useNavigate();

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');  

    const [loading, setLoading] = React.useState(false);
    const [mensagem, setMensagem] = React.useState(null);
    const [botaoHabilitado, setBotaoHabilitado] = React.useState(false);
    const [enviarEmail, setEnviarEmail] = React.useState(false);

    React.useEffect(() =>{
        const data = new Date();
        const dia = data.getDate();     
        if  (dia > 25)
            setBotaoHabilitado(true)
        else
            setBotaoHabilitado(false);    
    }, [])

    async function handleSubmit(ev) {
        ev.preventDefault();
        setLoading(true);        

        const email = enviarEmail ? 1: 0;
        const response = await api.get(`/Faturamento/${email}`);
        setMensagem(response.data);

        setLoading(false);
    }


    if  (loading) return <Loading />

    return (
        <section className={styles.form}>

            <Alert style={{background: 'ghostwhite', color: 'black'}}>
                <Alert.Heading style={{textAlign: 'center'}}>Geração Faturamento Mensal</Alert.Heading>        

                <br />

                <Row>
                    <Col md='3'>
                    </Col>
                   
                    <Col md='6'>
                        <Form.Check 
                            type="switch"
                            id="enviarEmail"
                            label="Enviar E-mail para as Revendas quando o faturamento estiver finalizado"                      
                            onChange={() => setEnviarEmail(!enviarEmail)}
                            checked={enviarEmail}
                        />
                    </Col>
                </Row>

                <br />

            </Alert>

            <div className={styles.areaBtn}>    
               <Button onClick={handleSubmit} variant="outline-primary" className={styles.btn} 
                        // disabled={nivelUsuario !== "1" || (!botaoHabilitado)}>Gerar
                        disabled={nivelUsuario !== "1"}>Gerar
               </Button>               
               <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Sair</Button>
            </div>    

            {mensagem && <Mensagem mensagem={mensagem} setMensagem={setMensagem} variant="warning" /> }                                 

         </section>   
    )
}

export default GeracaoFaturamento;