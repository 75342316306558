import React from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { FolderPlus, Search } from "react-bootstrap-icons";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';

export const HeaderVersoes = ({
  titulo, 
  textoBotao, 
  pesquisa, 
  setPesquisa, 
  tipoPesquisa, 
  setTipoPesquisa, 
  pesquisarClick,
  adicionarClick
}) => {
  const [pesquisarPor, setPesquisarPor] = React.useState('0');
  function atualizarTipoPesquisa( {target} ) {
    setPesquisarPor(target.value);
    setTipoPesquisa(target.value);
  }

  return (
    <Alert style={{background: 'ghostwhite', color: 'dimGray', padding: '2rem'}}>
      <Row style={{width: '100vw', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <Col md='2'>
          <Alert.Heading style={{textAlign: 'left', fontSize: '2rem', marginBottom: '0'}}>{titulo}</Alert.Heading>
        </Col>    

        <Col md='5' style={{display: 'flex', gap: '1rem'}}>
          <Form.Select onChange={({target}) => atualizarTipoPesquisa({target})} value={pesquisarPor} className="form-select-control" >
            <option disabled value="0">Opções</option>
            <option value="*">Todos</option>
            <option value="ponto">Ponto</option>
            <option value="acesso">Acesso</option>
            <option value="folha">Folha</option>
            <option value="versao">Número Versão</option>
          </Form.Select>      
          <InputGroup>
            <Form.Control placeholder="Pesquisa Parcial:" value={pesquisa} style={{fontSize: '0.8rem'}} onChange={({ target }) => setPesquisa(target.value)}/>
            <Button onClick={pesquisarClick} variant="outline-primary">
              <Search size={15} />
            </Button>
          </InputGroup>
        </Col>    

        {textoBotao !== "hide" ? (
          <Col md='3'>
            <Button onClick={adicionarClick} variant="outline-primary" style={{borderRadius: '8px'}}>
            <FolderPlus size={22}/>
              {textoBotao}
            </Button>
          </Col>
        ) : (null)}            
      </Row>             
    </Alert>
  )
}

export default HeaderVersoes;