import { Chip } from "@mui/material";

export default function TipoInstalacao({ tipoInstalacao, isPonto = false, isPedido = false }) {
  let color = '';
  let statusTitle = '';

  if (tipoInstalacao === 0) {
    color = 'tomato';
    statusTitle = 'Licenciado';
  } else if (tipoInstalacao === 1) {
    color = 'deepskyblue';
    statusTitle = isPonto ? 'SaaS (Rep-C)' : 'SaaS';
  } else if (isPonto && tipoInstalacao === 2) {
    color = 'blueviolet';
    statusTitle = 'SaaS (Rep-P)';
  }

  return isPedido ? (
    <Chip
      label={statusTitle}
      variant="outlined"
      size="small"
      sx={{ color: color, border: `1px solid ${color}`, fontWeight: 'bold' }}
    />
  ) : (
    <td style={{ fontSize: "small", color: color, fontWeight: "bold" }}>
      {statusTitle}
    </td>
  );
}
