import React from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';

export const HeaderContratos = ( {titulo, pesquisa, setPesquisa, 
                               tipoPesquisa, setTipoPesquisa, 
                               pesquisarClick} ) => {

    const [pesquisarPor, setPesquisarPor] = React.useState('0');

    function atualizarTipoPesquisa( {target} ) {
        setPesquisarPor(target.value);
        setTipoPesquisa(target.value);
    }

    return (
        <Alert style={{background: 'ghostwhite', color: 'dimGray'}}>
            <Row>
              <Col md='3'>
                 <Alert.Heading style={{textAlign: 'left', fontSize: '1.5rem'}}>{titulo}</Alert.Heading>
              </Col>    

              <Col md='2'>
                <Form.Select  onChange={({target}) => atualizarTipoPesquisa({target})} value={pesquisarPor} className=" form-select-control" >
                        <option disabled value="0">Opções</option>
                        <option value="ns">Número Série</option>
                        <option value="distribuidor">Revenda</option>
                        <option value="cliente">Cliente</option>
                        <option value="sistema">Sistema</option>
                        <option value="ativos">Ativos</option>
                        <option value="cancelados">Mostrar Todos</option>
                </Form.Select>      
              </Col>

              <Col md='3'>
                <InputGroup className="mb-3">
                    <Form.Control placeholder="Pesquisa Parcial:" value={pesquisa}
                                  style={{fontSize: '0.8rem'}}
                                  onChange={({ target }) => setPesquisa(target.value)}/>

                        <Button onClick={pesquisarClick} variant="outline-primary">
                            <Search size={15} />
                        </Button>
                </InputGroup>
              </Col>    

              <Col md='1'>
              </Col>     
             </Row>             
        </Alert>
    )
}

export default HeaderContratos;