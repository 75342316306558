import axios from "axios";

const url = process.env.REACT_APP_API_BASEURL;
const isDevMode = process.env.NODE_ENV === "development";

const protocol = window.location.protocol;
const host = window.location.host;
const path = window.location.pathname;

const linkApi = `${protocol}//${host}${path}api/api/`;


const api = axios.create({
  baseURL: isDevMode
    ? `${url}`

    : `${linkApi}`,     

});

api.interceptors.request.use(
  async config => {
    config.headers = {
      'Content-Type': config.file ? 'multipart/form-data; charset=utf-8' : 'application/json; charset=utf-8',
      'Access-Control-Allow-Origin': '*',
      Accept: 'application/json',
    };       
    const token = window.localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return Promise.resolve(config);
  },
  error => {
    return Promise.reject(error);
  },
);

export default api;