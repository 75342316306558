import { useState } from 'react';
import { PlusSquare, DashSquare } from 'react-bootstrap-icons';

import styles from './ExpandableTable.module.css'

export const TableRow = ({ item, columns, renderExpandedRow, bold = false, boxShadow = false, bgColor = 'dodgerblue', color = '', iconColor = 'gray' }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => setIsExpanded(old => !old);
  const row = (
    <tr style={{backgroundColor: bgColor, boxShadow: boxShadow ? '1px 1px 5px gray' : '0'}}>
      <th style={{backgroundColor: bgColor}}>
        {item.isExpandable ? 
          <button className={styles.expandButton} onClick={handleToggle}>
            {isExpanded ? <DashSquare size={24} color={iconColor} /> : <PlusSquare size={24} color={iconColor} />}
          </button> 
        : null}
      </th>
      {columns.map(col => <td style={{backgroundColor: bgColor, fontWeight: bold ? 'bold' : 'normal', color: color}}>{col.renderCell(item)}</td>)}
    </tr>
  )

  if (isExpanded) {
    return (
      <>
        {row}

        <tr>
          <td colSpan={columns.length + 1}>
            {renderExpandedRow?.(item)}
          </td>
        </tr>
      </>
    );
  }

  return row;
}