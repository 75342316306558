import { Table } from "react-bootstrap";

import styles from './ExpandableTable.module.css';
import { TableRow } from './TableRow.js';

export const ExpandableTable = ({ 
  columns, 
  items, 
  renderExpandedRow, 
  total = 0, 
  anotherTotal = '', 
  totalize = false, 
  headerBgColor = 'dodgerblue',
  headerColor = 'whitesmoke' 
}) => {
  let totalizeColumns = [{title: columns[0].title, renderCell: (item) => item.totalTitle}]
  
  for (var i = 1; i <= (columns.length - 2); i++) {
    totalizeColumns[i] = {title: columns[i].title, renderCell: (item) => item.emptyValue}
  }

  totalizeColumns[columns.length - 1] = {title: columns[columns.length - 1].title, renderCell: (item) => item.totalValue };

  return (
    <Table hover responsive>
      <thead className={styles.tableHeader} style={{backgroundColor: headerBgColor, color: headerColor}}>
        <th></th>

        {columns.map(col => <th>{col.title}</th>)}
      </thead>

      <tbody className={styles.registros}>
        {items.map(item => (
          <TableRow
            item={item}
            columns={columns}
            iconColor={headerBgColor === 'dodgerblue' ? headerBgColor : 'gray'}
            renderExpandedRow={renderExpandedRow}
            bgColor='white'
          />
        ))}
        {totalize && 
          <>
            <TableRow
              item={total}
              columns={totalizeColumns}
              renderExpandedRow={renderExpandedRow}
              bold={true}
              bgColor='white'
            />
          </>
        }
        { totalize && anotherTotal !== '' &&
          <>
            <TableRow
              item={anotherTotal}
              columns={totalizeColumns}
              renderExpandedRow={renderExpandedRow}
              bold={true}
              boxShadow={true}
              bgColor={headerBgColor}
              color={headerColor}
            />
          </>
        }
      </tbody>
    </Table>
  );
}
