import { Routes, Route } from 'react-router-dom';

import DistribuidoresIndex from "../Forms/Distribuidores/DistribuidoresIndex";
import UsuariosIndex from "../Forms/Usuarios/UsuariosIndex";
import NovoUsuario from "../Forms/Usuarios/NovoUsuario";
import EditarUsuario from "../Forms/Usuarios/EditarUsuario";
import NovoDistribuidor from "../Forms/Distribuidores/NovoDistribuidor";
import EditarDistribuidor from "../Forms/Distribuidores/EditarDistribuidor";
import DistribuidorClientes from "../Forms/Distribuidores/DistribuidorClientes";
import ClientesIndex from "../Forms/Clientes/ClientesIndex";
import NovoCliente from "../Forms/Clientes/NovoCliente";
import EditarCliente from "../Forms/Clientes/EditarCliente";
import ClientesCnpjIndex from "../Forms/Clientes/ClientesCnpjIndex";
import ContratosIndex from "../Forms/Contratos/ContratosIndex";
import ViewContrato from "../Forms/Contratos/ViewContrato";
import ServicosIndex from "../Forms/Servicos/ServicosIndex";
import NovoServico from "../Forms/Servicos/NovoServico";
import EditarServico from "../Forms/Servicos/EditarServico";
import ViewServico from "../Forms/Servicos/ViewServico";
import CancelarServico from "../Forms/Servicos/CancelarServico";
import GeracaoFaturamento from "../Forms/Faturamento/GeracaoFaturamento";
import RelatorioFaturamento from "../Forms/Faturamento/RelatorioFaturamento";
import RelatorioParcialFaturamento from "../Forms/Faturamento/RelatorioParcialFaturamento";
import RelatorioParcialFaturamentoRevenda from "../Forms/Faturamento/RelatorioParcialFaturamentoRevenda";
import RelatorioPedidosOSFaturadas from "../Forms/Faturamento/RelatorioPedidosOSFaturadas";
import { RelatorioDesempenhoRevenda } from '../Forms/Graficos/RelatorioDesempenhoRevenda/RelatorioDesempenhoRevenda';
import PersonalizacaoPontoIndex from "../Forms/Personalizacoes/BmaPonto/PersonalizacaoPontoIndex";
import EditarPersonalizacaoPonto from "../Forms/Personalizacoes/BmaPonto/EditarPersonalizacaoPonto";
import ViewPersonalizacaoPonto from "../Forms/Personalizacoes/BmaPonto/ViewPersonalizacaoPonto";
import PersonalizacaoAcessoIndex from "../Forms/Personalizacoes/BmaAcesso/PersonalizacaoAcessoIndex";
import EditarPersonalizacaoAcesso from "../Forms/Personalizacoes/BmaAcesso/EditarPersonalizacaoAcesso";
import ViewPersonalizacaoAcesso from "../Forms/Personalizacoes/BmaAcesso/ViewPersonalizacaoAcesso";
import PersonalizacaoFolhaIndex from "../Forms/Personalizacoes/BmaFolha/PersonalizacaoFolhaIndex";
import EditarPersonalizacaoFolha from "../Forms/Personalizacoes/BmaFolha/EditarPersonalizacaoFolha";
import ViewPersonalizacaoFolha from "../Forms/Personalizacoes/BmaFolha/ViewPersonalizacaoFolha";
import GraficosIniciais from "../Forms/Graficos/GraficosIniciais";
import VersoesIndex from "../Forms/Versoes/VersoesIndex";
import NovaVersao from "../Forms/Versoes/NovaVersao";
import ClienteVersoes from "../Forms/Versoes/ClienteVersoes";
import Dashboard from "../Forms/Graficos/Dashboard";
import ConsultaClientes from "../Forms/Clientes/ConsultaClientes";
import EditarClienteGlobal from "../Forms/Clientes/EditarClienteGlobal";
import ConsultaPorTipoPlanoOuVertical from "../Forms/Personalizacoes/Consultas/ConsultaPorTipoPlanoOuVertical";
import Login from "../Forms/Login/Login";
import Home from '../Forms/Home/Home';
import { ContratosCancelados } from '../Forms/Contratos/Relatorios/ContratosCancelados';
import { ProtectedRoute } from './ProtectedRoute';
import { DashboardAlternado } from '../Forms/DashboardAlternado';
import { DashboardDetails } from '../Forms/DashboardAlternado/Detalhes/DashboardDetails';
import { PermissaoNegada } from './PermissaoNegada';
import { Pedidos } from '../Forms/Pedidos';
import { PedidoPonto } from '../Forms/Pedidos/components/ponto/pedidoPonto';
import { PedidoFolha } from '../Forms/Pedidos/components/folha/pedidoFolha';
import { PedidoAcesso } from '../Forms/Pedidos/components/acesso/pedidoAcesso';
import { MotivoCancelamento } from '../Forms/MotivoCancelamento';
import { TipoServico } from '../Forms/TipoServico';
import { AlterarSenha } from '../Forms/AlterarSenha';

export function Router() {
    return (
        <Routes>    
            <Route path="login" element={ <Login /> }/>

            <Route element={<ProtectedRoute />}>
              <Route path='/' element={<Home />}/>

              <Route path="clientes" element={<ClientesIndex />}/>
              <Route path="clientecnpj/:id" element={<ClientesCnpjIndex />}/>

              <Route path="pedidos" element={<Pedidos />}/>
              <Route path="viewpedidoacesso/:id" element={<PedidoAcesso isShow/>} />
              <Route path="viewpedidoponto/:id" element={<PedidoPonto isShow/>} />
              <Route path="viewpedidofolha/:id" element={<PedidoFolha isShow/>} />

              <Route path="motivocancelamento" element={<MotivoCancelamento isShow/>} />

              <Route path="tiposervico" element={<TipoServico />} />

              <Route path="personalizacoesponto" element={<PersonalizacaoPontoIndex />}/>
              <Route path="viewpersonalizacaoponto/:id" element={<ViewPersonalizacaoPonto />}/>

              <Route path="personalizacoesacesso" element={<PersonalizacaoAcessoIndex />}/>
              <Route path="viewpersonalizacaoacesso/:id" element={<ViewPersonalizacaoAcesso />}/>

              <Route path="clienteversoes" element={<ClienteVersoes />}/>
              <Route path="personalizacoesplanovertical" element={<ConsultaPorTipoPlanoOuVertical />}/>

              <Route path="personalizacoesfolha" element={<PersonalizacaoFolhaIndex />}/>
              <Route path="viewpersonalizacaofolha/:id" element={<ViewPersonalizacaoFolha />}/>

              <Route path="relatoriopedidos" element={<RelatorioPedidosOSFaturadas />}/>
              <Route path="relatoriofaturamento" element={<RelatorioFaturamento />}/>
              <Route path="relatorioparcialfaturamentorevenda" element={<RelatorioParcialFaturamentoRevenda />}/>
              <Route path="relatorioparcialfaturamento" element={<RelatorioParcialFaturamento />}/>

              <Route path="dashboard" element={<Dashboard />}/>

              <Route path="graficosiniciais" element={<GraficosIniciais />}/>

              <Route path="401" element= {<PermissaoNegada />}/>

              <Route path="viewrevenda/:id" element={<EditarDistribuidor isShow/>}/>

              <Route path="alterarsenha" element={<AlterarSenha />}/>
            </Route>

            <Route element={<ProtectedRoute onlyDefault={true} />}>
              <Route path="usuarios" element={ <UsuariosIndex />}/>

              <Route path="revendas" element={ <DistribuidoresIndex />}/>           
              <Route path="revendaclientes/:id" element={<DistribuidorClientes />}/>

              <Route path="contratos" element={<ContratosIndex />}/>
              <Route path="viewcontrato/:id" element={<ViewContrato />}/>

              <Route path="servicos" element={<ServicosIndex />}/>
              <Route path="viewservico/:id" element={<ViewServico />}/>

              <Route path="geracaofaturamento" element={<GeracaoFaturamento />}/>
              
              <Route path="desempenho" element={<RelatorioDesempenhoRevenda />}/>
              <Route path="contratoscancelados" element={<ContratosCancelados />}/>

              <Route path="dashboard2" element={<DashboardAlternado />} />
              <Route path="dashboard2details/:qtd" element={<DashboardDetails />} />
              
              <Route path="versoes" element={<VersoesIndex />}/>

              <Route path="consultaclientes" element={<ConsultaClientes />}/>
            </Route>
            
            <Route element={<ProtectedRoute changes={true} />}>
              <Route path="novocliente" element={<NovoCliente />}/>
              <Route path="editarcliente/:id" element={<EditarCliente />}/>

              <Route path="pedidoacesso" element={<PedidoAcesso />} />
              <Route path="pedidoacesso/:id" element={<PedidoAcesso />} />

              <Route path="pedidoponto" element={<PedidoPonto />} />
              <Route path="pedidoponto/:id" element={<PedidoPonto />} />

              <Route path="pedidofolha" element={<PedidoFolha />} />
              <Route path="pedidofolha/:id" element={<PedidoFolha />} />

              <Route path="editarpersonalizacaoponto/:id" element={<EditarPersonalizacaoPonto />}/>

              <Route path="editarpersonalizacaoacesso/:id" element={<EditarPersonalizacaoAcesso />}/>

              <Route path="editarpersonalizacaofolha/:id" element={<EditarPersonalizacaoFolha />}/>
            </Route>

            <Route element={<ProtectedRoute changes={true} onlyDefault={true} />}>
              <Route path="novousuario" element={<NovoUsuario />} />
              <Route path="editarusuario/:id" element={<EditarUsuario />}/>

              <Route path="novarevenda" element={<NovoDistribuidor />}/>
              <Route path="editarrevenda/:id" element={<EditarDistribuidor />}/>

              <Route path="novoservico" element={<NovoServico />}/>
              <Route path="editarservico/:id" element={<EditarServico />}/>
              <Route path="cancelarservico/:id" element={<CancelarServico />}/>

                <Route path="novaversao" element={<NovaVersao /> } />
                <Route path="novaversao/:id" element={<NovaVersao /> } />

              <Route path="editarclienteglobal/:id" element={<EditarClienteGlobal />}/>
            </Route>
        </Routes>       
    )
}