import { useState, useRef, useContext } from 'react';
import { Box, IconButton, Paper, Tooltip, styled, ClickAwayListener } from "@mui/material";

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import FeedIcon from '@mui/icons-material/Feed';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from 'react-router-dom';
import { PedidosContext } from '../../../../Context/PedidosContext';

export function ActionButtons({ show = false, pedido }) {
  const [isVisible, setIsVisible] = useState(show);
  const anchorRef = useRef(null);

  const nivelUsuario = window.localStorage.getItem('nivelUsuario');

  const { productVariant, setModalLiberacao, setModalCancelamento } = useContext(PedidosContext)

  const navigate = useNavigate();

  const handleIconClick = () => {
    setIsVisible(true);
  };

  const handleClickAway = () => {
    setIsVisible(false);
  };

  const handleEditarPedidoClick = () => {
    const routeMap = {
      Acesso: `/pedidoacesso/${pedido.id}`,
      Ponto: `/pedidoponto/${pedido.id}`,
      Folha: `/pedidofolha/${pedido.id}`
    };
    navigate(routeMap[productVariant] || '/');
  };

  const handleVisualizarPedidoClick = () => {
    const routeMap = {
      Acesso: `/viewpedidoacesso/${pedido.id}`,
      Ponto: `/viewpedidoponto/${pedido.id}`,
      Folha: `/viewpedidofolha/${pedido.id}`
    };
    navigate(routeMap[productVariant] || '/');
  };

  const handleLiberarPedidoClick = () => {
    setModalLiberacao({ show: true, pedido:  pedido })
    setIsVisible(false);
  }

  const handleCancelarPedidoClick = () => {
    setModalCancelamento({ show: true, pedido:  pedido })
    setIsVisible(false);
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <IconButton ref={anchorRef} onClick={handleIconClick}>
        <MoreHorizIcon color='action' fontSize='small' sx={{ cursor: 'pointer' }} />
      </IconButton>
      {isVisible && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <StyledPaper elevation={2}>
            <Box sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              minWidth: '7rem',
              maxWidth: '15rem',
              alignItems: 'center',
              paddingLeft: '0.4rem'
            }}>
              <Tooltip title='Editar'>
                <BaseActionButton color='#ffc107' onClick={handleEditarPedidoClick} disabled={nivelUsuario === '2' || nivelUsuario === '4' || pedido.statusPedido > 0}>
                  <EditIcon />
                </BaseActionButton>
              </Tooltip>

              <Tooltip title='Visualizar'>
                <BaseActionButton color='#198754' onClick={handleVisualizarPedidoClick}>
                  <FeedIcon />
                </BaseActionButton>
              </Tooltip>

              <Tooltip title='Liberar'>
                <BaseActionButton color='#0d6efd' onClick={handleLiberarPedidoClick} disabled={nivelUsuario !== '1' || pedido.statusPedido > 0}>
                  <DriveFolderUploadIcon />
                </BaseActionButton>
              </Tooltip>

              <Tooltip title='Cancelar'>
                <BaseActionButton color='#dc3545' onClick={handleCancelarPedidoClick} disabled={nivelUsuario !== '1' || pedido.statusPedido > 1}>
                  <CancelIcon />
                </BaseActionButton>
              </Tooltip>
            </Box>
          </StyledPaper>
        </ClickAwayListener>
      )}
    </Box>
  );
}

export const BaseActionButton = styled(IconButton)(({ color }) => ({
  boxShadow: `0 0 1.5px ${color}`,
  color: color,
  margin: '0 0.5rem 0.3rem 0',
}));

const StyledPaper = styled(Paper)({
  position: 'absolute',
  top: 30,
  left: -60,
  zIndex: 1000,
  padding: '1rem 0',
  borderRadius: '30px',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: -8, 
    right: 20,
    width: 0,
    height: 0,
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
    borderBottom: '8px solid rgb(104,104,104, 0.18) ',
    zIndex: -1,
  }
});
