import { useContext, useEffect, useState } from "react";
import { Box, Grid, MenuItem, Button, FormControlLabel, Switch } from "@mui/material";
import { FormControlBase, InputLabelSelect, SelectBase, FormSectionTitle } from "../../styles";
import { PedidosContext } from "../../../../Context/PedidosContext";
import Loading from "../../../../Components/Loading";

export function FiltrosIndex() {
  const [vertical, setVertical] = useState(null);
  const [plano, setPlano] = useState(null);
  const [mostrarCancelados, setMostrarCancelados] = useState(false);
  const [loading, setLoading] = useState(false);

  const { 
    setSelectFilter, 
    selectFilter, 
    fetchPedidosAcesso, 
    fetchPedidosPonto, 
    fetchPedidosFolha, 
    setIsLoading, 
    productVariant
  } = useContext(PedidosContext);

  const handleVerticalChange = (event) => {
    setVertical(event.target.value);
  };

  const handlePlanoChange = (event) => {
    setPlano(event.target.value);
  };

  const handleSwitchChange = (event) => {
    setMostrarCancelados(event.target.checked);
  };

  const handleFilterApply = async () => {
    let filterCount = 0;

    if (vertical !== null && vertical !== 0) filterCount += 1;
    if (plano !== null) filterCount += 1;
    if (mostrarCancelados) filterCount += 1;

    setIsLoading(true);

    setSelectFilter({ 
      selectedFilters: filterCount, 
      showModal: false,
      mostrarCancelados: mostrarCancelados,
      campoSelect: productVariant === 'Acesso' ? vertical : (productVariant === 'Ponto' ? plano : null)
    });

    if (productVariant === 'Acesso') {
      fetchPedidosAcesso(mostrarCancelados, vertical, true)
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false));
    } else if (productVariant === 'Ponto') {
      fetchPedidosPonto(mostrarCancelados, plano, true)
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false));
    } else {
      fetchPedidosFolha(mostrarCancelados, true)
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false));
    }
  };

  const handleClearFilters = () => {
    setVertical(0);
    setPlano(null);
    setMostrarCancelados(false);

    setSelectFilter({
      selectedFilters: 0, 
      showModal: true,
      mostrarCancelados: false,
      campoSelect: null
    });
  };

  useEffect(() => {
    if (selectFilter.selectedFilters > 0) {
      setLoading(true);
      if (productVariant === 'Acesso') setVertical(selectFilter.campoSelect);
      if (productVariant === 'Ponto') setPlano(selectFilter.campoSelect);
      setMostrarCancelados(selectFilter.mostrarCancelados);
    }

    setLoading(false);
  }, [])

  if (loading) return <Loading />;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{
        backgroundColor: 'white', 
        py: 3, 
        px: 1, 
        my: 2, 
        borderRadius: '30px', 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'start',
        width: '55%', 
        alignSelf: 'center', 
        position: 'fixed', 
        top: 90,  
        zIndex: 1000, 
        maxHeight: '80vh',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
      }}>
        <FormSectionTitle label='Selecionar Filtros' />
        <Grid container spacing={2} sx={{ pb: 2, px: 4, mt: 3, display: 'flex' }}>

          {productVariant === 'Acesso' ? (
            <Grid item xs={12}>
              <FormControlBase>
                <InputLabelSelect id="vertical">Vertical</InputLabelSelect>
                <SelectBase
                  id="vertical"
                  name="vertical"
                  label="Vertical"
                  value={vertical}
                  onChange={handleVerticalChange}
                  width="100%"
                >
                  <MenuItem value={1}>Empresa</MenuItem>
                  <MenuItem value={2}>Refeitório</MenuItem>
                  <MenuItem value={3}>Academia</MenuItem>
                  <MenuItem value={4}>Condomínio</MenuItem>
                  <MenuItem value={5}>Hospital</MenuItem>
                  <MenuItem value={6}>Escola</MenuItem>
                </SelectBase>
              </FormControlBase>
            </Grid>
          ) : ( productVariant === 'Ponto' && (
            <Grid item xs={12}>
              <FormControlBase>
                <InputLabelSelect  id="tipoPlano">Tipo de Plano</InputLabelSelect>
                <SelectBase 
                  id="tipoPlano" 
                  label="Tipo de Plano"
                  name="tipoPlano"
                  value={plano}
                  onChange={handlePlanoChange}
                  width='100%'
                >
                    <MenuItem value={0}>Licenciado</MenuItem>
                    <MenuItem value={1}>SaaS (Rep-C)</MenuItem>
                    <MenuItem value={2}>SaaS (Rep-P)</MenuItem>
                </SelectBase>
              </FormControlBase>
            </Grid>
          ))}

          <Grid container spacing={2} sx={{ pb: 1, px: 4, mt: 0.5, display: 'flex' }}>
            <Grid item xs={6}>
              <FormControlLabel 
                control={<Switch
                  checked={mostrarCancelados}
                  onChange={handleSwitchChange}
                />} 
                label="Mostrar Cancelados" 
              />
            </Grid>
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 0.5, pt: 2, width: '100%' }}>
          <Button variant="outlined" onClick={() => setSelectFilter({ ...selectFilter, showModal: false })}>
            Voltar
          </Button>

          <Button 
            variant="outlined" 
            onClick={handleClearFilters} 
            disabled={vertical === 0 && !mostrarCancelados}
          >
            Limpar Filtros
          </Button>

          <Button variant="contained" onClick={handleFilterApply}>
            Aplicar
          </Button>
        </Box>

      </Box>
    </Box>
  );
}
