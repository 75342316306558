import React, { useState, useEffect } from "react";
import { ContratosCancelados30Perc } from "./Gráficos/ContratosCancelados30/ContratosCancelados30Perc";
import { ContratosCancelados30Qtd } from "./Gráficos/ContratosCancelados30/ContratosCancelados30Qtd";
import { ContratosCancelados60Perc } from "./Gráficos/ContratosCancelados60/ContratosCancelados60Perc";
import { ContratosCancelados60Qtd } from "./Gráficos/ContratosCancelados60/ContratosCancelados60Qtd";
import { ContratosCancelados90Perc } from "./Gráficos/ContratosCancelados90/ContratosCancelados90Perc";
import { ContratosCancelados90Qtd } from "./Gráficos/ContratosCancelados90/ContratosCancelados90Qtd";
import { TotaisContratos } from "./Gráficos/Totais/TotaisContratos";
import Loading from "../../Components/Loading";

import styles from "./DashboardAlternado.module.css";
import { DashboardAlternadoContext } from "../../Context/DashboardAlternadoContext";

export function DashboardAlternado() {
  const [componentIndex, setComponentIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const components = [<ContratosCancelados30Perc />, <ContratosCancelados30Qtd />, 
    <ContratosCancelados60Perc />, <ContratosCancelados60Qtd />,
    <ContratosCancelados90Perc />, <ContratosCancelados90Qtd />,
    <TotaisContratos />
  ];

  const { initContratos } = React.useContext(DashboardAlternadoContext);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        initContratos();
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);

        const startInterval = () => {
          return setInterval(() => {
            setComponentIndex(prevIndex => (prevIndex + 1) % components.length);
          }, 12000);
        };

        let interval = startInterval();
        
        const handleKeyPress = (event) => {
          clearInterval(interval);

          if (event.key === "ArrowLeft") {
            setComponentIndex((prevIndex) =>
              prevIndex === 0 ? components.length - 1 : prevIndex - 1
            );
          } else if (event.key === "ArrowRight") {
            setComponentIndex((prevIndex) =>
              (prevIndex + 1) % components.length
            );
          }

          interval = startInterval();
        };

        document.addEventListener("keydown", handleKeyPress);
  
        return () => clearInterval(interval);
      }
    }
    
    fetchData();
  }, []);

  if (loading) return <Loading />;

  return (
    <div className={styles.dashboardContainer}>
      {components.map((component, index) => (
        <div key={index} className={`${styles.component} ${index === componentIndex ? styles.active : ''}`}>
            {component}
        </div>
      ))}
    </div>
  );
}