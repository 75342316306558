import React, { useContext } from "react";
import api from "../../Api/apiAxios";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../Components/Loading";
import { MyContext } from "../../Context/MyContext";
import Mensagem from "../../Components/Mensagem";
import { Button, Form, Row, Col, FloatingLabel, Alert } from "react-bootstrap";
import styles from "./ViewContrato.module.css";
import { PedidosContext } from "../../Context/PedidosContext";

const ViewContrato = () => {
    const { id } = useParams();

    const navigate = useNavigate();

    const { mensagemGlobal, setMensagemGlobal, tipoMensagemGlobal } = React.useContext(MyContext);

    const [loading, setLoading] = React.useState(false);

    const [razaoSocialDistribuidor, setRazaoSocialDistribuidor] = React.useState('');
    const [razaoSocialCliente, setRazaoSocialCliente] = React.useState('');
    const [inscricao, setInscricao] = React.useState('');
    const [numeroSerie, setNumeroSerie] = React.useState('');
    const [valorContrato, setValorContrato] = React.useState(0);
    const [sistema, setSistema] = React.useState('');
    const [pedidoId, setPedidoId] = React.useState(0);
    const [dataAtivacao, setDataAtivacao] = React.useState(``);
    const [dataAtualizacao, setDataAtualizacao] = React.useState(``);
    const [dataCancelamento, setDataCancelamento] = React.useState(``);
    const [statusContrato, setStatusContrato] = React.useState('');
    const [tipoContrato, setTipoContrato] = React.useState('');
    const [dataEntradaPedido, setDataEntradaPedido] = React.useState('');
    const [dataLiberacaoPedido, setDataLiberacaoPedido] = React.useState('');
    const [faixaFuncionarios, setFaixaFuncionarios] = React.useState(0);
    const [tipoVertical, setTipoVertical] = React.useState('');
    const [tipoInstalacao, setTipoInstalacao] = React.useState('');
    const [valorPedido, setValorPedido] = React.useState(0);
    const [motivoCancelamento, setMotivoCancelamento] = React.useState('');
    const [motivoCancelamentoOpcao, setMotivoCancelamentoOpcao] = React.useState(null);

    const { motivosCancelamento } = useContext(PedidosContext);


    React.useEffect(() => {
        (async () => {
            try {   
                setLoading(true);
                const response = await api.get(`/Contrato/${id}`);  
              
                setPedidoId(response.data.pedidoId);
                setSistema(response.data.sistema);
                setTipoContrato(response.data.tipoContrato);
                setNumeroSerie(response.data.numeroSerie);
                setValorContrato(response.data.valor);
                setRazaoSocialCliente(response.data.cliente.razaoSocial);
                setInscricao(response.data.cliente.numeroInscricao);
                setRazaoSocialDistribuidor(response.data.distribuidor.razaoSocial);
                setMotivoCancelamento(response.data.motivoCancelamento);
                setMotivoCancelamentoOpcao(response.data.motivoCancelamentoOpcao);

                const dataString = response.data.dataAtivacao.substring(8,10) + "/" +
                                   response.data.dataAtivacao.substring(5,7)  + "/" +   
                                   response.data.dataAtivacao.substring(0,4);
                setDataAtivacao(dataString);                                   

                const dataString2 = response.data.dataAtualizacao.substring(8,10) + "/" +
                                   response.data.dataAtualizacao.substring(5,7)  + "/" +   
                                   response.data.dataAtualizacao.substring(0,4);
                setDataAtualizacao(dataString2);                                   

                if (response.data.dataCancelamento !== null) {
                    const dataCancelamentoString = response.data.dataCancelamento.substring(8,10) + "/" +
                                                   response.data.dataCancelamento.substring(5,7)  + "/" +   
                                                   response.data.dataCancelamento.substring(0,4);
                    setDataCancelamento(dataCancelamentoString);
                }
                else {
                    setDataCancelamento('* * * * * *');
                }

                if  (response.data.statusContrato === 1) {
                  setStatusContrato("Ativo")
                } else if (response.data.statusContrato === 0) {
                  setStatusContrato("Cancelado"); 
                } else {
                  setStatusContrato("Baixado"); 
                }

                if (response.data.pedidoId > 0) {
                    const idPedido = Number(response.data.pedidoId);
                    switch (response.data.sistema) { 
                        case "PONTO" :                     
                             const resp =  await api.get(`/BmaPonto/${idPedido}`);    
                             const dataPedidoEditada = resp.data.dataPedido.substring(8,10) + "/" +
                                   resp.data.dataPedido.substring(5,7)  + "/" +   
                                   resp.data.dataPedido.substring(0,4);
                             setDataEntradaPedido(dataPedidoEditada);                                   

                             const dataLiberacaoEditada = resp.data.dataLiberacao.substring(8,10) + "/" +
                                   resp.data.dataLiberacao.substring(5,7)  + "/" +   
                                   resp.data.dataLiberacao.substring(0,4);
                             setDataLiberacaoPedido(dataLiberacaoEditada);                                   

                             setFaixaFuncionarios(resp.data.faixaFuncionarios);
                             setValorPedido(resp.data.valorBruto);
                             setTipoVertical('');
                             setTipoInstalacao(resp.data.tipoInstalacao)

                             break;
                        case "ACESSO" :                     
                             const resp2 = await api.get(`/BmaAcesso/${idPedido}`);    
                             const dataPedidoEditada2 = resp2.data.dataPedido.substring(8,10) + "/" +
                                   resp2.data.dataPedido.substring(5,7)  + "/" +   
                                   resp2.data.dataPedido.substring(0,4);
                             setDataEntradaPedido(dataPedidoEditada2);                                   

                             const dataLiberacaoEditada2 = resp2.data.dataLiberacao.substring(8,10) + "/" +
                                   resp2.data.dataLiberacao.substring(5,7)  + "/" +   
                                   resp2.data.dataLiberacao.substring(0,4);
                             setDataLiberacaoPedido(dataLiberacaoEditada2);                                   

                             setFaixaFuncionarios(0);
                             setValorPedido(resp2.data.valorBruto);
                             setTipoVertical(resp2.data.tipoVertical);

                             break;

                        case "FOLHA" :                     
                             const resp3 = await api.get(`/BmaFolha/${idPedido}`);    
                             const dataPedidoEditada3 = resp3.data.dataPedido.substring(8,10) + "/" +
                                   resp3.data.dataPedido.substring(5,7)  + "/" +   
                                   resp3.data.dataPedido.substring(0,4);
                             setDataEntradaPedido(dataPedidoEditada3);                                   

                             const dataLiberacaoEditada3 = resp3.data.dataLiberacao.substring(8,10) + "/" +
                                   resp3.data.dataLiberacao.substring(5,7)  + "/" +   
                                   resp3.data.dataLiberacao.substring(0,4);
                             setDataLiberacaoPedido(dataLiberacaoEditada3);                                   

                             setFaixaFuncionarios(resp3.data.faixaFuncionarios);
                             setValorPedido(resp3.data.valorBruto);
                             setTipoVertical('');

                             break;
                    }

                }


                setLoading(false);

            } catch (error) {
                alert(error);
            }            
        }
        )();

    }, [id]);                



    if  (loading) return <Loading />

    return (
        <section className={styles.form}>
            {mensagemGlobal && <Mensagem mensagem={mensagemGlobal} setMensagem={setMensagemGlobal} variant={tipoMensagemGlobal} /> }                                 

            <Alert style={{background: 'ghostwhite', color: 'black'}}>
            {statusContrato === "Ativo" &&
                <Alert.Heading style={{textAlign: 'center', color: 'seagreen'}}>Contrato Ativo</Alert.Heading>        
            }
            {statusContrato === "Cancelado" &&
                <Alert.Heading style={{textAlign: 'center', color: 'tomato'}}>Contrato Cancelado</Alert.Heading>        
            }
            {statusContrato === "Baixado" &&
                <Alert.Heading style={{textAlign: 'center', color: 'lavender'}}>Contrato Baixado</Alert.Heading>        
            }

            <br />  

            <Row className="g-2" style={{marginBottom: '1rem'}}> 
                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Tipo de Sistema">
                        <Form.Select value={sistema} onChange={({target}) => setSistema(target.value)} disabled>
                            <option value="" disabled></option>
                            <option value="PONTO">BmaPonto</option>
                            <option value="ACESSO">BmaAcesso</option>
                            <option value="FOLHA">BmaFolha</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>

                <Col md='8'>
                    <FloatingLabel controlid="floatingInputGrid" label="Revenda">
                    <Form.Control placeholder="Release" value={razaoSocialDistribuidor} disabled
                                    onChange={({ target }) => setRazaoSocialDistribuidor(target.value)}/>             
                   </FloatingLabel>    
                </Col>
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>    
                <Col md='9'>
                    <FloatingLabel controlid="floatingInputGrid2" label="Cliente">
                    <Form.Control placeholder="Release" value={razaoSocialCliente} disabled
                                    onChange={({ target }) => setRazaoSocialCliente(target.value)}/>             
                    </FloatingLabel>                  
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid20" label="CNPJ / CPF">
                    <Form.Control placeholder="Inscricao" value={inscricao} disabled
                                    onChange={({ target }) => setInscricao(target.value)}/>             
                    </FloatingLabel>                  
                </Col>
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='2'>
                     <FloatingLabel controlid="floatingInputGrid5" label="Número de Série">
                        <Form.Control placeholder="NS" value={numeroSerie} disabled
                                    onChange={({ target }) => setNumeroSerie(target.value)}/>
                      </FloatingLabel>      
                </Col>           

                <Col md='2'>
                     <FloatingLabel controlid="floatingInputGrid5" label="Data Ativação">
                        <Form.Control placeholder="Ativacao" value={dataAtivacao} disabled
                                    onChange={({ target }) => setDataAtivacao(target.value)}/>
                      </FloatingLabel>      
                </Col>                

                <Col md='2'>
                     <FloatingLabel controlid="floatingInputGrid5" label="Data Cancelamento">
                        <Form.Control placeholder="Ativacao" value={dataCancelamento} disabled
                                    onChange={({ target }) => setDataCancelamento(target.value)}/>
                      </FloatingLabel>      
                </Col>                

                <Col md='2'>
                    <FloatingLabel controlid="floatingInputGrid9" label="Tipo de Contrato">
                        <Form.Select value={tipoContrato} onChange={({target}) => setTipoContrato(target.value)} disabled>
                            <option value="" disabled></option>
                            <option value="0">Manutenção</option>
                            <option value="1">Locação</option>
                            <option value="2">Saas</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>

                <Col md='2'>
                    <FloatingLabel controlid="floatingInputGrid6" label="Valor Contrato">
                        <Form.Control placeholder="Val.Contrato" value={valorContrato} disabled
                                    onChange={({ target }) => setValorContrato(target.value)}/>
                    </FloatingLabel>      
                </Col>
            </Row>

            {statusContrato === "Cancelado" &&
            <>
              <Row>
                <Col md='12'>
                  <FloatingLabel controlid="floatingInputGrid28" label="Motivo Cancelamento">
                      <Form.Select value={motivoCancelamentoOpcao}  disabled>
                          {motivosCancelamento.map((motivo) => (
                            <option value={motivo.id}>
                              {motivo.descricaoMotivo}
                            </option>
                          ))}
                      </Form.Select>
                  </FloatingLabel> 
                </Col>
              </Row>  

              <br />
              
              <Row>
                <Col md='12'>
                  <FloatingLabel controlid="floatingInputGrid6" label="Detalhes do Cancelamento">
                    <Form.Control value={motivoCancelamento} disabled />
                  </FloatingLabel> 
                </Col>
              </Row>
            </>
            }
            </Alert>

            <Alert style={{background: 'ghostwhite', color: 'black'}}>
                <Alert.Heading style={{textAlign: 'center', color: 'seagreen'}}>Dados Pedido</Alert.Heading>        

            <br />  

            <Row>
                <Col md='2'>
                     <FloatingLabel controlid="floatingInputGrid25" label="Data Entrada Pedido">
                        <Form.Control placeholder="Entrada" value={dataEntradaPedido} disabled
                                    onChange={({ target }) => setDataEntradaPedido(target.value)}/>
                      </FloatingLabel>      
                </Col>                

                <Col md='2'>
                     <FloatingLabel controlid="floatingInputGrid26" label="Data Liberação Pedido">
                        <Form.Control placeholder="Liberacao" value={dataLiberacaoPedido} disabled
                                    onChange={({ target }) => setDataLiberacaoPedido(target.value)}/>
                      </FloatingLabel>      
                </Col>                

                <Col md='2'>
                     <FloatingLabel controlid="floatingInputGrid27" label="Faixa Func.">
                        <Form.Control placeholder="NS" value={faixaFuncionarios} disabled
                                    onChange={({ target }) => setFaixaFuncionarios(target.value)}/>
                      </FloatingLabel>      
                </Col>                

                <Col md='2'>
                     <FloatingLabel controlid="floatingInputGrid27" label="Valor Total">
                        <Form.Control placeholder="NS" value={valorPedido} disabled
                                    onChange={({ target }) => setValorPedido(target.value)}/>
                      </FloatingLabel>      
                </Col>                

                {sistema === 'ACESSO' && 
                  <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid28" label="Vertical">
                        <Form.Select value={tipoVertical} onChange={({ target }) => setTipoVertical(target.value)} disabled>
                            <option value="" disabled></option>
                            <option value="1">Empresa</option>
                            <option value="2">Refeitório</option>
                            <option value="3">Academia</option>
                            <option value="4">Condomínio</option>
                            <option value="5">Hospital</option>
                            <option value="6">Escola</option>
                        </Form.Select>
                    </FloatingLabel>                    
                  </Col>   
                }

                {sistema === 'PONTO' && 
                  <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid7" label="Tipo de Plano">
                        <Form.Select value={tipoInstalacao} disabled>
                            <option value="0">Licenciado</option>
                            <option value="1">SaaS (Rep-C)</option>
                            <option value="2">SaaS (Rep-P)</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>   
                }            

            </Row>

            </Alert>

            <div className={styles.areaBtn}>                
               <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Voltar</Button>
            </div>    

        </section>
    )

}

export default ViewContrato;