import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom'

import api from '../Api/apiAxios';
import { MyContext } from '../Context/MyContext';

export const ProtectedRoute = ({ changes = false, children, onlyDefault = false }) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (changes) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    const handlePageHide = (event) => {
      event.preventDefault();
      logoutApp();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('pagehide', handlePageHide);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('pagehide', handlePageHide);
    };
  });

  const { logoutApp, timeoutLogout } = React.useContext(MyContext);

  const token = window.localStorage.getItem('token');
  const nivelUsuario = window.localStorage.getItem('nivelUsuario');
  const navigate = useNavigate();

  if (onlyDefault === true && nivelUsuario !== "1") {
    navigate('/401');
  }

  if (token === null) {
    logoutApp();
    return;
  }

  (async () => {
    try {   
      const response = await api.get(`/Token/validar-token?tokenAtual=${token}`);
      if  (response.data === false) {
        timeoutLogout(); 
      }  
    } catch (e) {
      console.log(e);
    } 
  })();

  return children ? children : <Outlet />;
}