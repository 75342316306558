import { useContext } from "react";
import { Box, Container, Grid } from "@mui/material";
import { ButtonBase, FormControlBase, FormSectionTitle, TextFieldBase } from "../../../styles";
import { format, isAfter, isBefore, subDays } from "date-fns";
import { useFormik } from "formik";
import * as Yup from 'yup';

import { PedidosContext } from "../../../../../Context/PedidosContext";
import api from "../../../../../Api/apiAxios";

const validationSchema = Yup.object({
  valorNegociado: Yup.number().required('O valor negociado é obrigatório'),
  periodoInicialNegociacao: Yup.date()
    .required('O período inicial da negociação é obrigatório')
    .typeError('Data inválida')
    .min(subDays(new Date(), 1), 'O período inicial não pode ser anterior à data atual'),
  periodoFinalNegociacao: Yup.date()
    .required('O período final da negociação é obrigatório')
    .typeError('Data inválida')
    .when('periodoInicialNegociacao', (periodoInicialNegociacao, schema) =>
      periodoInicialNegociacao
        ? schema.min(periodoInicialNegociacao, 'O período final deve ser uma data posterior ao período inicial')
        : schema
    ),
  descricaoMotivo: Yup.string()
    .required('O motivo é obrigatório')
    .min(3, 'O motivo deve ter pelo menos 3 caracteres'),
});

export function ModalNegociacao({ idContrato }) {
  const { productVariant, fetchNegociacoesAnteriores, setModalNegociacao, modalNegociacao, setNegociacoesAnteriores } = useContext(PedidosContext);

  const handleSave = async (values) => {
    setModalNegociacao({ show: false, negociacao: {} });

    let regNegociacao = {
      Id: modalNegociacao.negociacao.id || 0,
      IdContrato: idContrato,
      PeriodoInicial: formik.values.periodoInicialNegociacao,
      PeriodoFinal: formik.values.periodoFinalNegociacao,
      ValorNegociado: Number(formik.values.valorNegociado),
      DescricaoMotivo: formik.values.descricaoMotivo,
      TipoSistema: productVariant,
    };

    const negociacoesExistentes = await fetchNegociacoesAnteriores(idContrato);

    let ret;
    if (regNegociacao.Id > 0) {
      ret = await api.put('/NegociacaoContrato/', JSON.stringify(regNegociacao), { headers: { 'Content-Type': 'application/json' } });
    } else {
      const conflito = negociacoesExistentes.some(negociacao => {
        const inicioExistente = new Date(negociacao.periodoInicial);
        const fimExistente = new Date(negociacao.periodoFinal);
        const inicioNovo = new Date(formik.values.periodoInicialNegociacao);
        const fimNovo = new Date(formik.values.periodoFinalNegociacao);
  
        return (
          (isAfter(inicioNovo, inicioExistente) && isBefore(inicioNovo, fimExistente)) ||
          (isAfter(fimNovo, inicioExistente) && isBefore(fimNovo, fimExistente)) ||
          (isAfter(inicioExistente, inicioNovo) && isBefore(fimExistente, fimNovo))
        )
      });
  
      if (conflito) {
        alert("Já existe uma negociação cadastrada para esse período.");
        return;
      }

      ret = await api.post('/NegociacaoContrato/', JSON.stringify(regNegociacao), { headers: { 'Content-Type': 'application/json' } });
    }

    console.log(ret.data)
    
    const negociacoes = await fetchNegociacoesAnteriores(idContrato);
    const negociacoesOrdenadas = negociacoes.sort((a, b) => new Date(b.periodoInicial) - new Date(a.periodoInicial));

    setNegociacoesAnteriores(negociacoesOrdenadas);
  };

  const formik = useFormik({
    initialValues: {
      valorNegociado: modalNegociacao.negociacao.valorNegociado || '',
      periodoInicialNegociacao: modalNegociacao.negociacao.periodoInicial ? format(new Date(modalNegociacao.negociacao.periodoInicial), 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd'),
      periodoFinalNegociacao: modalNegociacao.negociacao.periodoFinal ? format(new Date(modalNegociacao.negociacao.periodoFinal), 'yyyy-MM-dd') : '',
      descricaoMotivo: modalNegociacao.negociacao.descricaoMotivo || '',
    },
    validationSchema: validationSchema,
  });

  return (
    <Box component='form' sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
      <Box sx={{
        backgroundColor: 'white',
        py: 3,
        px: 1,
        my: 2,
        borderRadius: '30px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        width: '70%',
        maxWidth: '65rem',
        alignSelf: 'center',
        position: 'fixed',
        top: 100,
        zIndex: 1000,
        maxHeight: '80vh',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)'
      }}>
        <FormSectionTitle label={modalNegociacao.negociacao.id ? 'Editar Negociação' : 'Adicionar Negociação'} />
        <Container sx={{ my: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <FormControlBase>
                <TextFieldBase
                  type="date"
                  label="Período Inicial"
                  name="periodoInicialNegociacao"
                  width='100%'
                  value={formik.values.periodoInicialNegociacao}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.periodoInicialNegociacao && Boolean(formik.errors.periodoInicialNegociacao)}
                  helperText={formik.touched.periodoInicialNegociacao && formik.errors.periodoInicialNegociacao}
                />
              </FormControlBase>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControlBase>
                <TextFieldBase
                  type="date"
                  label="Período Final"
                  name="periodoFinalNegociacao"
                  width='100%'
                  value={formik.values.periodoFinalNegociacao}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.periodoFinalNegociacao && Boolean(formik.errors.periodoFinalNegociacao)}
                  helperText={formik.touched.periodoFinalNegociacao && formik.errors.periodoFinalNegociacao}
                />
              </FormControlBase>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControlBase>
                <TextFieldBase
                  label="Valor Negociado (R$)"
                  name="valorNegociado"
                  width='100%'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.valorNegociado && Boolean(formik.errors.valorNegociado)}
                  helperText={formik.touched.valorNegociado && formik.errors.valorNegociado}
                  placeholder="0.00"
                  type="number"
                  value={formik.values.valorNegociado}
                />
              </FormControlBase>
            </Grid>
            <Grid item xs={12}>
              <FormControlBase>
                <TextFieldBase
                  label="Motivo"
                  name='descricaoMotivo'
                  width='100%'
                  value={formik.values.descricaoMotivo}
                  onChange={formik.handleChange}
                  multiline
                  rows={4}
                  onBlur={formik.handleBlur}
                  error={formik.touched.descricaoMotivo && Boolean(formik.errors.descricaoMotivo)}
                  helperText={formik.touched.descricaoMotivo && formik.errors.descricaoMotivo}
                />
              </FormControlBase>
            </Grid>
          </Grid>
        </Container>

        <Box sx={{ display: 'flex', width: '95%', justifyContent: 'flex-end', gap: 2, my: 1 }}>
          <ButtonBase variant="outlined" onClick={() => setModalNegociacao({ show: false, negociacao: {} })}>
            Cancelar
          </ButtonBase>
          <ButtonBase type="submit" variant="contained" onClick={handleSave}>
            Salvar
          </ButtonBase>
        </Box>
      </Box>
    </Box>
  );
}
