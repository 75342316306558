import { Box, Button, Typography } from "@mui/material";
import { FormSectionTitle } from "../../Pedidos/styles";
import { Container } from "react-bootstrap";

export function ModalConfirmacao({ setModalConfirmacao, onConfirm, produto }) {

  return (
    <Box sx={{
      backgroundColor: 'white',
      py: 3,
      px: 1,
      my: 2,
      borderRadius: '30px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      width: '50%',
      alignSelf: 'center',
      position: 'fixed',
      top: 250,
      left: 450,
      zIndex: 1000,
      maxHeight: '80vh',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    }}>
      <FormSectionTitle label='Confirmar Atualização' />
      <Container sx={{ my: 3, justifyContent: 'center', width: '100%' }}>
        <Typography sx={{fontSize: '1.3rem', mt: 2}}>Deseja atualizar todas as licenças ativas de {produto}?</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 3 }}>
          <Button variant="outlined" onClick={() => setModalConfirmacao({show: false, produto: '', onConfirm: null})}>
            Cancelar
          </Button>
          <Button variant="contained" onClick={() => onConfirm()}>
            Confirmar
          </Button>
        </Box>
      </Container>
    </Box>
  )
}