import React, { useEffect, useState } from "react";
import { Container, Grid, Button, Switch, MenuItem, Table, TableBody, TableContainer, TableHead, TableRow, Paper, Tooltip, IconButton } from "@mui/material";

import AddCircleIcon from '@mui/icons-material/AddCircle';

import { FormControlBase, InputLabelSelect, SelectBase, TextFieldBase } from "../../Pedidos/styles";
import { StyledTableCell } from '../../Pedidos/components/acesso/acessoIndex'

import api from "../../../Api/apiAxios";

export function AdicionarUsuario({ distribuidorId, statusRevenda }) {
  const [usuarios, setUsuarios] = useState([]);
  const [novoUsuario, setNovoUsuario] = useState(null);
  const nivelUsuario = window.localStorage.getItem('nivelUsuario');

  const handleAddUsuario = () => {
    setNovoUsuario({
      distribuidorId: distribuidorId || '',
      nome: '',
      email: '',
      celular: '',
      senha: 'portal123',
      tipo: null,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNovoUsuario({
      ...novoUsuario,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    try {
      let regUsuario = {
        Id: 0,
        DistribuidorId: Number(distribuidorId),
        Nome: novoUsuario.nome,
        Email: novoUsuario.email,
        Senha: novoUsuario.senha,
        Telefone: novoUsuario.celular,
        Celular: novoUsuario.celular, 
        Status: 1,
        TipoUsuario: Number(novoUsuario.tipo),
        DataHoraCadastro: new Date(),
        DataHoraUltimoAcesso: new Date(),
    }      
   
    const ret = await api.post("Usuario/", JSON.stringify(regUsuario), { headers: { 'Content-Type': 'application/json'}});

    console.log(ret.data)
    setUsuarios([...usuarios, novoUsuario]);
    setNovoUsuario(null);
    loadUsuarios();
    } catch (error) {
      console.error("Erro ao adicionar usuário:", error);
    }
  };

  async function loadUsuarios() {
    try {
      const resp = await api.get(`/Usuario/retornar-por-distribuidor?idDistribuidor=${distribuidorId}`);
      setUsuarios(resp.data);
    } catch (error) {
      console.error("Erro ao carregar usuários:", error);
    }
  }

  async function handleChangeStatus(usuario) {
    let regUsuario = {
        Id: Number(usuario.id),
        DistribuidorId: usuario.distribuidorId,
        Nome: usuario.nome,
        Email: usuario.email,
        Senha: usuario.senha,
        Telefone: usuario.telefone, 
        Celular: usuario.celular, 
        Status: usuario.status === 0 ? 1 : 0,
        TipoUsuario: Number(usuario.tipoUsuario),
        DataHoraCadastro: new Date(usuario.dataHoraCadastro),
        DataHoraUltimoAcesso: new Date(usuario.dataHoraUltimoAcesso),
    }

    const ret = await api.put("Usuario/", JSON.stringify(regUsuario), { headers: { 'Content-Type': 'application/json'}, withCredentials: false })

    if  (!ret.data.match(/Sucesso/)){
      console.log('Erro ao alterar status do usuário: ', usuario.nome)
    }

    loadUsuarios();
  }

  useEffect(() => {
    loadUsuarios();
  }, [distribuidorId]);

  return (
    <Container sx={{ my: 2 }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Nome</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Celular</StyledTableCell>
              <StyledTableCell>Ativo</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usuarios.map((usuario, index) => (
              <TableRow key={index}>
                <StyledTableCell>{usuario.nome}</StyledTableCell>
                <StyledTableCell>{usuario.email}</StyledTableCell>
                <StyledTableCell>{usuario.celular}</StyledTableCell>
                <StyledTableCell>
                  <Switch checked={usuario.status === 1} onChange={() => handleChangeStatus(usuario)} />
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      {(statusRevenda && (nivelUsuario === '1' || nivelUsuario === '3')) &&
        <Button onClick={handleAddUsuario} variant="text" sx={{ display: 'flex', alignItems: 'center' }}>
          <AddCircleIcon sx={{ marginRight: '0.5rem' }} />
          Novo Contato
        </Button>
      }
      {novoUsuario && (
        <Container sx={{ my: 3 }}>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <TextFieldBase
                label="Nome"
                name="nome"
                value={novoUsuario.nome}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item md={3}>
              <TextFieldBase
                label="Email"
                name="email"
                type="email"
                value={novoUsuario.email}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item md={3}>
              <TextFieldBase
                label="Celular"
                name="celular"
                value={novoUsuario.celular}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item md={3}>
              <TextFieldBase
                label="Senha"
                name="senha"
                value={novoUsuario.senha}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item md={3}>
              {nivelUsuario === '1' ? (
                <FormControlBase>
                  <InputLabelSelect>Tipo de Usuário</InputLabelSelect>
                  <SelectBase
                    name="tipo"
                    value={novoUsuario.tipo || ''}
                    onChange={(e) => setNovoUsuario({...novoUsuario, tipo: parseInt(e.target.value, 10)})}
                    fullWidth
                  >
                    <MenuItem value={1}>Admin - BMA</MenuItem>
                    <MenuItem value={2}>Consulta - BMA</MenuItem>
                    <MenuItem value={3}>Admin - Revenda</MenuItem>
                    <MenuItem value={4}>Consulta - Revenda</MenuItem>
                  </SelectBase>
                </FormControlBase>
              ) : (
                <FormControlBase>
                  <InputLabelSelect>Tipo de Usuário</InputLabelSelect>
                  <SelectBase
                    name="tipo"
                    value={novoUsuario.tipo || ''}
                    onChange={(e) => setNovoUsuario({...novoUsuario, tipo: parseInt(e.target.value, 10)})}
                    fullWidth
                  >
                    <MenuItem value={3}>Admin - Revenda</MenuItem>
                    <MenuItem value={4}>Consulta - Revenda</MenuItem>
                  </SelectBase>
                </FormControlBase>
              )
              }
              </Grid>
            <Grid item xs={12} sx={{display: 'flex', justifyContent: 'end'}}>
              <Button variant="contained" color="primary" onClick={handleSubmit}>
                Confirmar
              </Button>
              <Button variant="outlined" color="secondary" onClick={() => setNovoUsuario(null)} sx={{ ml: 2 }}>
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Container>
      )}
    </Container>
  );
}
