import React, { useState } from "react";
import { subDays, format, startOfDay, isAfter } from 'date-fns';

import api from "../Api/apiAxios";

export const DashboardAlternadoContext = React.createContext();

export const DashboardData = ({children}) => {
  const [contratos30, setContratos30] = useState(null);
  const [contratos60, setContratos60] = useState(null);
  const [contratos90, setContratos90] = useState(null);

  const initContratos = async () => {
    const dataAtual = new Date();

    try {
      let set30 = await buscaPorPeriodo(30, dataAtual);
      let set60 = await buscaPorPeriodo(60, dataAtual);
      let set90 = await buscaPorPeriodo(90, dataAtual);

      setContratos30(set30);
      setContratos60(set60);
      setContratos90(set90);
    } catch (e) {
      console.log(e);
    }
  }

  const buscaPorPeriodo = async (range, dataRef) => {
    const dataFinal = format(startOfDay(dataRef), 'yyyy-MM-dd');
    const dataInicial = format(subDays(startOfDay(dataRef), range - 1), 'yyyy-MM-dd');
  
    try {
      const resp = await api.get(`/Contrato/retornar-contratos-cancelados?id=${9999}&periodoInicial=${dataInicial}&periodoFinal=${dataFinal}`);

      const canceladosExpandiveis = await setExpandable(resp.data);
      const quantidadeTotalCancelados = resp.data.reduce((total, item) => total + (item.totalCancelamentosAcesso + item.totalCancelamentosFolha + item.totalCancelamentosPonto), 0);

      const resp2 = await api.get(`/Contrato/parametros-consulta?nivel=${1}&tipo=ativos&args=status`);  

      const contratosAtivosPeriodo = Object.values(resp2.data).filter(obj => {
        const dataAtivacao = new Date(obj.dataAtivacao);
        return isAfter(dataAtivacao, dataInicial);
      });

      const novosExpandiveis = GroupedByDistributor(contratosAtivosPeriodo);
      const quantidadeTotalNovos = novosExpandiveis.reduce((total, item) => total + item.totalItems, 0);

      switch (range) {
        case 30:
          setContratos30({cancelados: resp.data, quantidade_cancelados: quantidadeTotalCancelados, novos: novosExpandiveis, quantidade_novos: quantidadeTotalNovos})
          break;
        case 60:
          setContratos60({cancelados: resp.data, quantidade_cancelados: quantidadeTotalCancelados, novos: novosExpandiveis, quantidade_novos: quantidadeTotalNovos}) 
          break;
        case 90:
          setContratos90({cancelados: resp.data, quantidade_cancelados: quantidadeTotalCancelados, novos: novosExpandiveis, quantidade_novos: quantidadeTotalNovos})
          break;
        default:
          break;
      }

      return {cancelados: canceladosExpandiveis, quantidade_cancelados: quantidadeTotalCancelados, novos: novosExpandiveis, quantidade_novos: quantidadeTotalNovos};
    } catch (e) {
      console.log(e);
    }
  }

  const setExpandable = (contratosCancelados) => {
    let contratosExpandiveis = contratosCancelados.map(reg => {
       if ((reg.totalCancelamentosAcesso + reg.totalCancelamentosFolha + reg.totalCancelamentosPonto) > 0) {
           reg = {...reg, isExpandable: true}
       } else {
           reg = {...reg, isExpandable: false}
       }
       return reg;
     })
 
     return contratosExpandiveis;
   }

  const GroupedByDistributor = (novosContratos) => {
    const groupedData = novosContratos.reduce((acc, item) => {
      const distributorId = item.distribuidor.id;
      if (!acc[distributorId]) {
        acc[distributorId] = {
          distributor: item.distribuidor.razaoSocial,
          totalItems: 0,
          items: [],
          isExpandable: false,
        };
      }
      acc[distributorId].totalItems += 1;
      acc[distributorId].items.push({
        dataAtivacao: item.dataAtivacao,
        sistema: item.sistema,
        razaoSocialCliente: item.cliente.razaoSocial,
        numeroSerie: item.numeroSerie,
        tipoContrato: item.tipoContrato,
        valor: item.valor,
      });
      return acc;
    }, {});

    const groupedArray = Object.values(groupedData);

    groupedArray.map(item => {
      return item.isExpandable = item.totalItems > 0;
    });

    return groupedArray;
  }

  return (
    <DashboardAlternadoContext.Provider 
      value={{ 
        buscaPorPeriodo,
        contratos30,
        setContratos30,
        contratos60,
        setContratos60,
        contratos90,
        setContratos90,
        initContratos,
        GroupedByDistributor,
        setExpandable
      }}
    >
      {children}
    </DashboardAlternadoContext.Provider>
  )
}