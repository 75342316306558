import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Grid } from "@mui/material";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from '../../Api/apiAxios';
import Mensagem from '../../Components/Mensagem';
import { ButtonBase, FormSectionTitle, TextFieldBase } from '../Pedidos/styles';

export function AlterarSenha() {
  const [user, setUser] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [tipoMensagem, setTipoMensagem] = useState('');
  const [showMensagem, setShowMensagem] = useState(false);

  const navigate = useNavigate();

  async function getUser() {
    const userId = window.localStorage.getItem('idUsuario');

    try {
      const response = await api.get(`/Usuario/${userId}`);
      setUser(response.data);
    } catch (e) {
      if (e.response.status !== 401) {
        console.log(e);
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      senhaAtual: '',
      novaSenha: '',
      confirmarNovaSenha: ''
    },
    validationSchema: Yup.object({
      senhaAtual: Yup.string()
        .required('Senha atual é obrigatória')
        .oneOf([user.senha], 'Senha atual incorreta'),
      novaSenha: Yup.string()
        .required('Nova senha é obrigatória'),
      confirmarNovaSenha: Yup.string()
        .oneOf([Yup.ref('novaSenha'), null], 'As senhas não coincidem')
        .required('Confirmação da nova senha é obrigatória')
    }),
    onSubmit: async (values, { resetForm }) => {
      setShowMensagem(false);

      console.log('Senha atualizada com sucesso', values, user, formik.values);

      let regUsuario = {
        Id: Number(user.id),
        DistribuidorId: user.distribuidorId,
        Nome: user.nome,
        Email: user.email,
        Senha: formik.values.novaSenha,
        Telefone: user.telefone, 
        Celular: user.celular, 
        Status: user.status,
        TipoUsuario: Number(user.tipoUsuario),
        DataHoraCadastro: user.dataHoraCadastro,
        DataHoraUltimoAcesso: user.DataHoraUltimoAcesso,
      };

      const ret = await api.put("Usuario/", JSON.stringify(regUsuario), { headers: { 'Content-Type': 'application/json'}, withCredentials: false });

      if (ret.data.match(/Sucesso/)) {
        setMensagem("Senha atualizada com sucesso!");
        setTipoMensagem("success");
      } else {
        setMensagem("Ops, algo deu errado! Atualize a página e tente novamente...");
        setTipoMensagem("danger");
      }
      resetForm();
      getUser();
      setShowMensagem(true);
    }
  });

  useEffect(() => {
    getUser();
  }, []);

  return (
    <Container sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {showMensagem && <Mensagem mensagem={mensagem} setMensagem={setMensagem} variant={tipoMensagem} />}
      <Box sx={{
        backgroundColor: 'white',
        py: 3,
        px: 2,
        margin: '10px auto',
        borderRadius: '30px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        width: '50%',
        position: 'relative',
        top: 100,
        maxHeight: '80vh',
        boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.25)',
      }}>
        <FormSectionTitle label="Alterar Senha" />
        <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextFieldBase
                fullWidth
                margin="normal"
                label="Senha Atual"
                type="password"
                name="senhaAtual"
                value={formik.values.senhaAtual}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.senhaAtual && Boolean(formik.errors.senhaAtual)}
                helperText={formik.touched.senhaAtual && formik.errors.senhaAtual}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldBase
                fullWidth
                margin="normal"
                label="Nova Senha"
                type="password"
                name="novaSenha"
                value={formik.values.novaSenha}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.novaSenha && Boolean(formik.errors.novaSenha)}
                helperText={formik.touched.novaSenha && formik.errors.novaSenha}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldBase
                fullWidth
                margin="normal"
                label="Confirmar Nova Senha"
                type="password"
                name="confirmarNovaSenha"
                value={formik.values.confirmarNovaSenha}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.confirmarNovaSenha && Boolean(formik.errors.confirmarNovaSenha)}
                helperText={formik.touched.confirmarNovaSenha && formik.errors.confirmarNovaSenha}
              />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={3}>
              <ButtonBase
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginTop: '20px' }}
              >
                Confirmar
              </ButtonBase>
            </Grid>
            <Grid item xs={3}>
              <ButtonBase
                variant="outlined"
                color="primary"
                onClick={() => navigate(-1)}
                fullWidth
                style={{ marginTop: '20px' }}
              >
                Voltar
              </ButtonBase>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
}
