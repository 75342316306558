import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FolderPlus } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { VictoryPie } from "victory";

import Loading from "../../../../Components/Loading";
import styles from "../../DashboardAlternado.module.css";
import { DashboardAlternadoContext } from "../../../../Context/DashboardAlternadoContext";

export const ContratosCancelados60Qtd = () => {
  const [dadosGrafico, setDadosGrafico] = useState(null);
  const { buscaPorPeriodo, contratos60, setContratos60 } = React.useContext(DashboardAlternadoContext);
  
  const navigate = useNavigate();
  const dataAtual = new Date();
  
  useEffect(() => {
    async function fetchData() {
      try {
        let contratos = await contratos60;

        if (contratos === null) {
          contratos = await buscaPorPeriodo(60, dataAtual);
          setContratos60(contratos);
        }
        setDadosGrafico([{x: "Contratos Cancelados", y: contratos.quantidade_cancelados}, {x: "Contratos Novos", y: contratos.quantidade_novos} ])
      } catch (e) {
        console.log(e);
      }
    }

    fetchData();
  }, [contratos60])

  if (dadosGrafico === null) return <Loading />;

  return (
    <>
      <h1 className={styles.chartHeader}>
        Contratos: 60 dias 

        <Button onClick={() => navigate(`/dashboard2details/${60}`)} className={styles.btn}>
          <FolderPlus size={24} />
        </Button>
      </h1>
      
      <VictoryPie
        colorScale={["#8e3343", "#94aa2a"]}
        labels={({ datum }) => `${datum.y.toFixed(0)}`}
        labelRadius={({ innerRadius }) => innerRadius + (window.innerHeight > 740 ? 170 : 100) }
        style={{ labels: { fill: "white",  fontSize: 24, fontWeight: "bold" }}}
        data={dadosGrafico}
        width={window.innerHeight > 740 ? 700 : 500}
        height={window.innerHeight > 740 ? 700 : 500}
      />

      <div className={styles.captionContainer} style={{fontWeight: 'bold'}}>
        <label className={styles.captionLabel}>
          <span className={styles.captionColorSquare} style={{backgroundColor: '#8e3343'}} />
          Contratos Cancelados
        </label>

        <label className={styles.captionLabel}>
          <span className={styles.captionColorSquare} style={{backgroundColor: '#94aa2a'}} />
          Contratos Novos
        </label>
      </div>

      <div className={styles.captionContainer}>
        <label className={styles.captionLabel}>
          Quantidade de contratos
        </label>
      </div>
    </>
  )
}