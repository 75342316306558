import { Box, Container, Divider, Grid, MenuItem, Paper, Typography } from "@mui/material";
import { ButtonBase, FormControlBase, FormSectionBox, FormSectionTitle, InputLabelSelect, SelectBase, TextFieldBase } from "../../../styles";
import {  useFormik } from "formik";
import * as Yup from 'yup';
import { useContext, useEffect } from "react";
import { format, subDays } from "date-fns";
import { MyContext } from "../../../../../Context/MyContext";
import { PedidosContext } from "../../../../../Context/PedidosContext";
import api from "../../../../../Api/apiAxios";


export function ModalCancelamento({ contrato }) {
  const { setMensagemGlobal, setTipoMensagemGlobal } = useContext(MyContext);
  const { 
    productVariant, 
    motivosCancelamento, 
    fetchMotivosCancelamento,
    setModalCancelamentoContrato
  } = useContext(PedidosContext);

  const idUsuario = window.localStorage.getItem('idUsuario');

  const handleCancel = async (e) => {
    console.log('handleCancel')
    e.preventDefault();

    let regContrato = {
      Id: Number(contrato.id),
      PedidoId: Number(contrato.pedidoId),                        
      StatusContrato: 0,
      DataAtivacao: contrato.dataAtivacao,
      DataAtualizacao: contrato.dataAtualizacao,
      DataCancelamento: formik.values.dataCancelamento,
      ValorFixo: 1,
      Valor: Number(contrato.valor),
      Sistema: contrato.sistema,
      IdUsuarioCancelamento: Number(idUsuario),
      ClienteId: Number(contrato.clienteId),
      NumeroSerie: Number(contrato.numeroSerie),
      TipoContrato: Number(contrato.tipoContrato),
      CancelamentoComercial: 1,
      MotivoCancelamentoOpcao: formik.values.motivoCancelamentoOpcao,
      MotivoCancelamento: formik.values.motivoCancelamento,
    }                
      
    const ret = await api.put(`/Contrato/cancelar-contrato?id=${contrato.id}&motivo=${formik.values.motivoCancelamento}&motivoCancelamentoOpcao=${formik.values.motivoCancelamentoOpcao}&dataCancelamento=${formik.values.dataCancelamento}`)
  
    console.log(ret.data)
    setMensagemGlobal(ret.data);
    setTipoMensagemGlobal("success");
    setModalCancelamentoContrato({show: false, contrato: {}})  
  }

  const validationSchema = Yup.object({
    tipoSistema: Yup.string().nullable(),
    distribuidor: Yup.string().nullable(),
    cliente: Yup.string().nullable(),
    nomeFantasia: Yup.string().nullable(),
    dataAtivacao: Yup.date().nullable(),
    valorContrato: Yup.number().nullable(),
    numeroSerie: Yup.string().nullable(),
    idPedido: Yup.string().nullable(),
    dataCancelamento: Yup.date().required('Data de cancelamento é obrigatória.').min(subDays(new Date(), 1), 'A data de cancelamento deve ser a partir de hoje.'),
    motivoCancelamentoOpcao: Yup.number().required('Motivo do cancelamento deve ser selecionado.'),
    motivoCancelamento: Yup.string().nullable(),
  });

  const dataDeCancelamento = contrato.dataCancelamento !== null ? format(new Date(contrato.dataCancelamento), 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd')

  const formik = useFormik({
    initialValues: {
      tipoSistema: productVariant,
      distribuidor: contrato.distribuidor.razaoSocial,
      cliente: contrato.cliente.nomeFantasia,
      nomeFantasia: contrato.cliente.nomeFantasia,
      dataAtivacao: format(new Date(contrato.dataAtivacao), 'yyyy-MM-dd'),
      valorContrato: contrato.valor,
      numeroSerie: contrato.numeroSerie,
      idPedido: contrato.pedidoId,
      dataCancelamento: dataDeCancelamento,
      motivoCancelamentoOpcao: contrato.motivoCancelamentoOpcao || 0,
      motivoCancelamento: contrato.motivoCancelamento || '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log('onSubmit')
      handleCancel();
    },
  });

  useEffect(() => {
    fetchMotivosCancelamento();
  }, []);

  return (
    <Container sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <Paper sx={{ 
          p: 2, 
          width: '80%', 
          alignSelf: 'center', 
          position: 'fixed', 
          top: 80, 
          overflow: 'auto', 
          zIndex: 1000, 
          maxHeight: '80vh',
          backgroundColor: '#f8f9fa',
        }}  
        elevation={3}
      >
        <Typography variant="h5" sx={{ pb: 1, color: 'tomato', fontWeight: 'bold', width: '100%', textAlign: 'center' }}>
          Cancelar Contrato
        </Typography>

        <Divider />

        <Box
          component='form'
          onSubmit={formik.handleSubmit}
          sx={{ p: 1, mt: 1, display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <FormSectionBox>
            <FormSectionTitle label='Dados do Pedido' />
            <Grid container spacing={2} sx={{ pb: 2, pl: 4, mt: 1, display: 'flex' }}>
              <Grid item xs={3}>
                <FormControlBase>
                  <InputLabelSelect id="tipoSistema">Tipo de Sistema</InputLabelSelect>
                  <SelectBase 
                    id="tipoSistema"
                    name="tipoSistema"
                    label='Tipo de Sistema'
                    value={formik.values.tipoSistema}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.tipoSistema && Boolean(formik.errors.tipoSistema)}
                    helperText={formik.touched.tipoSistema && formik.errors.tipoSistema}
                    width='100%'
                    disabled
                  >
                    <MenuItem value='Acesso'>Acesso</MenuItem>
                    <MenuItem value='Folha'>Folha</MenuItem>
                    <MenuItem value='Ponto'>Ponto</MenuItem>
                  </SelectBase>
                </FormControlBase>
              </Grid>

              <Grid item xs={3}>
                <FormControlBase>
                  <TextFieldBase 
                    label="Data de Ativação" 
                    name="dataAtivacao"
                    value={formik.values.dataAtivacao}
                    type="date"
                    width='100%'
                    disabled
                  />
                </FormControlBase>
              </Grid>

              <Grid item xs={3}>
                <FormControlBase>
                  <TextFieldBase 
                    label="ID do Pedido"
                    name="idPedido"
                    value={formik.values.idPedido}
                    disabled
                    width='100%'
                  />
                </FormControlBase>
              </Grid>

              <Grid item xs={3}>
                <FormControlBase>
                  <TextFieldBase 
                    label="Valor do Contrato (R$)"
                    name="valorContrato"
                    value={formik.values.valorContrato} 
                    disabled
                    width='100%'
                  />
                </FormControlBase>
              </Grid>

              <Grid item xs={6}>
                <FormControlBase>
                  <TextFieldBase 
                    label="Número de Série"
                    name="numeroSerie"
                    value={formik.values.numeroSerie} 
                    disabled 
                    width='100%'
                  />
                </FormControlBase>
              </Grid>

              <Grid item xs={6}>
                <FormControlBase>
                  <InputLabelSelect id="distribuidor">Revenda</InputLabelSelect>
                  <TextFieldBase 
                    id="distribuidor" 
                    label="Revenda"
                    name="distribuidor"
                    value={formik.values.distribuidor}
                    disabled
                    width='100%'
                  />
                </FormControlBase>
              </Grid>

              <Grid item xs={6}>
                <FormControlBase>
                  <InputLabelSelect id="cliente">Cliente</InputLabelSelect>
                  <TextFieldBase 
                    id="cliente" 
                    label="Cliente"
                    name="cliente"
                    value={formik.values.cliente}
                    disabled
                    width='100%'
                  />
                </FormControlBase>
              </Grid>

              <Grid item xs={6}>
                <FormControlBase>
                  <InputLabelSelect id="nomeFantasia">Nome Fantasia</InputLabelSelect>
                  <TextFieldBase 
                    id="nomeFantasia" 
                    label="Nome Fantasia"
                    name="nomeFantasia"
                    value={formik.values.nomeFantasia}
                    disabled
                    width='100%'
                  />
                </FormControlBase>
              </Grid>             
            </Grid>
          </FormSectionBox>

          <FormSectionBox>
            <FormSectionTitle label='Dados do Cancelamento' />
            <Grid container spacing={2} sx={{ pb: 2, px: 4, mt: 1, display: 'flex' }}>
              <Grid item xs={4}>
                <FormControlBase>
                  <TextFieldBase 
                    label="Data de Cancelamento" 
                    name="dataCancelamento"
                    value={formik.values.dataCancelamento}
                    type="date"
                    onChange={formik.handleChange}
                    onBlur={formik.onBlur}
                    error={formik.touched.dataCancelamento && Boolean(formik.errors.dataCancelamento)}
                    helperText={formik.touched.dataCancelamento && formik.errors.dataCancelamento}
                    width='100%'
                  />
                </FormControlBase>
              </Grid>
              <Grid item xs={8}>
                <FormControlBase>
                  <InputLabelSelect  id="motivoCancelamentoOpcao">Motivo</InputLabelSelect>
                  <SelectBase 
                    id='motivoCancelamentoOpcao'
                    name='motivoCancelamentoOpcao'
                    value={formik.values.motivoCancelamentoOpcao}
                    onChange={formik.handleChange}
                    error={formik.touched.motivoCancelamentoOpcao && Boolean(formik.errors.motivoCancelamentoOpcao)}
                    helperText={formik.touched.motivoCancelamentoOpcao && formik.errors.motivoCancelamentoOpcao}
                    label='Motivo'
                    width='100%'
                  >
                    {motivosCancelamento.map((motivo) => (
                      <MenuItem key={motivo.id} value={motivo.id}>
                        {motivo.descricaoMotivo}
                      </MenuItem>
                    ))}
                  </SelectBase>
                </FormControlBase>
              </Grid>

              <Grid item xs={12}>
                <FormControlBase>
                  <TextFieldBase 
                    label="Detalhes"
                    name="motivoCancelamento"
                    value={formik.values.motivoCancelamento}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.motivoCancelamento && Boolean(formik.errors.motivoCancelamento)}
                    helperText={formik.touched.motivoCancelamento && formik.errors.motivoCancelamento}
                    width='100%'  
                    multiline 
                    rows={4}
                  />
                </FormControlBase>
              </Grid>
            </Grid>
          </FormSectionBox>
          
          <Divider />

          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 0.5, pt: 2 }}>
            <ButtonBase variant="outlined" onClick={() => setModalCancelamentoContrato({show: false, contrato: {}})}>
              Voltar
            </ButtonBase>

            <ButtonBase type="submit" onClick={(e) => handleCancel(e)} variant="contained">
              Cancelar
            </ButtonBase>
          </Box>
        </Box>
      </Paper>
    </Container>
  )
}
