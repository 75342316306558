import React from "react";
import api from "../../../Api/apiAxios";
import { Form, Alert, Button, Col, Row, FloatingLabel, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loading from "../../../Components/Loading";
import Mensagem from "../../../Components/Mensagem";
import styles from "./ConsultaPorTipoPlanoOuVertical.module.css";
import { FiletypePdf } from "react-bootstrap-icons";

const ConsultaPorTipoPlanoOuVertical = () => {
    const navigate = useNavigate();

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');  
    const idDistribuidor = window.localStorage.getItem('distribuidorId');   

    const [loading, setLoading] = React.useState(false);
    const [mensagem, setMensagem] = React.useState(null);
    const [tipoMensagem, setTipoMensagem] = React.useState('');
    const [opcao, setOpcao] = React.useState('');
    const [tipoPlano, setTipoPlano] = React.useState('');
    const [tipoVertical, setTipoVertical] = React.useState('');
    const [distribuidorSelecionado, setDistribuidorSelecionado] = React.useState('');
    const [distribuidores, setDistribuidores] = React.useState([]);
    const [licencas, setLicencas] = React.useState([]);
    const [totalLicencas, setTotalLicencas] = React.useState(0);
    const [tipoSelecionado, setTipoSelecionado] = React.useState('');

    React.useEffect(() => {
 
        async  function pesquisarDistribuidores() { 
            setLoading(true);                  

            if  (nivelUsuario !== "1") {
                 const resp = await api.get(`/Distribuidor/${idDistribuidor}`);  
                 const novoArray = []; 
                 novoArray.unshift(resp.data);
                 setDistribuidores(novoArray);            
            } else {
                   const response = await api.get('/Distribuidor');
                   const registrosFiltrados = response.data.filter((distribuidor) => 
                         distribuidor.statusEmpresa === 1);          
                   registrosFiltrados.sort((a,b) => a.razaoSocial.localeCompare(b.razaoSocial));

/*                    var todos = {
                    id: 9999,
                    razaoSocial: '*** TODOS ***',
                   }
                   registrosFiltrados.push(todos);
 */                   
                   setDistribuidores(registrosFiltrados);  

            }    
                   
            setLoading(false);       
     }

     pesquisarDistribuidores();
    }, [])

    function getValueDistribuidor(ev) {
        const idSelected = ev.target.children[ev.target.selectedIndex].getAttribute('id');
        setDistribuidorSelecionado(idSelected);   
    };  

    function getValueOpcao(value) {
        setOpcao(value);
        if  (value === "ponto") {
            setTipoVertical('');
        }
        if  (value === "acesso") {
            setTipoPlano('');
        }     
    }

    function getValueVertical(value) {
        setTipoVertical(value);     
    }
    
    async function handleSubmit(ev) {
        ev.preventDefault();

        if  (distribuidorSelecionado.length === 0) {
            alert("Faltou Selecionar a Revenda");
            return;
        }
        if  (opcao.length === 0) {
            alert("Faltou Selecionar a Opção do Sistema...");
            return;
        }
        if  (opcao === "ponto" && tipoPlano.length === 0) {
            alert("Faltou Selecionar o Tipo de Plano...");
            return;
        }
        if  (opcao === "acesso" && tipoVertical.length === 0) {
            alert("Faltou Selecionar o Tipo de Vertical...");
            return;
        }

        try {
            setLoading(true);        

            var tipo = null;
            if  (opcao === "ponto") {
                tipo = tipoPlano;        
            }
            else {
                tipo = tipoVertical;
            }             

            const response = 
                  await api.get(`/Totais/licencas-por-plano-vertical?id=${distribuidorSelecionado}&sistema=${opcao}&tipo=${tipo}`); 
            const registrosFiltrados = response.data.sort((a,b) => a.razaoSocialCliente.localeCompare(b.razaoSocialCliente));
            setLicencas(registrosFiltrados);
            setTotalLicencas(response.data.length);

        }
        catch (error) {
              alert("Não foi possível gerar o relatório.");
        } finally {
            setLoading(false);
        }
    }

    async function gerarPdf() {   
        var tipo = null;
        if  (opcao === "ponto") {
            tipo = tipoPlano;        
        }
        else {
            tipo = tipoVertical;
        }

        const response = 
              await api.get(`/Totais/pdf-por-plano-vertical?id=${distribuidorSelecionado}&sistema=${opcao}&tipo=${tipo}`,
                    { responseType: 'blob' }
              ); 
        var pdfBlob = new Blob([response.data], { type: 'application/pdf'} );
        var url = window.URL.createObjectURL(pdfBlob)
        window.open(url);
      
    }    

    if  (loading) return <Loading />

    return (
        <section className={styles.form}>

            <Alert style={{background: 'ghostwhite', color: 'black'}}>
                <Alert.Heading style={{textAlign: 'center', fontSize: 'x-large'}}>Consulta Licenças por Tipo Plano ou Tipo Vertical</Alert.Heading>        

            <br />

            <Row>
                <Col md='1'>
                </Col>

                <Col md='10'>
                    <FloatingLabel controlid="floatingInputGrid" label="Selecione a Revenda">
                        <Form.Select onChange={getValueDistribuidor} value={distribuidorSelecionado} className=" form-select-control">
                            <option></option>                               
                            {distribuidores.map((distribuidor) => (
                                <option key={distribuidor.id} 
                                        id={distribuidor.id}
                                        value={distribuidor.id}
                                        selected={distribuidor.id === distribuidorSelecionado}>{distribuidor.razaoSocial}</option>
                            ))}
                        </Form.Select>
                    </FloatingLabel>    
                </Col>
            </Row>
            <br />

            <Row className="g-2" style={{marginBottom: '1rem'}}>

                <Col md='1'>
                </Col>

                <Col md='2'>
                    <FloatingLabel controlid="floatingInputGrid7" label="Selecione o Sistema">
                        <Form.Select value={opcao} onChange={({target}) => getValueOpcao(target.value)}>
                            <option value="" disabled></option>
                            <option value="ponto">BmaPonto</option>
                            <option value="acesso">BmaAcesso</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>

                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid7" label="Selecione o Tipo de Plano">
                        <Form.Select value={tipoPlano} onChange={({target}) => setTipoPlano(target.value)}
                                     disabled={opcao === "acesso"}>
                            <option value="" disabled></option>
                            <option value="0">Licenciado</option>
                            <option value="1">SaaS (Rep-C)</option>
                            <option value="2">SaaS (Rep-P)</option>
                            <option value="9">*** TODOS ***</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>

                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid7" label="Selecione a Vertical">
                        <Form.Select value={tipoVertical} onChange={({target}) => getValueVertical(target.value)}
                                     disabled={opcao === "ponto"}>
                            <option value="" disabled></option>
                            <option value="1">Empresa</option>
                            <option value="2">Refeitório</option>
                            <option value="3">Academia</option>
                            <option value="4">Condomínio</option>
                            <option value="5">Hospital</option>
                            <option value="6">Escola</option>
                            <option value="9">*** TODOS ***</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>
            </Row>                    

            </Alert>

            <div className={styles.areaBtn}>    
               <Button onClick={handleSubmit} variant="outline-primary" className={styles.btn} 
                        disabled={nivelUsuario === "2" || nivelUsuario === "4"}>Gerar
               </Button>               
               <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Voltar</Button>
            </div>    

            {mensagem && <Mensagem mensagem={mensagem} setMensagem={setMensagem} variant={tipoMensagem} /> }                                 

            {(licencas.length > 0) && 
                <>
                    <Alert style={{background: 'ghostwhite', color: 'black'}}>
                        <Alert.Heading style={{textAlign: 'center', fontSize: 'xx-large', fontStyle: 'italic', color: 'dodgerblue'}}>Total Licenças: {totalLicencas}
                            <Button onClick={gerarPdf} variant="outline-primary" className={styles.btn2}> 
                                <FiletypePdf size={25}></FiletypePdf> 
                            </Button>
                        </Alert.Heading>        
                    </Alert>    
                  
                    <br />
                    <Table id="tblClientes" hover responsive bordered>
                            <thead style={{backgroundColor: 'dodgerblue', color: 'whitesmoke'}}>
                                <tr className={styles.trHead}>
                                    <th className={styles.th}>Cliente</th>
                                    <th className={styles.th}>NS</th>                          
                                    <th className={styles.th}>Versao</th>                          
                                    <th className={styles.th}>Plano/Vertical</th>                          
                                    <th className={styles.th}>NR.Equip.</th>                          
                                </tr>
                            </thead>                            
                            <tbody>
                                {licencas.map(licenca =>
                                    <tr key={licenca.id}>                                     
                                        <td className={styles.td1}>{licenca.razaoSocialCliente}</td>          
                                        <td className={styles.td2}>{licenca.numeroSerie}</td>
                                        <td className={styles.td2}>{licenca.versao}</td>
                                        <td className={styles.td2}>{licenca.tipoPlanoVertical}</td>
                                        {opcao === "ponto" ?
                                            <td className={styles.td2}>* * *</td>
                                        :
                                            <td className={styles.td2}>{licenca.quantidadeEquipamentos+1}</td>
                                        }
                                    </tr>
                                )}
                            </tbody>                                                
                    </Table>
                </>
            }
        </section>   
    )
}

export default ConsultaPorTipoPlanoOuVertical;