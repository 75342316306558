import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    Button,
    Grid,
    Typography,
    Box,
    Paper,
    Container,
    TableContainer,
    TableHead,
    TableBody,
    Tooltip,
    Table,
} from '@mui/material';

import DownloadIcon from '@mui/icons-material/Download';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';

import { ButtonBase, TextFieldBase, FormSectionBox, FormSectionTitle } from '../Pedidos/styles';
import { StyledTableCell, StyledTableRow } from '../Pedidos/components/acesso/acessoIndex';
import { BaseActionButton } from '../Pedidos/components/actionButtons';
import { MyContext } from '../../Context/MyContext';

import api from '../../Api/apiAxios';

const validationSchema = Yup.object({
  nomeArquivo: Yup.string().required('Nome do arquivo é obrigatório'),
  caminhoArquivo: Yup.string().required('Necessário fazer upload do arquivo'),
  tipoArquivo: Yup.string().required('Tipo do arquivo é obrigatório'),
  dataUpload: Yup.date().required('Data do upload é obrigatória'),
  tamanhoArquivo: Yup.number().required('Tamanho do arquivo é obrigatório').positive('O tamanho deve ser maior que zero'),
  usuarioId: Yup.number().nullable(),
  contratoId: Yup.number().nullable(),
  pedidoId: Yup.number().nullable(),
});

export const ArquivoForm = ({ distribuidorId }) => {
  const [formVisible, setFormVisible] = useState(false);
  const [arquivos, setArquivos] = useState([]);

  const idUsuario = window.localStorage.getItem('idUsuario');
  const nivelUsuario = window.localStorage.getItem('nivelUsuario');

  const { setMensagemGlobal, setTipoMensagemGlobal, setShowMensagemGlobal } = useContext(MyContext);

  const formik = useFormik({
      initialValues: {
          id: 0,
          nomeArquivo: '',
          caminhoArquivo: '',
          tipoArquivo: '',
          dataUpload: new Date(),
          tamanhoArquivo: 0,
          usuarioId: idUsuario,
          contratoId: null,
          pedidoId: null,
          distribuidorId,
      },
      validationSchema,
  });

  const handleToggleForm = () => {
    setFormVisible(!formVisible);
    formik.resetForm();
  }

  const handleSave = async () => {
    try {
      setShowMensagemGlobal(false);

      const formData = new FormData();
          
      const fileInput = document.querySelector('input[type="file"]');
      if (fileInput && fileInput.files.length > 0) {
        const file = fileInput.files[0];
        formData.append('arquivoUpload', file);
      } else {
        console.error('Nenhum arquivo selecionado.');
        return;
      }
  
      let url = 'Arquivo';
      const params = [];
      
      if (formik.values.usuarioId) params.push(`nomeArquivo=${formik.values.nomeArquivo}`);
      if (formik.values.usuarioId) params.push(`usuarioId=${formik.values.usuarioId}`);
      if (formik.values.contratoId) params.push(`contratoId=${formik.values.contratoId}`);
      if (formik.values.pedidoId) params.push(`pedidoId=${formik.values.pedidoId}`);
      if (formik.values.distribuidorId) params.push(`distribuidorId=${formik.values.distribuidorId}`);

      if (params.length > 0) {
        url += `?${params.join('&')}`;
      }

      await api.post(url, formData, { file: true });

      handleToggleForm();
      console.log('Arquivo enviado com sucesso');
      await fetchArquivos();
    } catch (error) {
      console.error('Erro ao enviar o arquivo:', error);

      if (error.response.status === 409) {
        setTipoMensagemGlobal('danger');
        setMensagemGlobal(error.response.data);
        setShowMensagemGlobal(true);
      }
    }
  }; 

  const handleFileChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        formik.setFieldValue('caminhoArquivo', file.name);
      }
  };

  const handleDelete = async (id) => {
    try {
      await api.delete(`Arquivo/${id}`);
      await fetchArquivos();
    } catch (error) {
      console.error('Erro ao excluir o arquivo:', error);
    }
  };

  const handleDownload = async (arquivoId, nomeArquivo) => {
    try {
      const response = await api.get(`Arquivo/download?arquivoId=${arquivoId}`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', nomeArquivo); 
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Erro ao baixar o arquivo:", error);
    }
  };

  const fetchArquivos = async () => {
    try {
      const response = await api.get(`Arquivo/distribuidor?distribuidorId=${distribuidorId}`);
      setArquivos(response.data);
    } catch (error) {
      console.error('Erro ao buscar arquivos:', error);
    }
  };

  useEffect(() => {
    async function loadArquivos() {
      await fetchArquivos();
    }

    loadArquivos();
  }, []);

  return (
    <Container sx={{ width: '100%', justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ width: '100%' }}>
        <FormSectionBox>
          <FormSectionTitle label='Arquivos Anexados' />

          <TableContainer component={Paper} sx={{ width: '90%', m: '20px auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Table>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Nome do Arquivo</StyledTableCell>
                  <StyledTableCell>Data de Upload</StyledTableCell>
                  <StyledTableCell>Ações</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {arquivos.map((arquivo) => (
                  <StyledTableRow key={arquivo.id}>
                    <StyledTableCell>{arquivo.nomeArquivo}</StyledTableCell>
                    <StyledTableCell>{new Date(arquivo.dataUpload).toLocaleDateString()}</StyledTableCell>
                    <StyledTableCell>
                      <Tooltip title='Baixar'>
                        <BaseActionButton color='primary' onClick={() => handleDownload(arquivo.id, arquivo.nomeArquivo)}>
                          <DownloadIcon />
                        </BaseActionButton>
                      </Tooltip>
                      <Tooltip title='Excluir'>
                        <BaseActionButton color='error' onClick={() => handleDelete(arquivo.id)} disabled={nivelUsuario !== '1'}>
                          <DeleteIcon />
                        </BaseActionButton>
                      </Tooltip>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <ButtonBase
            variant="contained"
            color="primary"
            sx={{ m: '10px auto' }}
            onClick={handleToggleForm}
            startIcon={<AddCircleIcon />}
          >
            Adicionar Arquivo
          </ButtonBase>
          {formVisible && (
            <Paper sx={{ width: '70%', m: '10px auto', p: 2 }} elevation={3}>
              <form onSubmit={formik.handleSubmit}>
                  <Grid container spacing={2}>
                      <Grid item xs={6}>
                          <TextFieldBase
                              name="nomeArquivo"
                              label="Nome do Arquivo"
                              fullWidth
                              variant="outlined"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.nomeArquivo}
                              error={formik.touched.nomeArquivo && Boolean(formik.errors.nomeArquivo)}
                              helperText={formik.touched.nomeArquivo && formik.errors.nomeArquivo}
                          />
                      </Grid>
                      <Grid item xs={6} sx={{ alignItems: 'start', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                          <Button
                              component="label"
                              variant="outlined"
                              color="primary"
                              startIcon={<CloudUploadIcon />}
                          >
                              Upload do Arquivo
                              <input
                                  type="file"
                                  hidden
                                  onChange={handleFileChange}
                              />
                          </Button>
                          {formik.touched.caminhoArquivo && formik.errors.caminhoArquivo && (
                              <Typography color="error" variant="body2">
                                  {formik.errors.caminhoArquivo}
                              </Typography>
                          )}
                          <Typography variant="body2" mt={1}>
                              {formik.values.caminhoArquivo}
                          </Typography>
                      </Grid>
                      {/* <Grid item xs={6}>
                          <TextFieldBase
                              name="contratoId"
                              label="Contrato ID"
                              fullWidth
                              variant="outlined"
                              type="number"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.contratoId}
                          />
                      </Grid>
                      <Grid item xs={6}>
                          <TextFieldBase
                              name="pedidoId"
                              label="Pedido ID"
                              fullWidth
                              variant="outlined"
                              type="number"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.pedidoId}
                          />
                      </Grid> */}
                  </Grid>
                  <Box mt={2} sx={{ display: 'flex', justifyContent: 'end', gap: 1}}>
                      <ButtonBase type="submit" variant="contained" color="primary" onClick={handleSave}>
                          Confirmar
                      </ButtonBase>
                      <ButtonBase variant="outlined" color="primary" onClick={handleToggleForm}>
                          Cancelar
                      </ButtonBase>
                  </Box>
              </form>
            </Paper>
          )}
        </FormSectionBox>
      </Box>
    </Container>
  );
};
