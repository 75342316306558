import React from "react";
import api from "../../../Api/apiAxios";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Col, FloatingLabel, ListGroup, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Loading from "../../../Components/Loading";
import styles from "./ViewPersonalizacaoFolha.module.css";

const ViewPersonalizacaoFolha = () => {
    const { id } = useParams();

    const navigate = useNavigate();   

    const [loading, setLoading] = React.useState(false);

    const [dados, setDados] = React.useState('');
    const [razaoSocialDistribuidor, setRazaoSocialDistribuidor] = React.useState('');    
    const [dataValidade, setDataValidade] = React.useState('');
    const [dataRegistro, setDataRegistro] = React.useState('');
    const [cnpjs, setCnpjs] = React.useState([]);

    React.useEffect(() => {
        (async () => {
            setLoading(true);

            try {   
                const tipo = "ID";
                const response = await api.get(`/LicencaFolha/${tipo}/${id}`);    

                setDados(response.data);  

                setRazaoSocialDistribuidor(response.data.distribuidor.razaoSocial);
                if   (response.data.dataValidade) {                    
                     var data = response.data.dataValidade.toString();
                     var ano = data.substr(0,4);
                     var mes = data.substr(5,2);
                     var dia = data.substr(8,2);                  
                     setDataValidade(dia + "/" + mes + "/" + ano);            
                }
                else setDataValidade("Cópia Vitalicia!");            
              
                if (response.data.dataRegistro !== null) {
                    const dataRegistroString = response.data.dataRegistro.substring(8,10) + "/" +
                                               response.data.dataRegistro.substring(5,7)  + "/" +   
                                               response.data.dataRegistro.substring(0,4);
                     setDataRegistro(dataRegistroString);
                }
                else setDataRegistro('* * * * * *');

                const resp = await api.get(`/ClienteCnpj/${response.data.clienteId}`);
                const registrosFiltrados = resp.data.filter((cnpjAdicional) => 
                      cnpjAdicional.status === 1);          
                setCnpjs(registrosFiltrados);
            } catch (error) {
                alert(error);
            } finally {
                setLoading(false);
            }           
        }
        )();

    }, [id]);                


    if  (loading) return <Loading />

    return (
        <section className={styles.form}>

            <Alert style={{background: 'ghostwhite', color: 'black'}}>
               <Alert.Heading style={{textAlign: 'center'}}>Licença BmaFolha</Alert.Heading>        
            <br />  

            <Row className="g-2" style={{marginBottom: '1rem'}}>
    
                <Col md='6'>
                     <FloatingLabel controlid="floatingInputGrid4" label="Revenda">
                        <Form.Control value={razaoSocialDistribuidor} disabled />
                      </FloatingLabel>      
                </Col>                

                <Col md='3'>
                     <FloatingLabel controlid="floatingInputGrid5" label="Número de Série">                               
                            <Form.Control value={dados.numeroSerie} disabled />                       
                    </FloatingLabel>      
                </Col>                

                <Col md='3'>
                     <FloatingLabel controlid="floatingInputGrid6" label="Versão">
                            <Form.Control value={dados.versao} disabled />                        
                    </FloatingLabel>      
                </Col>                
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
    
                <Col md='6'>
                    <FloatingLabel controlid="floatingInputGrid2" label="Cliente">
                        <Form.Control value={dados.razaoSocial} disabled />             
                    </FloatingLabel>                  
                </Col>

                <Col md='3'>
                     <FloatingLabel controlid="floatingInputGrid4" label="CNPJ / CPF">
                        <Form.Control value={dados.numeroInscricao} disabled />
                      </FloatingLabel>      
                </Col>                

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid9" label="Banco De Dados">
                        <Form.Select value={dados.tipoBanco} disabled>
                            <option value="1">MySql</option>
                            <option value="2">SqlServer</option>
                            <option value="3">Oracle</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid7" label="Tipo Instalação">
                        <Form.Select value={dados.tipoInstalacao} disabled>
                            <option value="0">Licenciado</option>
                            <option value="1">SaaS</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid6" label="Faixa Funcionários">
                        <Form.Control value={dados.faixaFuncionarios} disabled />
                    </FloatingLabel>      
                </Col>

                <Col md='2'>
                    <FloatingLabel controlid="floatingInputGrid6" label="Usuários">
                        <Form.Control value={dados.quantidadeUsuarios} disabled />
                    </FloatingLabel>      
                </Col>

                <Col md='2'>
                    <FloatingLabel controlid="floatingInputGrid11" label="Data Validade">
                        <Form.Control value={dataValidade} disabled />
                    </FloatingLabel>      
                </Col>

                <Col md='2'>
                    <FloatingLabel controlid="floatingInputGrid11" label="Data Registro">
                        <Form.Control value={dataRegistro} disabled />
                    </FloatingLabel>      
                </Col>
            </Row>                    


            <br /> 
            <hr />                    
            <Alert.Heading style={{textAlign: 'center'}}>Integrações & Módulos Adicionais</Alert.Heading>        
            <br /> 

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="ControleEmpresa"
                        label="Controle Empresas"                      
                        checked={dados.controleEmpresas}
                        readOnly
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="CalculoFolha"
                        label="Cálculo Folha"                      
                        checked={dados.moduloFolha}
                        readOnly
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="Rpa"
                        label="RPA"                      
                        checked={dados.moduloRpa}
                        readOnly
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="Treinamento"
                        label="Competencia/Treinamento"                      
                        checked={dados.moduloCompetenciaTreinamento}
                        readOnly
                    />
                </Col>
            </Row>                             

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="Cargos"
                        label="Cargos e Salários"                      
                        checked={dados.moduloCargosSalarios}
                        readOnly
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="SST"
                        label="SST"                      
                        checked={dados.moduloSST}
                        readOnly
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="EM"
                        label="Exames Médicos"                      
                        checked={dados.moduloExamesMedicos}
                        readOnly
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="ppp"
                        label="PPP"                      
                        checked={dados.moduloPpp}
                        readOnly
                    />
                </Col>
            </Row>                    

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="epi"
                        label="EPI"                      
                        checked={dados.moduloEpi}
                        readOnly
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="Paj"
                        label="PAJ"                      
                        checked={dados.moduloPAJ}
                        readOnly
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="integraPonto"
                        label="BmaPonto"                      
                        checked={dados.wpe}
                        readOnly
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="contrato"
                        label="Contrato"                      
                        checked={dados.temContrato}
                        readOnly
                    />
                </Col>
            </Row>                    
            <br /> 
            <hr />                    
            <Alert.Heading style={{textAlign: 'center'}}>Inscrições Adicionais</Alert.Heading>        
            <br /> 

            {cnpjs.map(cnpj => 
                <ListGroup horizontal  key={cnpj.inscricaoAdicional} className="my-2">
                    <ListGroup.Item variant="secondary" style={{width: "525px", fontWeight: "bold", fontSize: 14}}>{cnpj.razaoSocial}</ListGroup.Item>
                    <ListGroup.Item variant="secondary" style={{width: "200px", fontWeight: "bold", fontSize: 14}}>{cnpj.inscricaoAdicional}</ListGroup.Item>
                </ListGroup>                                                                                        
                    
            )}

            </Alert>

            <div className={styles.areaBtn}>    
               <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Voltar</Button>
            </div>    

        </section>
    )
}

export default ViewPersonalizacaoFolha;
