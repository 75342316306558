import React from "react";
import styles from "./Loading.module.css";

const Loading = ({ legenda = null }) => { 
  return (
    <div className={styles.container}>
      <div className={styles.spinnerContainer}>
        <div className={styles.loadingSpinner} />
      </div>
      {legenda && 
        <h2 className={styles.legendaLoad}>{legenda}</h2>
      }
    </div>
  );
}

export default Loading;