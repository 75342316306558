import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Container, ListItemText, MenuItem, MenuList, Paper } from "@mui/material";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { MyContext } from "../../../Context/MyContext";
import api from "../../../Api/apiAxios";

export function OpcoesUsuario() {
  const [user, setUser] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const nivelUsuario = window.localStorage.getItem('nivelUsuario');

  const navigate = useNavigate();

  const { logoutApp } = useContext(MyContext);

  const handleRevendaClick = () => {
    setIsMenuOpen(false)
    navigate('viewrevenda/' + user.distribuidorId)
  }

  const handleAlterarSenhaClick = () => {
    setIsMenuOpen(false)
    navigate('alterarsenha')
  }

  useEffect(() => {
    async function getUser() {
      const userId = window.localStorage.getItem('idUsuario');

      try {
        const response = await api.get(`/Usuario/${userId}`);
        setUser(response.data);
      } catch (e) {               
        if (e.response.status !== 401) {console.log(e)};
      }
    }

    getUser();
}, [])
  return (
    <Container>
      <Box>
        <Button variant='text' endIcon={<ExpandMoreIcon />} sx={{ minWidth: '10rem', maxWidth: '20rem', color: 'white'}} onClick={() => setIsMenuOpen(!isMenuOpen)}>
          Olá {user.nome}!
        </Button>
        {isMenuOpen && 
          <Paper sx={{ width: 250, position: 'absolute', zIndex: 5000 }}>
            <MenuList sx={{ color: 'rgb(23, 35, 77)' }} dense>
              {(nivelUsuario === "1" || nivelUsuario === "3") &&
                <MenuItem>
                  <ListItemText onClick={handleRevendaClick} inset>Visualizar Revenda</ListItemText>
                </MenuItem>
              }
              <MenuItem>
                <ListItemText onClick={handleAlterarSenhaClick} inset>Alterar Senha</ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText onClick={logoutApp} inset>Sair</ListItemText>
              </MenuItem>
            </MenuList>
          </Paper>
        }

      </Box>
    </Container>
    
  )
}