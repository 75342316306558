import { ShieldExclamation } from 'react-bootstrap-icons'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';

import styles from './PermissaoNegada.module.css';

export function PermissaoNegada() {
  const navigate = useNavigate();
  
  return (
    <div className={styles.container}>
      <ShieldExclamation size={50}/>
      <h1>401: Não Autorizado</h1>
      <h2>Você não tem permissão para acessar essa página. Solicite acesso ao admin.</h2>
      <Button onClick={() => navigate('/')} variant="outline-info" className={styles.btn}>Voltar para Home</Button> 
    </div> 
  )
}