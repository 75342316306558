import { Card } from "react-bootstrap";

import styles from './DashboardModal.module.css';

export function DashboardModal({children}) {
  return (
    <div className={styles.cardBg}>
      <Card className={styles.cardContainer} key={1} >
          <Card.Body>
              <Card.Title>{children}</Card.Title>
          </Card.Body>                                      
      </Card>
    </div>
  )
}