import { Box, Button, Checkbox, Chip, FormControl, FormControlLabel, InputLabel, Select, TextField } from "@mui/material";

export const tabProps = (index) => ({    
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

export const FormControlBase = ({ children, ...props }) => (
  <FormControl
    variant="outlined"
    sx={{ display: 'flex', flexDirection: 'row', ...props.sx }}
    {...props}
  >
    {children}
  </FormControl>
);

export const BoxBase = ({ children, width, ...props }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: width || '100%',
      gap: 4,
      marginBottom: 2,
      ...props.sx
    }}
    {...props}
  >
    {children}
  </Box>
);

export const ButtonBase = ({ children, sx, variant = 'outlined', ...props }) => (
  <Button
    variant={variant}
    sx={{ backgroundColor: variant === 'outlined' ? 'rgba(25, 118, 210, 0.1)' : 'rgba(25, 118, 210, 1)', ...sx }}
    {...props}
  >
    {children}
  </Button>
);

export const CustomTabPanel = ({ children, value, index, ...props }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...props}
  >
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
);

export const SelectBase = ({ isShow, width, margin, children, ...props }) => {
  return (
    <Select
      disabled={isShow}
      InputLabelProps={{ shrink: true }}
      sx={{
        backgroundColor: '#FFFF',
        width: width,
        minWidth: width,
        maxWidth: width,
        margin: margin || 0,
        maxHeight: '3.5rem',
        '& .Mui-disabled': {
          backgroundColor: 'rgb(128, 128, 128, 0.04)',
        },
        '& .MuiSelect-select.Mui-disabled': {
          '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.8)',
        },
      }}
      {...props}
    >
      {children}
    </Select>
  );
};

export const InputLabelSelect = ({children, ...props}) => {
  return (
    <InputLabel
      {...props} 
      shrink
      sx={{backgroundColor: '#fff'}}
    >
      {children}
    </InputLabel>
  )
};

export const TextFieldBase = ({ disabled, width, marginRight, textAlign, marginLeft, mb, ...props }) => {
  return (
    <TextField
      disabled={disabled}
      sx={{
        backgroundColor: '#FFFF',
        width: width,
        mb: mb || 0,
        ml: marginLeft || 0,
        mr: marginRight || 0,
        '& .MuiInputBase-root.Mui-disabled': {
          backgroundColor: 'rgb(128, 128, 128, 0.04)',
        },
        '& .MuiOutlinedInput-input.Mui-disabled': {
          '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.8)',
        },
      }}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputProps: {
          style: { textAlign: textAlign || 'left' },
        },
      }}
      {...props}
    />
  );
};

export const CheckboxBase = ({ disabled, label, mt, checked, ...props }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          {...props}
          disabled={disabled}
          checked={checked}
          sx={{
            '&.Mui-disabled': {
              color: checked ? 'rgba(25, 118, 210, 0.8)' : 'gray'
            },
          }}
        />
      }
      label={label}
      sx={{
        '& .MuiFormControlLabel-label.Mui-disabled': {
          color: 'black',
        },
        mt: mt || 0,
      }}
    />
  );
};

export const FormSectionBox = ({ children, ...props }) => {
  return ( 
    <Box sx={{
      backgroundColor: 'white', 
      py: 3, 
      px: 1, 
      my: 2, 
      borderRadius: '30px', 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'start'}}
      {...props}
    >
      {children}
    </Box>
  )
};

export const FormSectionTitle = ({ label, ...props}) => {
  return (
    <Chip
      label={label} 
      variant="contained" 
      sx={{ 
        backgroundColor: 'rgb(19, 35, 80)', 
        color: 'white', 
        mt: -5, 
        ml: 4, 
        fontSize: '0.95rem'}}
      {...props}
    />
  )
}