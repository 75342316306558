import React from "react";
import api from "../../Api/apiAxios";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../Components/Loading";
import { MyContext } from "../../Context/MyContext";
import Mensagem from "../../Components/Mensagem";
import { Button, Form, Row, Col, FloatingLabel, Alert } from "react-bootstrap";
import styles from "./EditarServico.module.css";

const ViewServico = () => {
    const { id } = useParams();

    const navigate = useNavigate();

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');  

    const { mensagemGlobal, setMensagemGlobal, setTipoMensagemGlobal, tipoMensagemGlobal } = React.useContext(MyContext);

    const [loading, setLoading] = React.useState(false);

    const [razaoSocialDistribuidor, setRazaoSocialDistribuidor] = React.useState('');
    const [razaoSocialCliente, setRazaoSocialCliente] = React.useState('');
    const [nomeUsuario, setNomeUsuario] = React.useState('');    
    const [sistema, setSistema] = React.useState('');
    const [statusServico, setStatusServico] = React.useState('');
    const [valorServico, setValorServico] = React.useState(0);
    const [valorParcela, setValorParcela] = React.useState(0);
    const [quantidadeParcelas, setQuantidadeParcelas] = React.useState('');
    const [descricaoServico, setDescricaoServico] = React.useState('');
    const [dataInclusao, setDataInclusao] = React.useState('');
    const [dataLiberacao, setDataLiberacao] = React.useState('');
    const [dataCancelamento, setDataCancelamento] = React.useState('');
    const [ressarcimento, setRessarcimento] = React.useState(false);

    React.useEffect(() => {
        async  function pesquisar() { 
               setLoading(true);                  
               const response = await api.get(`/Servico/${id}`);  
            //   console.log(response.data);            

               setRazaoSocialCliente(response.data.cliente.razaoSocial);
               setRazaoSocialDistribuidor(response.data.distribuidor.razaoSocial);
               setNomeUsuario(response.data.usuario.nome);
               setSistema(response.data.tipoSistema);
               setStatusServico(response.data.statusServico);
               setDescricaoServico(response.data.descricaoServico);
               setValorServico(response.data.valorServico);
               setQuantidadeParcelas(response.data.quantidadeParcelas);
               setValorParcela((response.data.valorServico / response.data.quantidadeParcelas).toFixed(2));               
               if  (response.data.ressarcimento === 1) {
                    setRessarcimento(true);
               }
               else setRessarcimento(false);

               const dataString = response.data.dataInclusao.substring(8,10) + "/" +
                                  response.data.dataInclusao.substring(5,7)  + "/" +   
                                  response.data.dataInclusao.substring(0,4);
               setDataInclusao(dataString);                                   
               
               if (response.data.statusServico === 1) {
                    const dataLiberacaoString = response.data.dataLiberacao.substring(8,10) + "/" +
                                                response.data.dataLiberacao.substring(5,7)  + "/" +   
                                                response.data.dataLiberacao.substring(0,4);
                    setDataLiberacao(dataLiberacaoString);
               }    
               else {
                    setDataLiberacao('* * * * * *');
               } 

               if (response.data.dataCancelamento !== null) {
                    const dataCancelamentoString = response.data.dataCancelamento.substring(8,10) + "/" +
                                                   response.data.dataCancelamento.substring(5,7)  + "/" +   
                                                   response.data.dataCancelamento.substring(0,4);
                    setDataCancelamento(dataCancelamentoString);
               }
               else {
                    setDataCancelamento('* * * * * *');
               }

               setLoading(false);       
        }

        pesquisar();            

    }, []);    


    if  (loading) return <Loading />

    return (
        <section className={styles.form}>
            {mensagemGlobal && <Mensagem mensagem={mensagemGlobal} setMensagem={setMensagemGlobal} variant={tipoMensagemGlobal} /> }                                 

            <Alert style={{background: 'ghostwhite', color: 'black'}}>
            {statusServico === 0 && <Alert.Heading style={{textAlign: 'center'}}>Serviço Digitado</Alert.Heading>}        
            {statusServico === 1 && <Alert.Heading style={{textAlign: 'center', color: 'seagreen'}}>Serviço Liberado</Alert.Heading>}        
            {statusServico === 2 && <Alert.Heading style={{textAlign: 'center', color: 'tomato'}}>Serviço Cancelado</Alert.Heading>}        

            <br />  

            <Row className="g-2" style={{marginBottom: '1rem'}}> 
                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Tipo de Sistema">
                        <Form.Select disabled value={sistema} onChange={({target}) => setSistema(target.value)}>
                            <option value="" disabled></option>
                            <option value="1">BmaPonto</option>
                            <option value="2">BmaAcesso</option>
                            <option value="3">BmaFolha</option>
                            <option value="4">SisDm</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid2" label="Data Inclusão">
                        <Form.Control placeholder="Release" value={dataInclusao} disabled />             
                    </FloatingLabel>                  
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid2" label="Data Liberação">
                        <Form.Control placeholder="Release" value={dataLiberacao} disabled />             
                    </FloatingLabel>                  
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid2" label="Data Cancelamento">
                        <Form.Control placeholder="Release" value={dataCancelamento} disabled />             
                    </FloatingLabel>                  
                </Col>
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>    
                <Col md='6'>
                    <FloatingLabel controlid="floatingInputGrid" label="Revenda">
                        <Form.Control placeholder="Release" value={razaoSocialDistribuidor} disabled
                                      onChange={({ target }) => setRazaoSocialDistribuidor(target.value)}/>             
                    </FloatingLabel>    
                </Col>

                <Col md='6'>
                    <FloatingLabel controlid="floatingInputGrid2" label="Cliente">
                        <Form.Control placeholder="Release" value={razaoSocialCliente} disabled
                                      onChange={({ target }) => setRazaoSocialCliente(target.value)}/>             
                    </FloatingLabel>                  
                </Col>
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid6" label="Valor Serviço">
                        <Form.Control placeholder="Val.Servico" value={valorServico} disabled />
                    </FloatingLabel>      
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Quantidade Parcelas">
                        <Form.Select value={quantidadeParcelas} 
                                     onChange={({target}) => setQuantidadeParcelas(target.value)} disabled>
                            <option value="" disabled></option>
                            <option value="1">01</option>
                            <option value="2">02</option>
                            <option value="3">03</option>
                            <option value="4">04</option>
                            <option value="5">05</option>
                            <option value="6">06</option>
                            <option value="7">07</option>
                            <option value="8">08</option>
                            <option value="9">09</option>
                            <option value="10">10</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid6" label="Valor Parcela">
                        <Form.Control placeholder="Val.Servico" value={valorParcela} disabled />
                    </FloatingLabel>      
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid16" label="Usuário">
                        <Form.Control placeholder="Val.Servico" value={nomeUsuario} disabled />
                    </FloatingLabel>      
                </Col>
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='12'>
                    <FloatingLabel controlid="floatingInputGrid6" label="Descrição do Serviço">
                        <Form.Control placeholder="Descrição Serviço" value={descricaoServico} disabled />
                    </FloatingLabel>      
                </Col>
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='12'>
                    <Form.Check 
                        type="switch"
                        id="status-servico"
                        readOnly
                        label="Ressarcimento para a Revenda"                      
                        onChange={() => setRessarcimento(!ressarcimento)}
                        checked={ressarcimento}
                    />
                </Col>
            </Row>

            </Alert>

            <div className={styles.areaBtn}>                
               <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Voltar</Button>
            </div>    

        </section>
    )

}

export default ViewServico;