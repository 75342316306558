import { useState, useRef, useContext } from 'react';
import { Box, IconButton, Paper, Tooltip, styled, ClickAwayListener } from "@mui/material";

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import FeedIcon from '@mui/icons-material/Feed';

import { PedidosContext } from '../../../../../Context/PedidosContext';
import api from '../../../../../Api/apiAxios';

export function ActionButtons({ show = false, item, isContrato = false }) {
  const [isVisible, setIsVisible] = useState(show);

  const anchorRef = useRef(null);

  const nivelUsuario = window.localStorage.getItem('nivelUsuario');

  const { setModalNegociacao, fetchNegociacoesAnteriores, setNegociacoesAnteriores, setModalCancelamentoContrato, setModalContrato } = useContext(PedidosContext)

  const handleIconClick = () => {
    setIsVisible(true);
  };

  const handleClickAway = () => {
    setIsVisible(false);
  };

  const handleEditarClick = async () => {
    setIsVisible(false);

    if (isContrato) {
      setModalContrato({ show: true, contrato: item })
    } else {
      setModalNegociacao({show: true, negociacao: item});
    }
  };

  const handleVisualizarClick = async () => {
    setIsVisible(false);
    setModalContrato({ show: true, contrato: item, isView: true })
  };

  const handleExcluirClick = async () => {
    setIsVisible(false);

    if (isContrato) {
      setModalCancelamentoContrato({ show: true, contrato: item })
    } else {
      await api.delete(`/NegociacaoContrato?id=${item.id}`);

      const negociacoes = await fetchNegociacoesAnteriores(item.idContrato);
      const negociacoesOrdenadas = negociacoes.sort((a, b) => new Date(b.periodoInicial) - new Date(a.periodoInicial));

      setNegociacoesAnteriores(negociacoesOrdenadas)
    }
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <IconButton ref={anchorRef} onClick={handleIconClick} disabled={nivelUsuario !== '1'}>
        <MoreHorizIcon color='action' fontSize='small' sx={{ cursor: 'pointer' }} />
      </IconButton>
      {isVisible && nivelUsuario === '1' && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <StyledPaper elevation={2}>
            <Box sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              minWidth: '7rem',
              maxWidth: '15rem',
              alignItems: 'center',
              paddingLeft: '0.4rem',
              zIndex: 5000
            }}>
              <Tooltip title='Editar'>
                <BaseActionButton color='#ffc107' onClick={handleEditarClick} disabled={nivelUsuario !== '1' || (isContrato && item.statusContrato !== 1)}>
                  <EditIcon />
                </BaseActionButton>
              </Tooltip>

              {isContrato &&
                <Tooltip title='Visualizar'>
                  <BaseActionButton color='#198754' onClick={handleVisualizarClick}>
                    <FeedIcon />
                  </BaseActionButton>
                </Tooltip>
              }

              <Tooltip title={isContrato ? 'Cancelar' : 'Excluir'}>
                <BaseActionButton color='#dc3545' onClick={handleExcluirClick} disabled={nivelUsuario !== '1' || (isContrato && item.statusContrato !== 1)}>
                  <CancelIcon />
                </BaseActionButton>
              </Tooltip>
            </Box>
          </StyledPaper>
        </ClickAwayListener>
      )}
    </Box>
  );
}

export const BaseActionButton = styled(IconButton)(({ color }) => ({
  boxShadow: `0 0 1.5px ${color}`,
  color: color,
  margin: '0 0.5rem 0.3rem 0',
}));

const StyledPaper = styled(Paper)({
  position: 'absolute',
  top: 30,
  left: -100,
  zIndex: 1000,
  padding: '1rem 0',
  borderRadius: '30px',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: -8, 
    right: 20,
    width: 0,
    height: 0,
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
    borderBottom: '8px solid rgb(104,104,104, 0.18) ',
    zIndex: -1,
  }
});
