import React from "react";
import api from "../../Api/apiAxios";
import { Button, Table } from "react-bootstrap";
import { CardText, Pencil, ToggleOff, ToggleOn } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import HeaderCadastros from "../../Components/HeaderCadastros";
import Loading from "../../Components/Loading";
import { MyContext } from "../../Context/MyContext";
import Mensagem from "../../Components/Mensagem";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import styles from "./ConsultaClientes.module.css";
import ModalCliente from "../../Components/ModalCliente";

const ConsultaClientes = () => {

    const navigate = useNavigate();

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');  
    const idDistribuidor = window.localStorage.getItem('distribuidorId');

    const { mensagemGlobal, setMensagemGlobal, tipoMensagemGlobal, setTipoMensagemGlobal,
            listaGlobalClientes, setListaGlobalClientes,
            listaGlobalContratos, setListaGlobalContratos,
            distribuidorIdGlobal, setDistribuidorIdGlobal } = React.useContext(MyContext);

    const [pesquisa, setPesquisa] = React.useState('');
    const [clientes, setClientes] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [regCliente, setRegCliente] = React.useState();

    React.useEffect(() => {     
            setListaGlobalContratos(null);
            setDistribuidorIdGlobal('');

    }, []);            


    function pesquisarClick(event) { 
        event.preventDefault();     
        (async () => {
            try {
                setLoading(true);
                                      
                if  (pesquisa.length > 0) {
                    const response = await api.get('/Cliente');
                    response.data.sort((a,b) => a.razaoSocial.localeCompare(b.razaoSocial));

                    if  (isNaN(pesquisa)) {
                        const pesquisaLowerCase = pesquisa.toLowerCase();
                        const registrosFiltrados = response.data.filter((cliente) => 
                              cliente.razaoSocial.toLowerCase().includes(pesquisaLowerCase));               
                        setClientes(registrosFiltrados);                               
                    } else {
                        const cnpjComMascara = pesquisa.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
                        const registrosFiltrados = response.data.filter((cliente) => 
                              cliente.numeroInscricao.includes(cnpjComMascara));               
                        setClientes(registrosFiltrados);            
                    }
                }    
                setLoading(false);
        //        setPesquisa('');              

            } catch (error) {
                alert(error);
            }
        }
        )();
    }

    function adicionarClick() {

    }

    function acionarModalClick(regCliente) {
        setModalOpen(true);      
        setRegCliente(regCliente);  
    }

    async function editarClick(regCliente) {
        navigate(`/editarclienteglobal/${regCliente.id}`);         
}

    if (loading) return <Loading />

    if  (modalOpen) return <ModalCliente setModalOpen={setModalOpen} cliente={regCliente} />

    return (
       <section>
         {mensagemGlobal && <Mensagem mensagem={mensagemGlobal} setMensagem={setMensagemGlobal} variant={tipoMensagemGlobal} /> }                                 

          <HeaderCadastros titulo={"Clientes (Geral)"} textoBotao={"hide"} 
                pesquisa={pesquisa} setPesquisa={setPesquisa}
                pesquisarClick={pesquisarClick} adicionarClick={adicionarClick} />

            <Table striped hover responsive size="sm">
                <thead className={styles.thead}>
                    <tr className={styles.trHead}>
                            <th className={styles.th}>Revenda</th>
                            <th className={styles.th}>Razão Social</th>
                            <th className={styles.th}>Cnpj/Cpf</th>                          
                            <th className={styles.th}>Contato</th>                        
                            <th className={styles.th}>E-Mail</th>
                            <th className={styles.th}>Fone</th>
                        </tr>
                </thead>
                <tbody className={styles.tbody}>
                {clientes.map(cliente => 
                    <tr key={cliente.id} className={styles.trBody}>                                     
                        <td className={styles.td}>{cliente.nomeFantasiaDistribuidor}</td>    
                        {cliente.statusEmpresa === 1 ?     
                            <td style={{fontSize: "small", color: "black"}}>{cliente.razaoSocial.substring(0,60)}</td>          
                          : <td style={{fontSize: "small", color: "silver"}}>{cliente.razaoSocial.substring(0,60)}</td>
                        }
                        {cliente.statusEmpresa === 1 ?     
                            <td style={{fontSize: "small", color: "black"}}>{cliente.numeroInscricao}</td>
                          : <td style={{fontSize: "small", color: "silver"}}>{cliente.numeroInscricao}</td>
                        }
                        <td className={styles.td}>{cliente.nomeContato}</td>
                        <td className={styles.td}>{cliente.emailContato}</td>  
                        <td className={styles.td}>{cliente.foneContato}</td>
                        <td>
                            <OverlayTrigger   overlay={
                                <Tooltip id="btn-view-editar">Editar Cliente</Tooltip> }>                              
                                    <Button onClick={() => editarClick(cliente)} 
                                            disabled={nivelUsuario !== "1" && nivelUsuario !== "3"}                                      
                                            variant="outline-warning">
                                         <Pencil size={15} />       
                                    </Button>                                       
                            </OverlayTrigger>                                          

                            <span> </span>    

                            <OverlayTrigger overlay={
                                <Tooltip id="btn-detalhar">Detalhar</Tooltip> }> 
                                <Button onClick={() => acionarModalClick(cliente)}
                                        variant="outline-success">
                                    <CardText size={15}/>
                                </Button>   
                            </OverlayTrigger>   
                        </td>                        
 
                    </tr>
                )}
            </tbody>

            </Table>

       </section>
    )

}

export default ConsultaClientes;