import React from "react";
import api from "../../Api/apiAxios";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Col, FloatingLabel, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Mensagem from "../../Components/Mensagem";
import { USUARIOS_VALIDAR_EMAIL } from "../../Api/apiFetch";
import styles from "./EditarUsuario.module.css";

const EditarUsuario = () => {
    const { id } = useParams();

    const navigate = useNavigate();  
    
    const nivelUsuario = window.localStorage.getItem('nivelUsuario');    

    const tipos = [
        {id2: 1, tipo: 'Admin - BMA'}, 
        {id2: 2, tipo: 'Consulta - BMA'}, 
        {id2: 3, tipo: 'Admin - Revenda'}, 
        {id2: 4, tipo: 'Consulta - Revenda'},         
     ];         

    var regexEmail = /\S+@\S+\.\S+/;

    const [checked, setChecked] = React.useState(false);
    const [distribuidorId, setDistribuidorId] = React.useState('');
    const [razaoSocial, setRazaoSocial] = React.useState('');
    const [nome, setNome] = React.useState('');
    const [senha, setSenha] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [fone, setFone] = React.useState('');
    const [celular, setCelular] = React.useState('');
    const [data, setData] = React.useState('');

    const [tipoUsuarioSelecionado, setTipoUsuarioSelecionado] = React.useState('');

    const [mensagem, setMensagem] = React.useState('');
    const [tipoMensagem, setTipoMensagem] = React.useState(false);
 
    React.useEffect(() => {
        (async () => {
            try {   
                const response = await api.get(`/Usuario/${id}`);    
                setDistribuidorId(response.data.distribuidor.id);
                setRazaoSocial(response.data.distribuidor.razaoSocial);
                setNome(response.data.nome);
                setSenha(response.data.senha);
                setEmail(response.data.email);
                setFone(response.data.telefone);
                setCelular(response.data.celular);
                setTipoUsuarioSelecionado(response.data.tipoUsuario);
                setData(response.data.dataHoraCadastro);               
                if  (response.data.status === 1)
                    setChecked(true)
                else
                    setChecked(false);    
                
            } catch (error) {
                alert(error);
            }            
        }
        )();
    }, [id]);                

    function getValueTipoUsuario(e) {
        const idSelected2 = e.target.children[e.target.selectedIndex].getAttribute('id2');
        setTipoUsuarioSelecionado(idSelected2);       
    };  

    async function handleSubmit(event) {
        event.preventDefault();     

        if  (!regexEmail.test(email))
        {
            setMensagem("Formato do E-mail Invalido !");
            setTipoMensagem("danger");
            return;
        }

        if  (nome.length === 0 || senha.length <5) {
            setMensagem("Nome não preenchido ou Senha com numero de caracteres inferior a 5");
            setTipoMensagem("danger");
            return;
        }

        const { url, options } = USUARIOS_VALIDAR_EMAIL({ email });       
        const response = await fetch(url, options);        
        var retorno = await response.json();        

        if  (retorno.id > 0 && retorno.id !== Number(id)) {
            setMensagem("E-mail já vinculado em outro usuário !");
            setTipoMensagem("danger");
            return;
        }           

        const dataCadastro = new Date(data);
        const dataAtual = new Date();          

        let regUsuario = {
            Id: Number(id),
            DistribuidorId: distribuidorId,
            Nome: nome,
            Email: email,
            Senha: senha,
            Telefone: fone, 
            Celular: celular, 
            Status: checked ? 1 : 0,
            TipoUsuario: Number(tipoUsuarioSelecionado),
            DataHoraCadastro: dataCadastro,
            DataHoraUltimoAcesso: dataAtual,
        }              

        const ret = await api.put("Usuario/",
            JSON.stringify(regUsuario),
        {
            headers: { 'Content-Type': 'application/json'},
            withCredentials: false           
        })
        if  (ret.data.match(/Sucesso/)){
             navigate("/usuarios");
        }
        else
        {
            setMensagem("Ops, algo deu errado ! Atualize a pagina e tente novamente...");
            setTipoMensagem("danger");
        }     

    };

    return (
        <section className={styles.form}>

            {mensagem && <Mensagem mensagem={mensagem} setMensagem={setMensagem} variant={tipoMensagem} /> }                                 

            <Alert style={{background: 'ghostwhite', color: 'black'}}>
            <Alert.Heading style={{textAlign: 'center'}}>Editar Usuário</Alert.Heading>        
            <br />  

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='12'>
                    <FloatingLabel controlid="floatingInputGrid1" label="Revenda">
                        <Form.Control type="text" placeholder="name@example.com" disabled
                            value={razaoSocial} onChange={({ target }) => setRazaoSocial(target.value)} />
                    </FloatingLabel>                  
                </Col>
            </Row>

            <Row className="g2" style={{marginBottom: '1rem'}}>

                <Col md='7'>
                    <FloatingLabel controlid="floatingInputGrid2" label="Nome">
                        <Form.Control type="text" placeholder="name@example.com" required
                            value={nome} onChange={({ target }) => setNome(target.value)} />
                    </FloatingLabel>
                </Col>                
                <Col md='5'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Senha">

                    {nivelUsuario === "1" ?          
                        <Form.Control type="text" placeholder="name@example.com" required
                            value={senha} onChange={({ target }) => setSenha(target.value)} /> 
                    :                            
                        <Form.Control type="password" placeholder="name@example.com" disabled
                        value={senha} onChange={({ target }) => setSenha(target.value)} /> 
                    }

                    </FloatingLabel>
                </Col>                
                
            </Row>                

            <Row className="g2" style={{marginBottom: '1rem'}}>

                <Col md='12'>
                    <FloatingLabel controlid="floatingInputGrid4" label="E-mail">
                        <Form.Control type="email" placeholder="name@example.com" 
                            value={email} onChange={({ target }) => setEmail(target.value)} /> 
                    </FloatingLabel>
                </Col>     

            </Row>               

            <Row className="g2" style={{marginBottom: '1rem'}}>

                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid5" label="Fone (Fixo)">
                        <Form.Control type="text" placeholder="name@example.com" 
                            value={fone} onChange={({ target }) => setFone(target.value)} />
                    </FloatingLabel>
                </Col>                

                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid6" label="Celular">
                        <Form.Control type="text" placeholder="name@example.com" 
                            value={celular} onChange={({ target }) => setCelular(target.value)} />
                    </FloatingLabel>
                </Col>                

                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid7" label="Tipo do Usuário">
                        <Form.Select onChange={getValueTipoUsuario}  className=" form-select-control" >
                            <option></option>
                            {tipos.map((reg) => (
                                    <option key={reg.id2} 
                                            id2={reg.id2}
                                            value={reg.id2}
                                            selected={reg.id2 === tipoUsuarioSelecionado}>{reg.tipo}</option>
                            ))}

                        </Form.Select>                        
                    </FloatingLabel>
                </Col>                
            </Row>                

            <Row>
              <Col md='4'>
                  <Form.Check 
                      type="switch"
                      id="status-usuario"
                      label="Usuário Ativo"                      
                      onChange={() => setChecked(!checked)}
                      checked={checked}
                  />
              </Col>
            </Row>

            </Alert>              

            <div className={styles.areaBtn}>                
                {nivelUsuario === "1" ?
                  <Button onClick={handleSubmit} variant="outline-primary" className={styles.btn}>Atualizar</Button>                         
                  :
                  <Button onClick={handleSubmit} variant="outline-primary" className={styles.btn} disabled>Atualizar</Button>
                  }
               <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Voltar</Button>
            </div>    

        </section>

    )
}

export default EditarUsuario;