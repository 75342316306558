import React, { useState, useEffect } from "react";
import { VictoryChart, VictoryBar, VictoryLabel } from "victory"
import { DashboardAlternadoContext } from "../../../../Context/DashboardAlternadoContext";
import Loading from "../../../../Components/Loading";

import styles from '../../DashboardAlternado.module.css'

export function TotaisContratos() {
  const [dadosGrafico, setDadosGrafico] = useState(null);
  const { contratos30, contratos60, contratos90, initContratos } = React.useContext(DashboardAlternadoContext);

  useEffect(() => {
    async function fetchData() {
      try {
        if (contratos30 === null || contratos60 === null || contratos90 === null) await initContratos();
        
        setDadosGrafico([
          {x: 28, y: contratos30.quantidade_cancelados, label: contratos30.quantidade_cancelados, fill: 'tomato', align: "start"}, 
          {x: 32, y: contratos30.quantidade_novos, label: contratos30.quantidade_novos, fill: 'palegreen', align: "end"},
          {x: 58, y: contratos60.quantidade_cancelados, label: contratos60.quantidade_cancelados, fill: 'tomato', align: "start"}, 
          {x: 62, y: contratos60.quantidade_novos, label: contratos60.quantidade_novos, fill: 'palegreen', align: "end"},
          {x: 88, y: contratos90.quantidade_cancelados, label: contratos90.quantidade_cancelados, fill: 'tomato', align: "start"}, 
          {x: 92, y: contratos90.quantidade_novos, label: contratos90.quantidade_novos, fill: 'palegreen', align: "end"},
        ])
      } catch (e) {
        console.log(e);
      }
    }

    fetchData();
  }, [contratos30, contratos60, contratos90])

  if (dadosGrafico === null) return <Loading />;

  return(
    <>
      <h1 className={styles.chartHeader}>Contratos por Período</h1>
      <VictoryChart width={900} height={500}>
        <VictoryBar
          alignment={({ datum }) => `${datum.alignment}`} 
          domain={{x: [25, 95], y: [0, 200]}}
          labelComponent={<VictoryLabel/>} 
          labels={({ datum }) => `${datum.label}`}
          data={dadosGrafico}
          style={{ data: { fill: ({ datum }) => datum.fill, width: 20 }, labels: {fontSize: 14} } }
        />
      </VictoryChart>
      
      <div className={styles.captionContainer} style={{fontWeight: 'bold'}}>
        <label className={styles.captionLabel}>
          <span className={styles.captionColorSquare} style={{ backgroundColor: 'tomato'}} />
          Contratos Cancelados
        </label>

        <label className={styles.captionLabel}>
          <span className={styles.captionColorSquare} style={{backgroundColor: 'palegreen'}} />
          Contratos Novos
        </label>
      </div>

      <div className={styles.captionContainer}>
        <label className={styles.captionLabel}>
          Contratos dos últimos 30 / 60 / 90 dias
        </label>
      </div>
      
    </>
  )
}