import { Box, Container, Divider, Grid, MenuItem, Paper, Typography } from "@mui/material";
import { ButtonBase, CheckboxBase, FormControlBase, FormSectionBox, FormSectionTitle, InputLabelSelect, SelectBase, TextFieldBase } from "../../../styles";
import { FormikProvider, useFormik } from "formik";
import * as Yup from 'yup';
import { useContext, useEffect } from "react";
import { MyContext } from "../../../../../Context/MyContext";
import api from "../../../../../Api/apiAxios";
import { format, subDays } from "date-fns";
import { PedidosContext } from "../../../../../Context/PedidosContext";

export function ModalCancelamentoAcesso({ pedido,  clientes }) {  
  const idUsuario = window.localStorage.getItem('idUsuario');

  const { setMensagemGlobal, setTipoMensagemGlobal } = useContext(MyContext);

  const { 
    fetchPedidosAcesso, 
    setModalCancelamento, 
    motivosCancelamento, 
    fetchMotivosCancelamento,
    setIsLoading,
  } = useContext(PedidosContext);

  const handleCancel = async (values) => {
    const resp = await api.put(`/BmaAcesso/atualizar-status-pedido?id=${pedido.id}&status=2&motivo=${formik.values.motivoCancelamento}&motivoCancelamentoOpcao=${formik.values.motivoCancelamentoOpcao}&dataCancelamento=${formik.values.dataCancelamento}&usuarioId=${idUsuario}`);
    
    setIsLoading(true);
    
    fetchPedidosAcesso()
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false));
    setModalCancelamento({show: false, pedido: {}})   

    if (resp.data.id > 0) {
      setMensagemGlobal('Pedido atualizado com sucesso!');
      setTipoMensagemGlobal("success");
    } else {
      setMensagemGlobal('Ocorreu um erro ao atualizar o pedido.');
      setTipoMensagemGlobal("danger");
    }
                   
  }

  const validationSchema = Yup.object({
    tipoPedido: Yup.number().nullable(),
    cliente: Yup.string().nullable(),
    numeroInscricao: Yup.string().nullable(),
    dataPedido: Yup.date().nullable(),
    totalPedido: Yup.number(),
    valorMensal: Yup.number(),
    bancoDeDados: Yup.number().nullable(),
    vertical: Yup.number().nullable(),
    versao: Yup.number(),
    release: Yup.number(),
    serverAdicional: Yup.number(),
    equipamentosAdicionais: Yup.number(),
    usuariosAgenda: Yup.number(),
    modalidadeLocacao: Yup.boolean(),
    alunos: Yup.boolean(),
    funcionarios: Yup.boolean(),
    pacientes: Yup.boolean(),
    moduloLGPD: Yup.boolean(),
    ponto: Yup.boolean(),
    portalTerceiros: Yup.boolean(),
    contrato: Yup.boolean(),
    dataCancelamento: Yup.date().required('Data de cancelamento deve ser informada.').min(subDays(new Date(), 1), 'A data de cancelamento não pode ser anterior à data atual'),
    motivoCancelamentoOpcao: Yup.number().required('Motivo do cancelamento deve ser selecionado.'),
    motivoCancelamento: Yup.string()
    .nullable(),
  });

  const dataDeCancelamento = pedido.dataCancelamento !== null ? format(new Date(pedido.dataCancelamento), 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd')

  const formik = useFormik({
    initialValues: {
      tipoPedido: pedido.tipoPedido,
      cliente: pedido.cliente.nomeFantasia,
      numeroInscricao: pedido.cliente.numeroInscricao,
      dataPedido: format(new Date(pedido.dataPedido), 'yyyy-MM-dd'),
      totalPedido: pedido.valorBruto,
      valorMensal: pedido.valorMensal,
      bancoDeDados: pedido.tipoBancoDados,
      vertical: pedido.tipoVertical,
      versao: pedido.versaoSistema,
      release: pedido.versaoRelease,
      serverAdicional: pedido.serverAdicional,
      equipamentosAdicionais: pedido.equipamentoAdicional,
      usuariosAgenda: pedido.usuariosAgenda,
      modalidadeLocacao: pedido.locacao,
      alunos: pedido.integracaoAlunos,
      funcionarios: pedido.integracaoFuncionarios,
      pacientes: pedido.integracaoPacientes,
      moduloLGPD: pedido.moduloLGPD,
      ponto: pedido.integracaoPonto,
      portalTerceiros: pedido.portalTerceiros,
      contrato: pedido.contrato,
      dataCancelamento: dataDeCancelamento,
      motivoCancelamentoOpcao: pedido.motivoCancelamentoOpcao || 0,
      motivoCancelamento: pedido.motivoCancelamento || '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleCancel(values);
    },
  });

  useEffect(() => {
    fetchMotivosCancelamento();
  }, [])

  return (
    <Container sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <Paper sx={{ 
          p: 2, 
          width: '80%', 
          alignSelf: 'center', 
          position: 'fixed', 
          top: 80, 
          overflow: 'auto', 
          zIndex: 1000, 
          maxHeight: '80vh',
          backgroundColor: '#f8f9fa',
        }}  
        elevation={3}
      >
        <Typography variant="h5" sx={{ pb: 1, color: 'tomato', fontWeight: 'bold', width: '100%', textAlign: 'center' }}>
          Cancelar Pedido
        </Typography>

        <Divider />

        <Box
          component='form'
          onSubmit={formik.handleSubmit}
          sx={{ p: 1, mt: 1, display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <FormSectionBox>
            <FormSectionTitle label='Dados do Pedido' />
              <Grid container spacing={2} sx={{ pb: 2, pl: 4, mt: 1, display: 'flex' }}>
                <Grid item xs={4}>
                  <FormControlBase>
                    <InputLabelSelect id="tipoPedido">Tipo de Pedido</InputLabelSelect>
                    <SelectBase 
                      id="tipoPedido"
                      name="tipoPedido"
                      label='Tipo de Pedido'
                      value={formik.values.tipoPedido}
                      isShow={true}
                      width='100%'
                    >
                      <MenuItem value={0}>Cópia Nova</MenuItem>
                      <MenuItem value={1}>Atualização</MenuItem>
                    </SelectBase>
                  </FormControlBase>
                </Grid>
                <Grid item xs={4}>
                  <FormControlBase>
                    <InputLabelSelect id="cliente">Cliente</InputLabelSelect>
                    <TextFieldBase 
                      id="cliente" 
                      label="Cliente"
                      name="cliente"
                      value={formik.values.cliente}
                      disabled
                      width='100%'
                    />
                  </FormControlBase>
                </Grid>
                <Grid item xs={4}>
                  <FormControlBase>
                    <InputLabelSelect id="numeroInscricao">CNPJ / CPF</InputLabelSelect>
                    <TextFieldBase 
                      id="numeroInscricao" 
                      label="CNPJ / CPF"
                      name="numeroInscricao"
                      value={formik.values.numeroInscricao}
                      disabled
                      width='100%'
                    />
                  </FormControlBase>
                </Grid>
                <Grid item xs={4}>
                <FormControlBase>
                    <TextFieldBase 
                      label="Data do Pedido" 
                      name="dataPedido"
                      value={formik.values.dataPedido}
                      type="date"
                      width='100%'
                      disabled={true}
                    />
                  </FormControlBase>
                </Grid>
                <Grid item xs={4}>
                  <FormControlBase>
                    <TextFieldBase 
                      label="Total Pedido (R$)"
                      name="totalPedido"
                      value={formik.values.totalPedido} 
                      disabled
                      width='100%'
                    />
                  </FormControlBase>
                </Grid>
                <Grid item xs={4}>
                  <FormControlBase>
                    <TextFieldBase 
                      label="Valor Mensal (R$)"
                      name="valorMensal"
                      value={formik.values.valorMensal} 
                      disabled 
                      width='100%'
                    />
                  </FormControlBase>
                </Grid>
              </Grid>
          </FormSectionBox>

          <FormSectionBox>
            <FormSectionTitle label='Dados da Licença' />
            <Grid container spacing={2} sx={{ pb: 2, pl: 4, mt: 1, display: 'flex' }}>
              <Grid item xs={4}>
                <FormControlBase>
                  <InputLabelSelect id="bancoDeDados">Banco de Dados</InputLabelSelect>
                  <SelectBase 
                    id="bancoDeDados" 
                    label="Banco de Dados"
                    name="bancoDeDados"
                    value={formik.values.bancoDeDados}
                    isShow={true}
                    width='100%'
                  >
                    <MenuItem value={1}>MySql</MenuItem>
                    <MenuItem value={2}>SqlServer</MenuItem>
                    <MenuItem value={3}>Oracle</MenuItem>
                  </SelectBase>
                </FormControlBase>
              </Grid>
              <Grid item xs={4}>
                <FormControlBase>
                  <InputLabelSelect  id="vertical">Vertical</InputLabelSelect>
                  <SelectBase 
                    id="vertical" 
                    label="Vertical"
                    name="vertical"
                    value={formik.values.vertical}
                    width='100%'
                    isShow={true}
                  >
                    <MenuItem value={1}>Empresa</MenuItem>
                    <MenuItem value={2}>Refeitório</MenuItem>
                    <MenuItem value={3}>Academia</MenuItem>
                    <MenuItem value={4}>Condomínio</MenuItem>
                    <MenuItem value={5}>Hospital</MenuItem>
                    <MenuItem value={6}>Escola</MenuItem>
                  </SelectBase>
                </FormControlBase>
              </Grid>
              <Grid item xs={4}>
                <FormControlBase>
                  <TextFieldBase 
                    id="versao"
                    name="versao"
                    label="Versão"
                    value={`${formik.values.versao.toString().includes('.') ? formik.values.versao : (formik.values.versao.toString().substring(1).length > 0 ? `${formik.values.versao.toString().substring(0,1)}.${formik.values.versao.toString().substring(1)}` : formik.values.versao)}.${formik.values.release}`}
                    width='100%'
                    disabled 
                  />
                </FormControlBase>
              </Grid>
              <Grid item xs={4}>
                <FormControlBase>
                  <TextFieldBase 
                    label="Server Adicional"
                    name="serverAdicional"
                    value={formik.values.serverAdicional}
                    width='100%'
                    disabled={true}
                  />
                </FormControlBase>
              </Grid>
              <Grid item xs={4}>
                <FormControlBase>
                  <TextFieldBase 
                    id="equipamentosAdicionais" 
                    label="Equipamentos Adicionais"
                    name="equipamentosAdicionais"
                    value={formik.values.equipamentosAdicionais}
                    width='100%'
                    disabled={true}
                  />
                </FormControlBase>
              </Grid>
              <Grid item xs={4}>
                <FormControlBase>
                  <TextFieldBase 
                    label="Usuários Agenda"
                    name="usuariosAgenda"
                    value={formik.values.usuariosAgenda}
                    width='100%'
                    disabled={true}
                  />
                </FormControlBase>
              </Grid>
            </Grid>

            <FormikProvider value={formik}>
              <Grid container spacing={2} sx={{ pb: 2, pl: 4, mt: 1, display: 'flex' }}>
                <Grid item xs={3}>
                  <CheckboxBase
                    name="modalidadeLocacao"
                    checked={formik.values.modalidadeLocacao}
                    disabled
                    label="Modalidade Locação"
                  />                        
                </Grid>
                <Grid item xs={3}>
                  <CheckboxBase
                    name="alunos"
                    checked={formik.values.alunos}
                    disabled
                    label="Alunos"
                  />  
                </Grid>
                <Grid item xs={3}>
                  <CheckboxBase
                    name="funcionarios"
                    checked={formik.values.integracaoFuncionarios}
                    disabled
                    label="Funcionários"
                  /> 
                </Grid>
                <Grid item xs={3}>
                  <CheckboxBase
                    name="pacientes"
                    checked={formik.values.integracaoPacientes}
                    disabled
                    label="Pacientes"
                  /> 
                </Grid>
                <Grid item xs={3}>
                  <CheckboxBase
                    name="moduloLGPD"
                    checked={formik.values.moduloLGPD}
                    disabled
                    label="Módulo LGPD"
                  /> 
                </Grid>
                <Grid item xs={3}>
                  <CheckboxBase
                    name="ponto"
                    checked={formik.values.ponto}
                    disabled
                    label="Ponto"
                  /> 
                </Grid>
                <Grid item xs={3}>
                  <CheckboxBase
                    name="portalTerceiros"
                    checked={formik.values.portalTerceiros}
                    disabled
                    label="Portal Terceiros"
                  /> 
                </Grid>
                <Grid item xs={3}>
                  <CheckboxBase
                    name="contrato"
                    checked={formik.values.contrato}
                    disabled
                    label="Contrato"
                  /> 
                </Grid>
              </Grid>
            </FormikProvider>
          </FormSectionBox>

          <FormSectionBox>
            <FormSectionTitle label='Dados do Cancelamento' />
            <Grid container spacing={2} sx={{ pb: 2, px: 4, mt: 1, display: 'flex' }}>
              <Grid item xs={4}>
                <FormControlBase>
                  <TextFieldBase 
                    label="Data de Cancelamento" 
                    name="dataCancelamento"
                    value={formik.values.dataCancelamento}
                    type="date"
                    onChange={formik.handleChange}
                    onBlur={formik.onBlur}
                    error={formik.touched.dataCancelamento && Boolean(formik.errors.dataCancelamento)}
                    helperText={formik.touched.dataCancelamento && formik.errors.dataCancelamento}
                    width='100%'
                  />
                </FormControlBase>
              </Grid>
              <Grid item xs={8}>
                <FormControlBase>
                  <InputLabelSelect  id="motivoCancelamentoOpcao">Motivo</InputLabelSelect>
                  <SelectBase 
                    id='motivoCancelamentoOpcao'
                    name='motivoCancelamentoOpcao'
                    value={formik.values.motivoCancelamentoOpcao}
                    onChange={formik.handleChange}
                    error={formik.touched.motivoCancelamentoOpcao && Boolean(formik.errors.motivoCancelamentoOpcao)}
                    helperText={formik.touched.motivoCancelamentoOpcao && formik.errors.motivoCancelamentoOpcao}
                    label='Motivo'
                    width='100%'
                  >
                    {motivosCancelamento.map((motivo) => (
                      <MenuItem key={motivo.id} value={motivo.id}>
                        {motivo.descricaoMotivo}
                      </MenuItem>
                    ))}
                  </SelectBase>
                </FormControlBase>
              </Grid>

              <Grid item xs={12}>
                <FormControlBase>
                  <TextFieldBase 
                    label="Detalhes"
                    name="motivoCancelamento"
                    value={formik.values.motivoCancelamento}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.motivoCancelamento && Boolean(formik.errors.motivoCancelamento)}
                    helperText={formik.touched.motivoCancelamento && formik.errors.motivoCancelamento}
                    width='100%'  
                    multiline 
                    rows={4}
                  />
                </FormControlBase>
              </Grid>
            </Grid>
          </FormSectionBox>
          
          <Divider />

          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 0.5, pt: 2}}>
            <ButtonBase variant="outlined" onClick={() => setModalCancelamento({show: false, pedido: {}})}>
              Voltar
            </ButtonBase>

            <ButtonBase type="submit" variant="contained">
              Cancelar
            </ButtonBase>
          </Box>
        </Box>
      </Paper>
    </Container>
  )
}
