import { useContext, useEffect,useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Container, IconButton, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { format } from "date-fns";
import { useFormik } from "formik";
import * as Yup from 'yup';

import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import { ButtonBase, FormSectionBox, FormSectionTitle } from "../../styles";
import { PedidosContext } from "../../../../Context/PedidosContext";
import { StyledTableCell } from "../acesso/acessoIndex";
import { ModalCancelamento } from "./modalCancelamento";
import { ModalNegociacao } from "./modalNegociacao";
import { ActionButtons } from "./actionButtons";

import Mensagem from "../../../../Components/Mensagem";
import Loading from "../../../../Components/Loading";
import StatusPedido from "../../../../Components/StatusPedido";
import { ModalContrato } from "./modalContrato";

export function ContratoTab() {

  const navigate = useNavigate();

  const nivelUsuario = window.localStorage.getItem('nivelUsuario');

  const {
    isLoading,
    setIsLoading,
    productVariant,
    contrato,
    mensagemGlobal,
    setMensagemGlobal,
    tipoMensagemGlobal,
    modalCancelamentoContrato,
    fetchNegociacoesAnteriores,
    modalNegociacao,
    setModalNegociacao,
    negociacoesAnteriores,
    setNegociacoesAnteriores,
    modalContrato,
    setModalContrato,
    fetchContratos,
    contratos,
    setContratos,
    pedidoLiberado,
    modalLiberacao,
    verificarECancelarContratos
  } = useContext(PedidosContext);

  const { id } = useParams();

  const validationSchema = Yup.object({
    idContrato: Yup.number().nullable(),
    valorContrato: Yup.number()
      .nullable(),
    periodoInicial: Yup.date()
      .nullable(),
    dataCancelamento: Yup.date()
      .nullable(),
  });

  const formik = useFormik({
    initialValues: {
      idContrato: 0,
      valorContrato: '',
      periodoInicial: '',
      dataCancelamento: '',
      valorNegociado: '',
      periodoInicialNegociacao: '',
      periodoFinalNegociacao: '',
      descricaoMotivo: '',
    },
    validationSchema: validationSchema,
  })

  useEffect(() => {
    async function loadContratos() {
      if (!id) return;

      try {
        setIsLoading(true)

        const listaContratos = await fetchContratos(id);
        await verificarECancelarContratos(listaContratos);

        const listaContratosAtualizados = await fetchContratos(id);
        const contratoAtivo = listaContratosAtualizados.filter(c => c.statusContrato === 1);
        const contratosRestantes = listaContratosAtualizados
            .filter(c => c.statusContrato !== 1)
            .sort((a, b) => new Date(b.dataAtivacao) - new Date(a.dataAtivacao));

        const listaContratosOrdenada = [...contratoAtivo, ...contratosRestantes];

        setContratos(listaContratosOrdenada);

        const negociacoes = await fetchNegociacoesAnteriores(contratoAtivo.id || contrato.id || formik.values.idContrato);

        if (negociacoes?.length > 0) {
          const negociacoesOrdenadas = negociacoes.sort((a, b) => new Date(b.periodoInicial) - new Date(a.periodoInicial));
          setNegociacoesAnteriores(negociacoesOrdenadas)
        }

        formik.setFieldValue('idContrato', contratoAtivo.id)
        formik.setFieldValue('valorContrato', contratoAtivo.valor)
        formik.setFieldValue('periodoInicial', format(new Date(contratoAtivo.dataAtivacao), 'yyyy-MM-dd'))
        if (contratoAtivo.dataCancelamento) formik.setFieldValue('dataCancelamento', format(new Date(contratoAtivo.dataCancelamento), 'yyyy-MM-dd'))
      } catch (e) {
        console.log(e)
      } finally {
        setIsLoading(false)
      }
    }

    loadContratos();
  }, [modalCancelamentoContrato.show, id, productVariant, modalContrato.show, pedidoLiberado, modalLiberacao.show])

  useEffect(() => {
    async function loadNegociacoes() {
      if (!contrato?.id && !formik.values.idContrato) return;
      try {
        setIsLoading(true);
        const negociacoes = await fetchNegociacoesAnteriores(contrato.id || formik.values.idContrato);
        const negociacoesOrdenadas = negociacoes.sort((a, b) => new Date(b.periodoInicial) - new Date(a.periodoInicial));
        setNegociacoesAnteriores(negociacoesOrdenadas);
      } catch (e) {
        console.log(e)
      } finally {
        setIsLoading(false);
      }

    }
    loadNegociacoes();
  }, [modalNegociacao.show])

  if (isLoading) return <Loading />;

  return (
    <>
    {contratos.length > 0 ? (
      <Box component='form' sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {mensagemGlobal && <Mensagem mensagem={mensagemGlobal} setMensagem={setMensagemGlobal} variant={tipoMensagemGlobal}/>}
        {modalCancelamentoContrato.show &&
          <ModalCancelamento contrato={contrato} />
        }
        {modalNegociacao.show &&
          <ModalNegociacao idContrato={formik.values.idContrato || contrato.id } />
        }
        {modalContrato.show &&
          <ModalContrato contratoSelecionado={modalContrato.contrato} isShow={modalContrato.isView}/>
        }
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', mb: 2 }}>
        </Box>
        <FormSectionBox>
          <FormSectionTitle label="Contratos" />
          <Container sx={{ my: 3 }}>
            {nivelUsuario === "1" &&
              <Box sx={{ display: 'flex', width: '98%', justifyContent: 'end', pb: 0.5}}>
                <Button
                  variant='text'
                  onClick={() => setModalContrato({ show: true, contrato: {} })}
                  sx={{height: '2.5rem', fontSize: '0.78rem', color: 'rgb(19, 35, 80)', display: 'flex', alignItems: 'end'}}
                  disabled={contrato.statusContrato === 1}
                >
                  <AddCircleIcon sx={{marginRight: '0.5rem'}}/>
                  Novo Contrato
                </Button>
              </Box>
            }
            <TableContainer component={Paper} sx={{overflow: 'visible'}}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Período Inicial</StyledTableCell>
                    <StyledTableCell>Período Final</StyledTableCell>
                    <StyledTableCell>Valor (R$)</StyledTableCell>
                    <StyledTableCell>Tipo</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>Ações</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contratos.map((row, index) => (
                    <TableRow key={index}>
                      <StyledTableCell>{format(new Date(row.dataAtivacao), 'dd/MM/yyyy')}</StyledTableCell>
                      <StyledTableCell>{row.dataCancelamento !== null ? format(new Date(row.dataCancelamento), 'dd/MM/yyyy'): '**********'}</StyledTableCell>
                      <StyledTableCell>{row.valor.toFixed(2)}</StyledTableCell>
                      {row.tipoContrato === 0 && <StyledTableCell>Manut.</StyledTableCell>}
                      {row.tipoContrato === 1 && <StyledTableCell style={{color: "darkcyan"}}>Locação</StyledTableCell>}
                      {row.tipoContrato === 2 && <StyledTableCell style={{color: "blueviolet"}}>SaaS</StyledTableCell>}
                      <StyledTableCell><StatusPedido status={row.statusContrato} isPedido isContrato/></StyledTableCell>
                      <StyledTableCell><ActionButtons item={row} isContrato/></StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </FormSectionBox>

        <FormSectionBox>
          <FormSectionTitle label="Negociações" />
          <Container sx={{ my: 3 }}>
            {nivelUsuario === "1" &&
              <Box sx={{ display: 'flex', width: '98%', justifyContent: 'end', pb: 0.5}}>
                <Button
                  variant='text'
                  onClick={() => setModalNegociacao({show: true, negociacao: {}})}
                  sx={{height: '2.5rem', fontSize: '0.78rem', color: 'rgb(19, 35, 80)', display: 'flex', alignItems: 'end'}}
                  disabled={contrato.statusContrato !== 1}
                >
                  <AddCircleIcon sx={{marginRight: '0.5rem'}} />
                  Nova Negociação
                </Button>
              </Box>
            }
            <TableContainer component={Paper} sx={{ overflow: 'visible' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Período Inicial</StyledTableCell>
                    <StyledTableCell>Período Final</StyledTableCell>
                    <StyledTableCell>Valor Negociado (R$)</StyledTableCell>
                    <StyledTableCell>Motivo</StyledTableCell>
                    <StyledTableCell>Ações</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {negociacoesAnteriores.map((row, index) => (
                    <TableRow key={index}>
                      <StyledTableCell>{format(new Date(row.periodoInicial), 'dd/MM/yyyy')}</StyledTableCell>
                      <StyledTableCell>{format(new Date(row.periodoFinal), 'dd/MM/yyyy')}</StyledTableCell>
                      <StyledTableCell>{row.valorNegociado.toFixed(2)}</StyledTableCell>
                      <StyledTableCell>{row.descricaoMotivo}</StyledTableCell>
                      <StyledTableCell><ActionButtons item={row} /></StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </FormSectionBox>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
          <ButtonBase variant="outlined" onClick={() => navigate('/pedidos')}>
            Voltar
          </ButtonBase>
        </Box>
      </Box>
  ) : (
    <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2}}>
      <DoNotDisturbIcon sx={{ color: 'gray' }}/>
      <Typography variant="h6" sx={{ color: 'gray' }}>Pedido não possui contrato ativo. Solicite a liberação do pedido!</Typography>
    </Container>
  )}
  </>
  )
}