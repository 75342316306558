import React from 'react';
import Button from 'react-bootstrap/Button';
import { Table } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

function ModalNS( {modalNS, setModalNS, numeroSerie, setNumeroSerie, 
                   titleModal, listaNS, setNSSelecionada} ) {
  
  const handleClose = (registro) => {
    setModalNS(false);
    setNumeroSerie(registro.numeroSerie);
    setNSSelecionada(true);
  }

  return (
    <>
      <Modal
        show={modalNS}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>Selecione o NS - {titleModal}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <Table striped hover responsive size='sm'>
                <thead>
                    <tr>
                       <th>Número Serie</th>                        
                    </tr>
                </thead>
                <tbody>
                {listaNS.map(registro => 
                    <tr key={registro.numeroSerie}>                                     
                        <td>{registro.numeroSerie}</td>          
                        <td>                                    
                            <Button onClick={() => handleClose(registro)}
                                    variant="primary">
                                        Selecionar 
                            </Button>                             
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>
           
        </Modal.Body>
        <Modal.Footer>
{/*             <Button variant="secondary" onClick={handleClose}>
                Retornar
            </Button>
 */}        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalNS;

