import { Box, Container, Divider, Paper, Typography } from "@mui/material";
import { ButtonBase } from "../../styles";
import api from "../../../../Api/apiAxios";
import { useContext } from "react";
import { MyContext } from "../../../../Context/MyContext";
import { PedidosContext } from "../../../../Context/PedidosContext";
import { useLocation, useNavigate } from "react-router-dom";

export function ModalLiberacao({ pedido }) {

  const { setMensagemGlobal, setTipoMensagemGlobal } = useContext(MyContext);
  const { fetchPedidosAcesso, fetchPedidosFolha, fetchPedidosPonto, setModalLiberacao, productVariant, setIsLoading, setPedidoLiberado } = useContext(PedidosContext);

  const navigate = useNavigate();
  const location = useLocation();

  const handleLiberarPedidoClick = async () => {
    const atualizarUrl = {
      Acesso: '/BmaAcesso/atualizar-status-pedido',
      Ponto: '/BmaPonto/atualizar-status-pedido', 
      Folha: '/BmaFolha/atualizar-status-pedido',
    }
    setIsLoading(true);
    try {
      const resp = await api.put(`${atualizarUrl[productVariant]}?id=${pedido.id}&status=1`);

      productVariant === 'Acesso' ? fetchPedidosAcesso() : ( productVariant === 'Folha' ? fetchPedidosFolha() : fetchPedidosPonto());
      if (resp.data.id > 0) {
        setMensagemGlobal('Pedido atualizado com sucesso!');
        setTipoMensagemGlobal("success");
      } else {
        setMensagemGlobal('Ocorreu um erro ao atualizar o pedido.');
        setTipoMensagemGlobal("danger");
      }
    } catch (error) {
      console.log('erro: ', error)
    } finally {
      setModalLiberacao({show: false, pedido: {}});
      setIsLoading(false);
      
      if (location.pathname !== '/pedidos') {
        navigate(`/viewpedido${productVariant.toLowerCase()}/${pedido.id}`);
        setPedidoLiberado(true);
      }
    }
  }

  return (
    <Container sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <Paper sx={{ p: 2, width: '50%', alignSelf: 'center', position: 'fixed', top: 200, zIndex: 1000 }}>
        <Typography variant="h5" sx={{ pb: 1}}>
          Confirmação de Liberação do Pedido
        </Typography>

        <Divider />

        <Typography variant="subtitle1" sx={{ p: 2 }}>
          <strong>Cliente:</strong> {pedido.cliente.razaoSocial} 
          <br />
          <strong>Inscrição:</strong> {pedido.cliente.numeroInscricao} - <strong>Contrato:</strong> {pedido.contrato === 1 || pedido.contrato ? 'Sim' : 'Não'}
          <br />
          <strong>Tipo Cópia:</strong> {pedido.tipoInstalacao === 0 || productVariant === 'Acesso' ? 'Licenciado' : (pedido.tipoInstalacao === 1 && productVariant === 'Ponto' ? 'Saas Rep-C' : (productVariant === 'Ponto' ? 'Saas Rep-P' : 'Saas'))}
        </Typography>

        <Divider />

        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 0.5, pt: 2}}>
          <ButtonBase variant="contained" onClick={handleLiberarPedidoClick}>
            Liberar
          </ButtonBase>

          <ButtonBase variant="outlined" onClick={() => setModalLiberacao({show: false, pedido: {}})}>
            Retornar
          </ButtonBase>
        </Box>
      </Paper>
    </Container>
  )
}