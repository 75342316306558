import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useNavigate } from 'react-router-dom';
import { MyContext } from '../../../Context/MyContext';

import ReceiptIcon from '@mui/icons-material/Receipt';

import styles from '../Menu.module.css';

import api from '../../../Api/apiAxios';
import { IconButton, Tooltip } from '@mui/material';
import { OpcoesUsuario } from '../OpcoesUsuario';

function MenuDefault() {
    const navigate = useNavigate();
    const { logoutApp } = React.useContext(MyContext);
    const [username, setUsername] = useState('');

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');

    useEffect(() => {
        (async () => {
            const userId = window.localStorage.getItem('idUsuario');
            try {
                const response = await api.get(`/Usuario/${userId}`);
                setUsername(response.data.nome);
            } catch (e) {               
                if (e.response.status !== 401) {console.log(e)};
            }
            
        })();
    }, [])
  
    return (
        <Navbar collapseOnSelect expand="lg" style={{backgroundColor: '#132350'}} variant="dark" >
        <Container>
            <Navbar.Brand style={{cursor: 'pointer'}} onClick={()=>navigate("/")}>BmaPortal</Navbar.Brand>

            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Nav className={styles.nav_container}>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <div className={styles.menu_items}>
                        <NavDropdown title="Cadastros" id="collasible-nav-dropdown" style={{marginRight: "15px"}}>                    
                            <NavDropdown.Item onClick={()=>navigate("/clientes")}>Clientes</NavDropdown.Item>                   
                            <NavDropdown.Item onClick={()=>navigate("/revendas")}>Revendas</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={()=>navigate("/servicos")}>Serviços</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={()=>navigate("/versoes")}>Versões</NavDropdown.Item>
                            <NavDropdown.Item onClick={()=>navigate("/motivocancelamento")}>Motivos de Cancelamento</NavDropdown.Item>
                            <NavDropdown.Item onClick={()=>navigate("/tiposervico")}>Tipos de Servico</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={()=>navigate("/consultaclientes")}>Consulta Clientes (Geral)</NavDropdown.Item>                   
                        </NavDropdown>

                        <Nav.Link onClick={()=>navigate("/pedidos")} style={{marginRight: "15px"}}>Pedidos</Nav.Link>

                        <NavDropdown title="Licenças" id="collasible-nav-dropdown" style={{marginRight: "15px"}}>
                            <NavDropdown.Item onClick={()=>navigate("/personalizacoesponto")}>BmaPonto</NavDropdown.Item>
                            <NavDropdown.Item onClick={()=>navigate("/personalizacoesacesso")}>BmaAcesso</NavDropdown.Item>
                            <NavDropdown.Item onClick={()=>navigate("/personalizacoesfolha")}>BmaFolha</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={()=>navigate("/clienteversoes")}>Clientes x Sistemas</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={()=>navigate("/personalizacoesplanovertical")}>Consulta por Plano / Vertical</NavDropdown.Item>
                        </NavDropdown>

                        <NavDropdown title="Consultas" id="collasible-nav-dropdown" style={{marginRight: "15px"}}>
                            <NavDropdown.Item onClick={()=>navigate("/contratos")}>Contratos</NavDropdown.Item>
                            <NavDropdown.Item onClick={()=>navigate("/usuarios")}>Usuarios</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={()=>navigate("/relatoriopedidos")}>Pedidos / OS</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={()=>navigate("/relatorioparcialfaturamento")}>Parcial Mês - Faturamento (Totais)</NavDropdown.Item>
                            <NavDropdown.Item onClick={()=>navigate("/relatorioparcialfaturamentorevenda")}>Parcial Mês - Faturamento (Por Revenda)</NavDropdown.Item>
                            <NavDropdown.Item onClick={()=>navigate("/relatoriofaturamento")}>Faturamento por Período</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={()=>navigate("/desempenho")}>Desempenho por Revenda</NavDropdown.Item>
                            <NavDropdown.Item onClick={()=>navigate("/contratoscancelados")}>Contratos Cancelados</NavDropdown.Item>
                            <NavDropdown.Item onClick={()=>navigate("/dashboard")}>Dashboard</NavDropdown.Item>
                        </NavDropdown>

                                               
                    </div>
                </Navbar.Collapse>
            </Nav>
            <div className={styles.logout_container}>
                {nivelUsuario === "1" &&
                  <Tooltip title='Geração de Faturamento'>
                    <IconButton onClick={() => navigate("/geracaofaturamento")} sx={{ backgroundColor: '#0d6efd' }}>
                      <ReceiptIcon sx={{ color: 'white' }} fontSize='small' />
                    </IconButton>
                  </Tooltip>
                }
                <OpcoesUsuario />
            </div>
        </Container>
        </Navbar>

    );
}

export default MenuDefault;