import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FolderPlus } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { VictoryPie } from "victory";

import Loading from "../../../../Components/Loading";
import styles from "../../DashboardAlternado.module.css";
import { DashboardAlternadoContext } from "../../../../Context/DashboardAlternadoContext";

export const ContratosCancelados90Perc = () => {
  const [dadosGrafico, setDadosGrafico] = useState(null);
  const { buscaPorPeriodo, contratos90, setContratos90 } = React.useContext(DashboardAlternadoContext);
  
  const navigate = useNavigate();
  const dataAtual = new Date();
  
  useEffect(() => {
    async function fetchData() {
      try {
        let contratos = await contratos90;

        if (contratos === null) {
          contratos = await buscaPorPeriodo(90, dataAtual);
          setContratos90(contratos);
        }

        let total = contratos.quantidade_cancelados + contratos.quantidade_novos;
        let percent_cancelados = Number((contratos.quantidade_cancelados/total) * 100);
        let percent_novos = Number((contratos.quantidade_novos/total) * 100);

        setDadosGrafico([{x: "Contratos Cancelados", y: percent_cancelados}, {x: "Contratos Novos", y: percent_novos} ])
      } catch (e) {
        console.log(e);
      }
    }

    fetchData();
  }, [contratos90])

  if (dadosGrafico === null) return <Loading />;

  return (
    <>
      <h1 className={styles.chartHeader}>
        Contratos: 90 dias 

        <Button onClick={() => navigate(`/dashboard2details/${90}`)} className={styles.btn}>
          <FolderPlus size={24} />
        </Button>
      </h1>

      <VictoryPie
        colorScale={["#761a1a", "#295f4e"]}
        labels={({ datum }) => `${datum.y.toFixed(2)}%`}
        labelRadius={({ innerRadius }) => innerRadius + (window.innerHeight > 740 ? 170 : 100) }
        style={{ labels: { fill: "white",  fontSize: 24, fontWeight: "bold" }}}
        data={dadosGrafico}
        width={window.innerHeight > 740 ? 700 : 500}
        height={window.innerHeight > 740 ? 700 : 500}
      />

      <div className={styles.captionContainer} style={{fontWeight: 'bold'}}>
        <label className={styles.captionLabel}>
          <span className={styles.captionColorSquare} style={{backgroundColor: '#761a1a'}} />
          Contratos Cancelados
        </label>

        <label className={styles.captionLabel}>
          <span className={styles.captionColorSquare} style={{backgroundColor: '#295f4e'}} />
          Contratos Novos
        </label>
      </div>

      <div className={styles.captionContainer}>
        <label className={styles.captionLabel}>
          Percentual de contratos
        </label>
      </div>
    </>
  )
}