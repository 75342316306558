import React, { useEffect, useState } from 'react';
import { Alert, Row, Table, Button } from "react-bootstrap";
import { format } from 'date-fns';
import { useNavigate, useParams } from 'react-router-dom';
import { ExpandableTable } from '../../../Components/ExpandableTable';
import { DashboardAlternadoContext } from '../../../Context/DashboardAlternadoContext';
import { TableItemName } from '../../../Components/TableItemName';
import Loading from '../../../Components/Loading';

import styles from "./DashboardDetails.module.css";
import { DashboardModal } from '../../../Components/DashboardModal';

export function DashboardDetails() {
  const [itemsCancelados, setItemsCancelados] = useState(null);
  const [itemsNovos, setItemsNovos] = useState(null);
  const [totalItemsNovos, setTotalItemsNovos] = useState(null);
  const [totalItemsCancelados, setTotalItemsCancelados] = useState(null);

  const navigate = useNavigate();
  const dataAtual = new Date();

  const { qtd } = useParams();

  const { 
    contratos30, 
    setContratos30, 
    contratos60, 
    setContratos60, 
    contratos90, 
    setContratos90, 
    buscaPorPeriodo, 
    GroupedByDistributor, 
    setExpandable 
  } = React.useContext(DashboardAlternadoContext);

  useEffect(() => {

    async function fetchData() {
      try {
        let contratos = '';

        switch (qtd) {
          case "30":
            contratos = await contratos30;
            break;
          case "60":
            contratos = await contratos60;
            break;
          case "90":
            contratos = await contratos90;
            break;
          default:
            break;
        }

        if (contratos === null) {
          contratos = await buscaPorPeriodo(qtd, dataAtual);

          switch (qtd) {
            case "30":
              setContratos30(contratos);
              break;
            case "60":
              setContratos60(contratos);
              break;
            case "90":
              setContratos90(contratos);
              break;
            default:
              break;
          }
        }

        if (contratos && contratos.cancelados.length > 0) {
          setTotalItemsCancelados({totalTitle: 'Total', emptyValue: '', totalValue: contratos.quantidade_cancelados});
          setItemsCancelados(contratos.cancelados);
        }
        
        if (contratos && contratos.novos.length > 0) {
          setTotalItemsNovos({totalTitle: 'Total', emptyValue: '', totalValue: contratos.quantidade_novos});
          setItemsNovos(contratos.novos);
        }
      } catch (e) {
        console.log(e);
      }
    }

    fetchData();
  }, [contratos30, contratos60, contratos90])

  const columnsCancelados = [{
    title: 'Contratos Cancelados',
    renderCell: (item) => item.razaoSocialDistribuidor,
  },
  {
    title: 'Quantidade',
    renderCell: (item) => (item.totalCancelamentosAcesso + item.totalCancelamentosFolha + item.totalCancelamentosPonto),
  }];


  const columnsNovos = [{
    title: 'Contratos Novos',
    renderCell: (item) => item.distributor,
  },
  {
    title: 'Quantidade',
    renderCell: (item) => item.totalItems,
  }];

  const renderExpandedRowNovos = (item) => {
    item.items.sort(function compare(a, b) {
        var dateA = new Date(a.dataAtivacao);
        var dateB = new Date(b.dataAtivacao);
        return dateB - dateA;
      });

    return (
      <Table className={styles.contratos} hover responsive>
        <thead>
          <tr>
            <th>Data de Ativação</th>
            <th>Cliente</th>
            <th>Sistemas</th>
            <th>NS</th>
            <th>Tipo de Contrato</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody className={styles.registros}>
          {item.items.map(item => 
            <tr>
              <td>{format(item.dataAtivacao, 'dd/MM/yyyy')}</td>
              <TableItemName title={item.razaoSocialCliente} />
              <td>{item.sistema}</td>
              <td>{item.numeroSerie}</td>
              <td>{item.tipoContrato === 0 ? 'Manutenção' : (item.tipoContrato === 1 ? 'Locação' : 'Saas')}</td>
              <td>R$ {item.valor.toFixed(2)}</td>
            </tr>
          )}
            <tr>
              <td style={{fontWeight: 'bold'}}>Total</td>
              <td />
              <td />
              <td />
              <td />
              <td style={{fontWeight: 'bold'}}>R$ {item.items.reduce((total, atual) => total + atual.valor, 0).toFixed(2)}</td>
            </tr>
        </tbody>
      </Table>
    );
  }

  const renderExpandedRowCancelados = (item) => {
    item.listaContratos.sort(function compare(a, b) {
        var dateA = new Date(a.dataCancelamento);
        var dateB = new Date(b.dataCancelamento);
        return dateB - dateA;
      });

    return (
      <Table className={styles.contratos} hover responsive>
        <thead>
          <tr>
            <th>Data de Cancelamento</th>
            <th>Cliente</th>
            <th>Sistemas</th>
            <th>NS</th>
            <th>Data de Ativação</th>
            <th>Tipo de Contrato</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody className={styles.registros}>
          {item.listaContratos.map(item => 
            <tr>
              <td>{format(item.dataCancelamento, 'dd/MM/yyyy')}</td>
              <TableItemName title={item.razaoSocialCliente} />
              <td>{item.sistema}</td>
              <td>{item.numeroSerie}</td>
              <td>{format(item.dataAtivacao, 'dd/MM/yyyy')}</td>
              <td>{item.tipoContrato}</td>
              <td>R$ {item.valor.toFixed(2)}</td>
            </tr>
          )}
            <tr>
              <td style={{fontWeight: 'bold'}}>Total</td>
              <td />
              <td />
              <td />
              <td />
              <td />
              <td style={{fontWeight: 'bold'}}>R$ {item.listaContratos.reduce((total, atual) => total + atual.valor, 0).toFixed(2)}</td>
            </tr>
        </tbody>
      </Table>
    );
  }

  if (itemsCancelados === null || itemsNovos === null) return <Loading />;

  return (
    <>
      <Alert className={styles.detailsHeader}>
        <Row className={styles.headerRow}>
            <Alert.Heading className={styles.heading}>Detalhes de Contratos: {qtd} dias</Alert.Heading> 
            <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Voltar</Button>
        </Row>
      </Alert>

      <div>
        <DashboardModal>
          <ExpandableTable
            totalize={true} 
            headerBgColor='palegreen'
            headerColor='black'
            columns={columnsNovos}
            items={itemsNovos}
            total={totalItemsNovos}
            renderExpandedRow={renderExpandedRowNovos}
          />
        </DashboardModal>

        <DashboardModal>
          <ExpandableTable 
            totalize={true}
            headerBgColor='tomato'
            headerColor='black'
            columns={columnsCancelados}
            items={itemsCancelados}
            total={totalItemsCancelados}
            renderExpandedRow={renderExpandedRowCancelados}
          /> 
        </DashboardModal>
      </div>
    </>
  )
}