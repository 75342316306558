import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MyContext } from "../../Context/MyContext";
import { Button, Form, Row, Col, FloatingLabel, Alert } from "react-bootstrap";

import Loading from "../../Components/Loading";
import Mensagem from "../../Components/Mensagem";
import styles from "./NovaVersao.module.css";
import api from "../../Api/apiAxios";

const NovaVersao = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const nivelUsuario = window.localStorage.getItem('nivelUsuario');  
  
  const { mensagemGlobal, setMensagemGlobal, setTipoMensagemGlobal, tipoMensagemGlobal } = React.useContext(MyContext);

  const [loading, setLoading] = React.useState(false);  

  const [tipoSistema, setTipoSistema] = React.useState('');
  const [versao, setVersao] = React.useState(0);
  const [release, setRelease] = React.useState(0);
  const [tipoVertical, setTipoVertical] = React.useState('0');
  const [ultimaVersao, setUltimaVersao] = React.useState(''); 

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);

        const resp = await api.get(`/Versao/${id}`);
        console.log(resp.data)
        setVersao(resp.data.numeroVersao);
        setTipoSistema(resp.data.tipoSistema);
        setRelease(resp.data.release);
        setTipoVertical(resp.data.tipoVertical || "0");
        handleUltimaVersao({sistema_prop: resp.data.tipoSistema, vertical: resp.data.tipoVertical})
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }

    if (id) fetchData();
  }, [id])

  const handleTipoSistemaChange = (e) => {
    setTipoSistema(e);
    if (e !== "2") setTipoVertical('0');
    handleUltimaVersao({sistema_prop: e});
  }

  const handleUltimaVersao = async ({sistema_prop = '', vertical = ''}) => {
    try {
      const sistema = sistema_prop ? Number(sistema_prop) : Number(tipoSistema);
      if (sistema === 2 && vertical !== '') {
        const response = await api.get(`/Versao/retornar-versao-atual?tipoSistema=${sistema}&tipoVertical=${vertical}`);   
        const numeroVersaoEditada = response.data.numeroVersao.toString();
        const iniVer = numeroVersaoEditada.substring(1,0);
        const fimVer = numeroVersaoEditada.substring(2,1);
        setUltimaVersao(iniVer + "." + fimVer + "." + response.data.release);                                      
      } else if (sistema === 2 && vertical === '') {
        setUltimaVersao('');
      } else if (sistema !== 0 && sistema !== 2) {
        const response = await api.get(`/Versao/retornar-versao-atual?tipoSistema=${sistema}`);   
        const numeroVersaoEditada = response.data.numeroVersao.toString();
        const iniVer = numeroVersaoEditada.substring(2,0);
        const fimVer = numeroVersaoEditada.substring(2);
        setUltimaVersao(iniVer + "." + fimVer + "." + response.data.release);                                     
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  const handleVerticalChange = async (e) => {
    setTipoVertical(e);
    handleUltimaVersao({vertical: e});
  }

  const handleVersaoChange = (e) => {
    if (/^\d{0,4}$/.test(e)) setVersao(e);
  }

  const handleReleaseChange = (e) => {
    if (/^\d{0,3}$/.test(e)) setRelease(e);
  }

  async function handleSubmit(ev) {
    ev.preventDefault();
    setMensagemGlobal("");

    if (tipoSistema === undefined || tipoSistema === "" || tipoSistema === null || tipoSistema === 0) {
      setMensagemGlobal("Informe o Tipo do Sistema.")
      setTipoMensagemGlobal("danger");
      return;
    } else if (tipoSistema === "2" && (tipoVertical === "0" || tipoVertical === "" || tipoVertical === null )) {
      setMensagemGlobal("Selecione o tipo de Vertical para Acesso.")
      setTipoMensagemGlobal("danger");
      return;
    } else if (versao <= 0 || versao === "" || versao === null) {
      setMensagemGlobal("Informe a Versão.")
      setTipoMensagemGlobal("danger");
      return;
    } else if (release <= 0 || release === "" || release === null) {
      setMensagemGlobal("Informe a Release.")
      setTipoMensagemGlobal("danger");
      return;
    }

    const dataAtual = new Date();       
    let regVersao = {
      Id: id || 0,
      TipoSistema: Number(tipoSistema),
      NumeroVersao: Number(versao),
      Release: release,
      DataLiberacao: dataAtual,
      TipoVertical: tipoSistema === "2" ? Number(tipoVertical) : null,
    }                
            
    const ret = id ? await api.put("Versao/", JSON.stringify(regVersao), { headers: { 'Content-Type': 'application/json'} }) : await api.post("Versao/", JSON.stringify(regVersao), { headers: { 'Content-Type': 'application/json'} });

    setMensagemGlobal(ret.data);
    setTipoMensagemGlobal("success");               
    navigate("/versoes");
  }

  // async function pegarArquivo(e) {
  //   const fd = new FormData();
  //   fd.append('imagem', e.target.files[0]);

  //   const cpf = "948.252.899-91";
  //   const resp = await apiImg.post(`/Image?cpf=${cpf}`, fd);
  //   console.log(resp);    
  // }

  if  (loading) return <Loading />

  return (
    <section className={styles.form}>
      {mensagemGlobal && <Mensagem mensagem={mensagemGlobal} setMensagem={setMensagemGlobal} variant={tipoMensagemGlobal} /> }                                 

      <Alert style={{background: 'ghostwhite', color: 'black'}}>
        <Alert.Heading style={{textAlign: 'center'}}>Inserir Versão</Alert.Heading>        
      <br />  

      <Row className="g-2" style={{marginBottom: '1rem'}}>
          <Col md='3'>
          </Col>

          <Col md='6'>
            <FloatingLabel controlid="floatingInputGrid3" label="Selecione o Tipo de Sistema">
              <Form.Select value={tipoSistema} default="" onChange={({target}) => handleTipoSistemaChange(target.value)}>
                <option value="" disabled></option>
                <option value="1">BmaPonto</option>
                <option value="2">BmaAcesso</option>
                <option value="3">BmaFolha</option>
              </Form.Select>
            </FloatingLabel>                   
          </Col>
        </Row>

      <Row className="g-2" style={{marginBottom: '1rem'}}>
        <Col md='3'/>

        <Col md='4'>
          <FloatingLabel controlid="floatingInputGrid7" label="Selecione a Vertical">
            <Form.Select value={tipoVertical} default="0" onChange={({target}) => handleVerticalChange(target.value)} disabled={tipoSistema !== '2'}>
              <option value="0" disabled></option>
              <option value="1">Empresa</option>
              <option value="2">Refeitório</option>
              <option value="3">Academia</option>
              <option value="4">Condomínio</option>
              <option value="5">Hospital</option>
              <option value="6">Escola</option>
            </Form.Select>
          </FloatingLabel>                    
        </Col>

        <Col md='2'>
          <FloatingLabel controlid="floatingInputGrid5" label="Versão Atual">
            <Form.Control placeholder="Versao" value={ultimaVersao} disabled onChange={({ target }) => setUltimaVersao(target.value)} />
          </FloatingLabel>      
        </Col>                
      </Row>

      <Row className="g-2" style={{marginBottom: '1rem'}}>    
        <Col md='3' />

        <Col md='3'>
          <FloatingLabel controlid="floatingInputGrid5" label="Versão">
            <Form.Control placeholder="Versão" value={versao} onChange={({ target }) => handleVersaoChange(target.value)} />
          </FloatingLabel>      
        </Col>                

        <Col md='3'>
          <FloatingLabel controlid="floatingInputGrid6" label="Release">
            <Form.Control placeholder="Release" value={release} onChange={({ target }) => handleReleaseChange(target.value)}/>
          </FloatingLabel>      
        </Col>                
      </Row>

      </Alert>
      
      <div className={styles.areaBtn}>                
        <Button onClick={handleSubmit} variant="outline-primary" className={styles.btn} disabled={nivelUsuario !== "1"}>
          Confirmar
        </Button>
        <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>
          Voltar
        </Button>
      </div>    
    </section>
  )
}

export default NovaVersao;