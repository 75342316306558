import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { format, subDays } from "date-fns";
import { Box, Container, Divider, Grid, MenuItem, Paper, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from 'yup';

import { ButtonBase, FormControlBase, FormSectionBox, FormSectionTitle, InputLabelSelect, SelectBase, TextFieldBase } from "../../../styles";
import { PedidosContext } from "../../../../../Context/PedidosContext";
import api from "../../../../../Api/apiAxios";

export function ModalCancelamento() {
  const { id } = useParams()

  const { 
    productVariant, 
    motivosCancelamento, 
    fetchMotivosCancelamento,
    modalCancelamentoServico,
    setModalCancelamentoServico,
    fetchServicos,
    setServicos,
    fetchTiposServicos,
    tiposServicos,
  } = useContext(PedidosContext);

  const servico = modalCancelamentoServico.servico

  const handleCancel = async () => {
    setModalCancelamentoServico({show: false, servico: {}})
    
    const regServico = {
      Id: servico.id,
      PedidoId: servico.pedidoId,
      DistribuidorId: servico.distribuidorId,
      ClienteId: servico.clienteId,
      UsuarioId: servico.usuarioId,
      TipoSistema: servico.tipoSistema,
      TipoServico: servico.tipoServico,
      DescricaoServico: servico.descricaoServico,
      QuantidadeParcelas: servico.quantidadeParcelas,
      StatusServico: 2,
      ValorServico: servico.valorServico,
      DataInclusao: servico.dataInclusao,
      DataLiberacao: servico.dataLiberacao,
      NumeroSerie: servico.numeroSerie,
      Ressarcimento: servico.ressarcimento,
      IntegrarMesAnterior: servico.integrarMesAnterior,
      DataPrimeiroVencimento: servico.dataPrimeiroVencimento,
      DataCancelamento: formik.values.dataCancelamento,
      MotivoCancelamentoOpcao: formik.values.motivoCancelamentoOpcao,
      MotivoCancelamento: formik.values.motivoCancelamento
    };
  
    const ret = await api.put("Servico/", JSON.stringify(regServico), { headers: { 'Content-Type': 'application/json' } });
    console.log(ret.data);
  
    const servicos = await fetchServicos(id);
    const servicosOrdenados = servicos.sort((a, b) => new Date(b.periodoInicial) - new Date(a.periodoInicial));
    setServicos(servicosOrdenados);  
  }

  const validationSchema = Yup.object({
    dataCancelamento: Yup.date().required('Data de cancelamento é obrigatória.').min(subDays(new Date(), 1), 'A data de cancelamento deve ser a partir de hoje.'),
    motivoCancelamentoOpcao: Yup.number().required('Motivo do cancelamento deve ser selecionado.'),
    motivoCancelamento: Yup.string().nullable(),
    inicioFaturamento: Yup.date().nullable(),
    valorServico: Yup.number().nullable(),
    quantidadeParcelas: Yup.number().nullable(),
    valorParcela: Yup.number().nullable(),
    tipoServico: Yup.string().nullable(),
    descricaoServico: Yup.string().nullable(),
    credito: Yup.number().nullable(),
    integraFaturamentoAnt: Yup.boolean().nullable(),
  });

  const dataDeCancelamento = servico.dataCancelamento !== null ? format(new Date(servico.dataCancelamento), 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd');

  const formik = useFormik({
    initialValues: {
      tipoSistema: productVariant,
      distribuidor: servico.distribuidor.razaoSocial,
      cliente: servico.cliente.nomeFantasia,
      nomeFantasia: servico.cliente.nomeFantasia,
      dataInclusao: format(new Date(servico.dataInclusao), 'yyyy-MM-dd'),
      numeroSerie: servico.numeroSerie,
      idPedido: servico.pedidoId,
      dataCancelamento: dataDeCancelamento,
      motivoCancelamentoOpcao: servico.motivoCancelamentoOpcao || 0,
      motivoCancelamento: servico.motivoCancelamento || '',
      inicioFaturamento: servico.DataPrimeiroVencimento ? format(new Date(servico.DataPrimeiroVencimento), 'yyyy-MM-dd') : '',
      valorServico: servico.valorServico || 0,
      quantidadeParcelas: servico.quantidadeParcelas || 1,
      valorParcela: servico.valorParcela || 0,
      tipoServico: servico.tipoServico || '',
      descricaoServico: servico.descricaoServico || '',
      credito: servico.ressarcimento || 0,
      integraFaturamentoAnt: servico.IntegrarMesAnterior || 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleCancel();
    },
  });

  useEffect(() => {
    fetchMotivosCancelamento();
  }, []);

  useEffect(() => {
    fetchTiposServicos();
  }, [modalCancelamentoServico.show])

  return (
    <Container sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <Paper sx={{ 
          p: 2, 
          width: '80%', 
          alignSelf: 'center', 
          position: 'fixed', 
          top: 80, 
          overflow: 'auto', 
          zIndex: 1000, 
          maxHeight: '80vh',
          backgroundColor: '#f8f9fa',
        }}  
        elevation={3}
      >
        <Typography variant="h5" sx={{ pb: 1, color: 'tomato', fontWeight: 'bold', width: '100%', textAlign: 'center' }}>
          Cancelar Serviço
        </Typography>

        <Divider />

        <Box
          component='form'
          onSubmit={formik.handleSubmit}
          sx={{ p: 1, mt: 1, display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <FormSectionBox>
            <FormSectionTitle label='Dados do Servico' />
            <Grid container spacing={2} sx={{ pb: 2, px: 4, mt: 1, display: 'flex' }}>
              <Grid item xs={4}>
                <FormControlBase>
                  <TextFieldBase 
                    label="Início do Faturamento"
                    name="inicioFaturamento"
                    type="date"
                    value={formik.values.inicioFaturamento}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.inicioFaturamento && Boolean(formik.errors.inicioFaturamento)}
                    helperText={formik.touched.inicioFaturamento && formik.errors.inicioFaturamento}
                    width='100%'
                    disabled
                  />
                </FormControlBase>
              </Grid>

              <Grid item xs={4}>
                <FormControlBase>
                  <TextFieldBase 
                    label="Valor do Serviço (R$)"
                    name="valorServico"
                    type="number"
                    value={formik.values.valorServico}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.valorServico && Boolean(formik.errors.valorServico)}
                    helperText={formik.touched.valorServico && formik.errors.valorServico}
                    width='100%'
                    disabled
                  />
                </FormControlBase>
              </Grid>

              <Grid item xs={4}>
                <FormControlBase>
                  <TextFieldBase 
                    label="Quantidade de Parcelas"
                    name="quantidadeParcelas"
                    type="number"
                    value={formik.values.quantidadeParcelas}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.quantidadeParcelas && Boolean(formik.errors.quantidadeParcelas)}
                    helperText={formik.touched.quantidadeParcelas && formik.errors.quantidadeParcelas}
                    width='100%'
                    disabled
                  />
                </FormControlBase>
              </Grid>

              <Grid item xs={4}>
                <FormControlBase>
                  <TextFieldBase 
                    label="Valor da Parcela (R$)"
                    name="valorParcela"
                    type="number"
                    value={formik.values.valorParcela}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.valorParcela && Boolean(formik.errors.valorParcela)}
                    helperText={formik.touched.valorParcela && formik.errors.valorParcela}
                    width='100%'
                    disabled
                  />
                </FormControlBase>
              </Grid>

              <Grid item xs={4}>
                <FormControlBase>
                  <InputLabelSelect id="credito">Crédito</InputLabelSelect>
                  <SelectBase 
                    id="credito"
                    name="credito"
                    value={formik.values.credito}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.credito && Boolean(formik.errors.credito)}
                    helperText={formik.touched.credito && formik.errors.credito}
                    width='100%'
                    disabled
                  >
                    <MenuItem value={1}>Sim</MenuItem>
                    <MenuItem value={0}>Não</MenuItem>
                  </SelectBase>
                </FormControlBase>
              </Grid>

              <Grid item xs={4}>
                <FormControlBase>
                  <InputLabelSelect id="integraFaturamentoAnt">Integra Faturamento Ant.</InputLabelSelect>
                  <SelectBase 
                    id="integraFaturamentoAnt"
                    name="integraFaturamentoAnt"
                    value={formik.values.integraFaturamentoAnt}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.integraFaturamentoAnt && Boolean(formik.errors.integraFaturamentoAnt)}
                    helperText={formik.touched.integraFaturamentoAnt && formik.errors.integraFaturamentoAnt}
                    width='100%'
                    disabled
                  >
                    <MenuItem value={1}>Sim</MenuItem>
                    <MenuItem value={0}>Não</MenuItem>
                  </SelectBase>
                </FormControlBase>
              </Grid>

              <Grid item xs={12}>
              <FormControlBase>
                <InputLabelSelect  id="tipoServico">Tipo de Serviço</InputLabelSelect>
                <SelectBase 
                  id='tipoServico'
                  name='tipoServico'
                  value={formik.values.tipoServico}
                  onChange={formik.handleChange}
                  error={formik.touched.tipoServico && Boolean(formik.errors.tipoServico)}
                  helperText={formik.touched.tipoServico && formik.errors.tipoServico}
                  disabled
                  label='Motivo'
                  width='100%'
                >
                  {tiposServicos.map((tipo) => (
                    <MenuItem key={tipo.id} value={tipo.id}>
                      {tipo.tituloTipo}
                    </MenuItem>
                  ))}
                </SelectBase>
              </FormControlBase>
            </Grid>

              <Grid item xs={12}>
                <FormControlBase>
                  <TextFieldBase 
                    label="Descrição do Serviço"
                    name="descricaoServico"
                    value={formik.values.descricaoServico}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.descricaoServico && Boolean(formik.errors.descricaoServico)}
                    helperText={formik.touched.descricaoServico && formik.errors.descricaoServico}
                    width='100%'
                    multiline 
                    rows={4}
                    disabled
                  />
                </FormControlBase>
              </Grid>
            </Grid>
          </FormSectionBox>

          <FormSectionBox>
            <FormSectionTitle label='Dados do Cancelamento' />
            <Grid container spacing={2} sx={{ pb: 2, px: 4, mt: 1, display: 'flex' }}>
              <Grid item xs={4}>
                <FormControlBase>
                  <TextFieldBase 
                    label="Data de Cancelamento" 
                    name="dataCancelamento"
                    value={formik.values.dataCancelamento}
                    type="date"
                    onChange={formik.handleChange}
                    onBlur={formik.onBlur}
                    error={formik.touched.dataCancelamento && Boolean(formik.errors.dataCancelamento)}
                    helperText={formik.touched.dataCancelamento && formik.errors.dataCancelamento}
                    width='100%'
                  />
                </FormControlBase>
              </Grid>
              <Grid item xs={8}>
                <FormControlBase>
                  <InputLabelSelect  id="motivoCancelamentoOpcao">Motivo</InputLabelSelect>
                  <SelectBase 
                    id='motivoCancelamentoOpcao'
                    name='motivoCancelamentoOpcao'
                    value={formik.values.motivoCancelamentoOpcao}
                    onChange={formik.handleChange}
                    error={formik.touched.motivoCancelamentoOpcao && Boolean(formik.errors.motivoCancelamentoOpcao)}
                    helperText={formik.touched.motivoCancelamentoOpcao && formik.errors.motivoCancelamentoOpcao}
                    label='Motivo'
                    width='100%'
                  >
                    {motivosCancelamento.map((motivo) => (
                      <MenuItem key={motivo.id} value={motivo.id}>
                        {motivo.descricaoMotivo}
                      </MenuItem>
                    ))}
                  </SelectBase>
                </FormControlBase>
              </Grid>

              <Grid item xs={12}>
                <FormControlBase>
                  <TextFieldBase 
                    label="Detalhes"
                    name="motivoCancelamento"
                    value={formik.values.motivoCancelamento}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.motivoCancelamento && Boolean(formik.errors.motivoCancelamento)}
                    helperText={formik.touched.motivoCancelamento && formik.errors.motivoCancelamento}
                    width='100%'  
                    multiline 
                    rows={4}
                  />
                </FormControlBase>
              </Grid>
            </Grid>
          </FormSectionBox>

          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 0.5, pt: 2}}>
            <ButtonBase variant="outlined" onClick={() => setModalCancelamentoServico({show: false, servico: {}})}>
              Voltar
            </ButtonBase>

            <ButtonBase type="submit" variant="contained">
              Cancelar
            </ButtonBase>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}
