import { Chip } from "@mui/material";

export default function StatusPedido({status, isPedido = false, isContrato = false, isServico = false}) {
    let color = '';
    let statusTitle = '';
    
    if (status === 0) {
        isContrato ? color = 'tomato' : color = 'lavender';
        isContrato ? statusTitle = 'Cancelado' : (isServico ? statusTitle = 'Digitado' : statusTitle = 'Pendente');
    } else if (status === 1) {
        color = 'palegreen';
        isContrato ? statusTitle = 'Ativo' : statusTitle = 'Liberado';
    } else if (status === 2) {
        !isContrato ? color = 'tomato' : color = 'lavender';
        isContrato ? statusTitle = 'Baixado' : statusTitle = 'Cancelado';
    }
    return isPedido ? (
      <Chip
        label={statusTitle}
        size="small"
        sx={{ backgroundColor: color, fontWeight: 'bold' }}
      />
    ) : (
        <td style={{fontSize: "small", backgroundColor: color}}>{statusTitle}</td>                        
    )
}