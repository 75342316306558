import { styled, Tooltip, tooltipClasses } from '@mui/material';
import styles from './TableItemName.module.css';

export const TableItemName = ({title}) => {
    let newTitle = ''
    var showTooltip = false;
    var maxLength = 25

    if (title.length > maxLength) {
        newTitle = title.substring(0,maxLength) + '...';
        showTooltip = true;
    } else {
        newTitle = title;
    }

    const StyledTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        fontSize: '0.85rem',
      },
    }));

    return (      
        <>                  
            { showTooltip ? 
                <StyledTooltip title={title} arrow>                
                    <td className={styles.td}>{newTitle}</td>
                </StyledTooltip> 
            : <td className={styles.td}>{newTitle}</td> 
            }
        </>
    )
}
