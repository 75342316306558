import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Container, Grid, MenuItem } from "@mui/material";
import { BoxBase, ButtonBase, FormControlBase, FormSectionTitle, InputLabelSelect, SelectBase, TextFieldBase } from "../../../styles";
import { useFormik } from "formik";
import * as Yup from 'yup';

import { PedidosContext } from "../../../../../Context/PedidosContext";
import api from "../../../../../Api/apiAxios";
import { format } from "date-fns";

const validationSchema = Yup.object({
  valorContrato: Yup.number()
    .required('O valor do contrato é obrigatório')
});

export function ModalContrato({ contratoSelecionado, isShow = false }) {
  const { id } = useParams();

  const { contrato, setModalContrato, setContratos, fetchContratos, motivosCancelamento } = useContext(PedidosContext);

  const handleSave = async (values) => {
    setModalContrato({ show: false, contrato: {} });

    const regContrato = {
      Id: contratoSelecionado?.id || 0,
      PedidoId: contrato.pedidoId,
      StatusContrato: 1,
      DataAtivacao: new Date(),
      DataAtualizacao: new Date(),
      ValorFixo: 1,
      Valor: Number(formik.values.valorContrato),
      Sistema: contrato.sistema,
      TipoContrato: contrato.tipoContrato,
      DistribuidorId: contrato.distribuidor.id,
      ClienteId: contrato.clienteId,
      NumeroSerie: contrato.numeroSerie,
    };

    if (contratoSelecionado?.valor !== formik.values.valorContrato) {
      let ret;
      if (contratoSelecionado?.id > 0) {
        ret = await api.put('/Contrato/', JSON.stringify(regContrato), { headers: { 'Content-Type': 'application/json' } });
      } else {
        ret = await api.post('/Contrato/', JSON.stringify(regContrato), { headers: { 'Content-Type': 'application/json' } });
      }

      console.log(ret.data)
    } else {
      alert('O valor do contrato não foi alterado, não será feito update.');
    }

    const listaContratos = await fetchContratos(id)

    const contratoAtivo = listaContratos.filter(c => c.statusContrato === 1);
        const contratosRestantes = listaContratos
            .filter(c => c.statusContrato !== 1)
            .sort((a, b) => new Date(b.dataAtivacao) - new Date(a.dataAtivacao));

    const listaContratosOrdenada = [...contratoAtivo, ...contratosRestantes];
    setContratos(listaContratosOrdenada)
  };

  const formik = useFormik({
    initialValues: {
      valorContrato: contrato?.valorContrato || contratoSelecionado.valor || ''
    },
    validationSchema: validationSchema,
    onSubmit: handleSave
  });

  useEffect(() => {
    if (contrato.valor) formik.setFieldValue('valorContrato', contrato.valor)
  }, [])

  return (
    <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
      <Box sx={{
        backgroundColor: 'white',
        py: 3,
        px: 1,
        my: 2,
        borderRadius: '30px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        width: '50%',
        alignSelf: 'center',
        position: 'fixed',
        top: 100,
        zIndex: 1000,
        maxHeight: '80vh',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)'
      }}>
        <FormSectionTitle label={ contratoSelecionado?.id > 0 && !isShow ? 'Editar Contrato' : (isShow ? 'Visualizar Contrato' : 'Novo Contrato')} />
        <Container sx={{ display: 'flex', flexDirection: 'column', mt: 3, justifyContent: 'center', width: '98%' }}>
          <BoxBase>
            <TextFieldBase
              label="Valor do Contrato (R$)"
              name="valorContrato"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.valorContrato && Boolean(formik.errors.valorContrato)}
              helperText={formik.touched.valorContrato && formik.errors.valorContrato}
              placeholder="0.00"
              type='number'
              width='100%'
              value={formik.values.valorContrato}
              disabled={isShow}
            />
          </BoxBase>
        </Container>

        {isShow && contratoSelecionado.statusContrato === 0 && 
          <Grid container spacing={2} sx={{ pb: 2, px: 4, display: 'flex' }}>
            <Grid item xs={4}>
              <FormControlBase>
                <TextFieldBase 
                  label="Data de Cancelamento" 
                  name="dataCancelamento"
                  value={format(new Date(contratoSelecionado.dataCancelamento), 'yyyy-MM-dd')}
                  type="date"
                  width='100%'
                  disabled
                />
              </FormControlBase>
            </Grid>
            <Grid item xs={8}>
              <FormControlBase>
                <InputLabelSelect  id="motivoCancelamentoOpcao">Motivo</InputLabelSelect>
                <SelectBase 
                  id='motivoCancelamentoOpcao'
                  name='motivoCancelamentoOpcao'
                  value={contratoSelecionado.motivoCancelamentoOpcao}
                  label='Motivo'
                  width='100%'
                  disabled
                >
                  {motivosCancelamento.map((motivo) => (
                    <MenuItem key={motivo.id} value={motivo.id}>
                      {motivo.descricaoMotivo}
                    </MenuItem>
                  ))}
                </SelectBase>
              </FormControlBase>
            </Grid>

            <Grid item xs={12}>
              <FormControlBase>
                <TextFieldBase 
                  label="Detalhes"
                  name="motivoCancelamento"
                  value={contratoSelecionado.motivoCancelamento}
                  width='100%'  
                  multiline 
                  rows={4}
                  disabled
                />
              </FormControlBase>
            </Grid>
          </Grid>
        }

        {isShow ? (
        <Box sx={{ display: 'flex', width: '95%', justifyContent: 'flex-end', gap: 2, my: 1 }}>
          <ButtonBase variant="outlined" onClick={() => setModalContrato({ show: false, contrato: {} })}>
            Voltar
          </ButtonBase>
        </Box>
        ) : (
        <Box sx={{ display: 'flex', width: '95%', justifyContent: 'flex-end', gap: 2, my: 1 }}>
          <ButtonBase variant="outlined" onClick={() => setModalContrato({ show: false, contrato: {} })}>
            Cancelar
          </ButtonBase>
          <ButtonBase type="submit" variant="contained" onClick={() => handleSave()} >
            Salvar
          </ButtonBase>
        </Box>
        )}
      </Box>
    </Box>
  );
}
