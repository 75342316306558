import { useState, useRef, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Box, IconButton, Paper, Tooltip, styled, ClickAwayListener } from "@mui/material";

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import FeedIcon from '@mui/icons-material/Feed';

import { PedidosContext } from '../../../../../Context/PedidosContext';
import api from '../../../../../Api/apiAxios';

export function ActionButtons({ show = false, servico }) {
  const [isVisible, setIsVisible] = useState(show);
  const anchorRef = useRef(null);

  const nivelUsuario = window.localStorage.getItem('nivelUsuario');

  const { id } = useParams();

  const { setModalServico, fetchServicos, setServicos, setModalCancelamentoServico } = useContext(PedidosContext)


  const handleIconClick = () => {
    setIsVisible(true);
  };

  const handleClickAway = () => {
    setIsVisible(false);
  };

  const handleEditarServicoClick = async () => {
    setIsVisible(false);
    setModalServico({show: true, servico: servico});
  };

  const handleVisualizarServicoClick = async () => {
    setIsVisible(false);
    setModalServico({show: true, servico: servico, disabled: true});
  };

  const handleCancelarServicoClick = async () => {
    setIsVisible(false);
    setModalCancelamentoServico({ show: true, servico: servico })
  }

  const handleStatusServicoClick = async (novoStatus) => {
    setIsVisible(false);
  
    const regServico = {
      Id: servico.id,
      PedidoId: servico.pedidoId,
      DistribuidorId: servico.distribuidorId,
      ClienteId: servico.clienteId,
      UsuarioId: servico.usuarioId,
      TipoSistema: servico.tipoSistema,
      TipoServico: servico.tipoServico,
      DescricaoServico: servico.descricaoServico,
      QuantidadeParcelas: servico.quantidadeParcelas,
      StatusServico: novoStatus,
      ValorServico: servico.valorServico,
      DataInclusao: servico.dataInclusao,
      DataLiberacao: servico.dataLiberacao,
      NumeroSerie: servico.numeroSerie,
      Ressarcimento: servico.ressarcimento,
      IntegrarMesAnterior: servico.integrarMesAnterior,
      DataPrimeiroVencimento: servico.dataPrimeiroVencimento,
      ...(novoStatus === 2 && { dataCancelamento: new Date() })
    };
  
    const ret = await api.put("Servico/", JSON.stringify(regServico), { headers: { 'Content-Type': 'application/json' } });
    console.log(ret.data);
  
    const servicos = await fetchServicos(id);
    const servicosOrdenados = servicos.sort((a, b) => new Date(b.periodoInicial) - new Date(a.periodoInicial));
    setServicos(servicosOrdenados);
  };
  

  return (
    <Box sx={{ position: 'relative' }}>
      <IconButton ref={anchorRef} onClick={handleIconClick} disabled={nivelUsuario !== '1'}>
        <MoreHorizIcon color='action' fontSize='small' sx={{ cursor: 'pointer' }} />
      </IconButton>
      {isVisible && nivelUsuario === '1' && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <StyledPaper elevation={2}>
            <Box sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              minWidth: '7rem',
              maxWidth: '15rem',
              alignItems: 'center',
              paddingLeft: '0.4rem',
              zIndex: 5000
            }}>
              <Tooltip title='Editar'>
                <BaseActionButton color='#ffc107' onClick={handleEditarServicoClick} disabled={nivelUsuario !== '1' || servico.statusServico !== 0 }>
                  <EditIcon />
                </BaseActionButton>
              </Tooltip>

              <Tooltip title='Visualizar'>
                <BaseActionButton color='#198754' onClick={handleVisualizarServicoClick}>
                  <FeedIcon />
                </BaseActionButton>
              </Tooltip>

              <Tooltip title='Liberar'>
                <BaseActionButton color='#0d6efd' onClick={() => handleStatusServicoClick(1)} disabled={nivelUsuario !== '1' || servico.statusServico !== 0}>
                  <DriveFolderUploadIcon />
                </BaseActionButton>
              </Tooltip>

              <Tooltip title='Cancelar'>
                <BaseActionButton 
                  color='#dc3545' 
                  onClick={handleCancelarServicoClick}
                  // onClick={() => handleStatusServicoClick(2)} 
                  disabled={nivelUsuario !== '1'}
                >
                  <CancelIcon />
                </BaseActionButton>
              </Tooltip>
            </Box>
          </StyledPaper>
        </ClickAwayListener>
      )}
    </Box>
  );
}

export const BaseActionButton = styled(IconButton)(({ color }) => ({
  boxShadow: `0 0 1.5px ${color}`,
  color: color,
  margin: '0 0.5rem 0.3rem 0',
}));

const StyledPaper = styled(Paper)({
  position: 'absolute',
  top: 30,
  left: -60,
  zIndex: 1000,
  padding: '1rem 0',
  borderRadius: '30px',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: -8, 
    right: 20,
    width: 0,
    height: 0,
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
    borderBottom: '8px solid rgb(104,104,104, 0.18) ',
    zIndex: -1,
  }
});
