import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { ChatDots } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { MyContext } from '../../../Context/MyContext';

import styles from '../Menu.module.css';

import api from '../../../Api/apiAxios';
import { OpcoesUsuario } from '../OpcoesUsuario';

function MenuRevenda() {
    const navigate = useNavigate();
    const { logoutApp } = React.useContext(MyContext);
    const [username, setUsername] = useState('');

    function acessarChat() {      
        window.open("https://tawk.to/chat/54f44e95d997804320115e85/default", "_blank");
    }   
    
    useEffect(() => {
        (async () => {
            const userId = window.localStorage.getItem('idUsuario');
            try {
                const response = await api.get(`/Usuario/${userId}`);
                setUsername(response.data.nome);
            } catch (e) {
                if (e.response.status !== 401) {alert(e)};
            }
        })();
    }, [])
    
    return (
        <Navbar collapseOnSelect expand="lg" style={{backgroundColor: '#132350'}} variant="dark">
        <Container>
            <Navbar.Brand style={{cursor: 'pointer'}} onClick={()=>navigate("/")}>BmaPortal</Navbar.Brand>

            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Nav className={styles.nav_container}>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <div className={styles.menu_items}>
                        <NavDropdown title="Cadastros" id="collasible-nav-dropdown" style={{marginRight: "15px"}}>
                            <NavDropdown.Item onClick={()=>navigate("/clientes")}>Clientes</NavDropdown.Item>
                        </NavDropdown>

                        <Nav.Link onClick={()=>navigate("/pedidos")} style={{marginRight: "15px"}}>Pedidos</Nav.Link>

                        <NavDropdown title="Licenças" id="collasible-nav-dropdown" style={{marginRight: "15px"}}>
                            <NavDropdown.Item onClick={()=>navigate("/personalizacoesponto")}>BmaPonto</NavDropdown.Item>
                            <NavDropdown.Item onClick={()=>navigate("/personalizacoesacesso")}>BmaAcesso</NavDropdown.Item>
                            <NavDropdown.Item onClick={()=>navigate("/personalizacoesfolha")}>BmaFolha</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={()=>navigate("/clienteversoes")}>Clientes x Sistemas</NavDropdown.Item>        
                        </NavDropdown>

                        <NavDropdown title="Consultas" id="collasible-nav-dropdown" style={{marginRight: "15px"}}>
                            <NavDropdown.Item onClick={()=>navigate("/relatoriopedidos")}>Pedidos / OS</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={()=>navigate("/relatorioparcialfaturamento")}>Parcial Mês - Faturamento (Totais)</NavDropdown.Item>
                            <NavDropdown.Item onClick={()=>navigate("/relatorioparcialfaturamentorevenda")}>Parcial Mês - Faturamento (Por Revenda)</NavDropdown.Item>
                            <NavDropdown.Item onClick={()=>navigate("/relatoriofaturamento")}>Faturamento por Período</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={()=>navigate("/dashboard")}>Dashboard</NavDropdown.Item>
                        </NavDropdown>

                        <Nav.Link onClick={acessarChat} style={{marginRight: "15px"}}>Chat <ChatDots size={25} /> </Nav.Link>
                    </div>             
                </Navbar.Collapse>
            </Nav>
            <div className={styles.logout_container}>
              <OpcoesUsuario />
            </div>
        </Container>
        </Navbar>
    );
}

export default MenuRevenda;